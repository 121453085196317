<template>
  <div class="resources" :class="{ 'show-error': showError, 'single-value': !multiple }">
    <div class="resources__item" v-for="(item, index) in list" :key="index">
      <div v-if="multiple" class="resources__item__no">{{ index + 1 }}.</div>
      <div class="resources__item__data">
        <div v-if="index === editingItemIndex" class="resource-item">
          <div style="width: 100%">
            <div
              class="resource-edit__input"
              :class="{ hasError: invalidEditName }"
            >
              <input
                v-if="multiple && !nameLarge"
                type="text"
                v-model="item.name"
                ref="updatename"
                :placeholder="$t('10_1_resource_name_placeholder')"
                :maxlength="length.short"
                @change="invalidEditName = false"
              />

              <textarea
                v-if="multiple && nameLarge"
                v-model="item.name"
                ref="updatename"
                :placeholder="$t('textarea_start_typing')"
                :maxlength="length.short"
                @change="invalidEditName = false"
              ></textarea>
            </div>
            <div
              v-if="!nameOnly"
              class="resource-edit__input"
              :class="{ hasError: invalidEditLink }"
            >
              <input
                @change="invalidEditLink = false"
                type="text"
                placeholder="https://www…"
                v-model="item.link"
                :maxlength="length.short"
              />
            </div>
          </div>

          <div
            class="
              resources__controll__buttons
              resources__controll__buttons--edit
              resources__controll__buttons--columns
            "
          >
            <div class="resources__controll__buttons__list">
              <Button
                @click="updateItem(index)"
                :size="'small'"
                :type="'primary'"
                >{{ $t('10_1_resource_save') }}</Button
              >
              <Button
                @click="restoreItem(index)"
                :size="'small'"
                :type="'secondary'"
                >
                {{ $t('10_1_resource_cancel') }}
              </Button>
            </div>

            <div>
              <Button
                v-if="confirmDelete !== index"
                :size="'slim'"
                @click="deleteItem(index)"
              >
                <IcoBin />
              </Button>
              <Button
                v-if="confirmDelete === index"
                v-click-outside="cancelDeleteItem"
                @click="confirmedDeleteItem()"
                :type="'delete'"
                :size="'slim'"
                class="button-confirm-delete"
              >
                <IcoBin />
              </Button>
            </div>
          </div>
        </div>
        <div v-else class="resource-item">
          <div class="resource-item__data">
            <div v-if="multiple" @click="editItem(index)" class="resource-item__title">
              {{ item.name }}
            </div>
            <div class="resource-item__url">
              <a target="_blank" :href="item.link">{{ item.link }}</a>
            </div>
          </div>
          <div class="resource-item__controll">
            <Button v-if="!nameOnly" @click="copyUrlToClipboard(item)" :size="'slim'">
              <IcoDuplicate />
            </Button>
            <Button v-if="!disabled" @click="editItem(index)" :size="'slim'">
              <IcoEdit />
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="resources__controll resources__controll--add"
      :class="{ 'is-first': list.length === 0 }"
    >
      <div v-if="state === 'add'">
        <div style="display: flex; width: 100%; gap: 15px">
          <div v-if="multiple" class="resources__item__no">{{ items.length + 1 }}.</div>
          <div style="width: 100%">
            <div
              class="resource-edit__input"
              :class="{ hasError: invalidAddName }"
            >
              <input
                v-if="multiple && !nameLarge"
                @change="invalidAddName = false"
                ref="new_resource_name"
                :placeholder="$t('10_1_resource_name_placeholder')"
                type="text"
                v-model="title"
                :maxlength="length.short"
              />

              <textarea
                v-if="multiple && nameLarge"
                @change="invalidAddName = false"
                ref="new_resource_name"
                :placeholder="$t('textarea_start_typing')"
                v-model="title"
                :maxlength="length.long"
              ></textarea>
            </div>
            <div
              v-if="!nameOnly"
              class="resource-edit__input"
              :class="{ hasError: invalidAddLink }"
            >
              <input
                @change="invalidAddLink = false"
                placeholder="https://www…"
                type="text"
                v-model="url"
                :maxlength="length.short"
              />
            </div>
          </div>
        </div>

        <div
          class="
            resources__controll__buttons resources__controll__buttons--edit
          "
          :class="{'single-value': !multiple}"
        >
          <Button @click.native="saveItem" :size="'small'" :type="'primary'">
            {{ $t('10_1_resource_save') }}
          </Button>
          <Button @click="cancelItem" :size="'small'" :type="'secondary'">
            {{ $t('10_1_resource_cancel') }}
          </Button>
        </div>
      </div>
      <div v-else-if="!disabled" class="resources__controll__buttons">
        <template v-if="multiple || (!multiple && list.length === 0)">
          <Button @click="addItem" :size="'small'" :type="'secondary'">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.00065 3.33325V12.6666M3.33398 7.99992H12.6673" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>{{$t(transCreateNew)}}
          </Button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import textLength from '@/constants/TextLengthConstant.js'
import { obj_clone } from '@/services/helpers/clone.js'
import { isValidURL } from '@/services/UtilsService.js'
import ClickOutside from 'vue-click-outside'
import Button from '@/components/button/Button.vue'
import IcoBin from '@/components/svg/small/IcoBin.vue'
import IcoEdit from '@/components/svg/small/IcoEdit.vue'
import IconExternal from '@/components/svg/small/IconExternal.vue'
import IcoDuplicate from '@/components/svg/small/IcoDuplicate.vue'

export default {
  components: {
    Button,
    IcoBin,
    IcoEdit,
    IconExternal,
    IcoDuplicate,
    isValidURL,
  },
  directives: {
    ClickOutside,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    transCreateNew: {
      type: String,
      default: '10_1_resource_add',
    },
    showError: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true
    },
    nameLarge: {
      type: Boolean,
      default: false
    },
    nameOnly: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.list = this.items

    this.list_default = []
    this.list.forEach((item) => {
      this.list_default.push(obj_clone(item))
    })
  },
  computed: {
    length() {
      return textLength
    },
  },
  data() {
    return {
      list: [],
      list_default: [],
      state: '',
      title: '',
      url: '',
      editingItemIndex: null,
      confirmDelete: null,
      index: 0,
      invalidAddName: false,
      invalidAddLink: false,
      invalidEditName: false,
      invalidEditLink: false,
    }
  },
  methods: {
    editItem(index) {
      if (this.disabled) {
        return
      }

      if (this.editingItemIndex != null) {
        this.$notify({
          title: this.$t('10_1_toaster_warning_title_finish_resources_edit'),
          text: this.$t(
            '10_1_toaster_warning_description_finish_resources_edit'
          ),
          type: 'warning',
          duration: 5000,
        })

        return
      }

      this.editingItemIndex = index

      setTimeout(() => {
        this.$refs.updatename[0].focus()
      }, 100)

      this.$emit('opened')
    },
    async copyUrlToClipboard(item) {
      await navigator.clipboard.writeText(item.link)

      this.$notify({
        title: this.$t('10_1_toaster_title_copied_to_clipboard'),
        text: this.$t('10_1_toaster_description_copied_to_clipboard'),
        type: 'info',
        duration: 5000,
      })

      setTimeout(() => {
        this.editingItemIndex = null
      }, 1)
    },
    openUrl(item) {
      window.open(item.link, '_blank').focus()
    },
    saveItem() {
      this.invalidAddName = false
      this.invalidAddLink = false

      if (this.title === '' && this.multiple) {
        this.invalidAddName = true

        this.$notify({
          title: this.$t('9_2_toaster_error_title_resources_missing_name'),
          text: this.$t('9_2_toaster_error_description_resources_missing_name'),
          type: 'error',
          duration: 5000,
        })
      }

      if (this.url === '' && !this.nameOnly) {
        this.invalidAddLink = true

        this.$notify({
          title: this.$t('9_2_toaster_error_title_resources_missing_link'),
          text: this.$t('9_2_toaster_error_description_resources_missing_link'),
          type: 'error',
          duration: 5000,
        })
      }

      if (this.invalidAddName || this.invalidAddLink) {
        return
      }

      this.list.push({
        name: this.title,
        link: this.url,
      })

      this.$nextTick(() => {
        this.clearItem()
      })

      this.$emit('closed')
      this.$emit('updateResources', this.list)
    },
    clearItem() {
      this.title = ''
      this.url = ''
      this.state = 'create'
    },
    cancelItem() {
      this.$emit('closed')
      this.clearItem()
    },
    addItem() {
      if (this.editingItemIndex != null) {
        this.$notify({
          title: this.$t('10_1_toaster_warning_title_finish_resources_edit'),
          text: this.$t(
            '10_1_toaster_warning_description_finish_resources_edit'
          ),
          type: 'warning',
          duration: 5000,
        })

        return
      }

      setTimeout(() => {
        this.$refs.new_resource_name.focus()
      }, 100)

      this.$emit('opened')

      this.state = 'add'
    },
    deleteItem(index) {
      this.confirmDelete = index

      this.$notify({
        title: this.$t('toaster_info_title_confirm_delete'),
        text: this.$t('toaster_info_description_confirm_delete'),
        type: 'info',
        duration: 5000,
      })

      setTimeout(() => {
        this.confirmDelete = null
      }, 2000)
    },
    confirmedDeleteItem() {
      this.list.splice(this.confirmDelete, 1)
      this.editingItemIndex = null
      this.confirmDelete = null

      this.$emit('updateResources', this.list)
      this.$emit('closed')
    },
    cancelDeleteItem() {
      this.confirmDelete = null
    },
    updateItem(index) {
      this.invalidEditName = false
      this.invalidEditLink = false

      let data = this.items
      data[index] = obj_clone(this.list[index])

      if (data[index].name === '' && this.multiple) {
        this.invalidEditName = true

        this.$notify({
          title: this.$t('9_2_toaster_error_title_resources_missing_name'),
          text: this.$t('9_2_toaster_error_description_resources_missing_name'),
          type: 'error',
          duration: 5000,
        })
      }

      if (data[index].link === '') {
        this.invalidEditLink = true

        this.$notify({
          title: this.$t('9_2_toaster_error_title_resources_missing_link'),
          text: this.$t('9_2_toaster_error_description_resources_missing_link'),
          type: 'error',
          duration: 5000,
        })
      }

      if (this.invalidEditName || this.invalidEditLink) {
        return
      }

      this.$emit('updateResources', data)
      this.$emit('closed')

      this.list_default = []
      this.list.forEach((item) => {
        this.list_default.push(obj_clone(item))
      })

      setTimeout(() => {
        this.editingItemIndex = null
      }, 100)
    },
    restoreItem(index) {
      this.list[index] = obj_clone(this.list_default[index])

      setTimeout(() => {
        this.editingItemIndex = null
      }, 100)

      this.$emit('closed')
    },
  },
  watch: {
    items() {
      this.list = this.items

      this.list_default = []
      this.list.forEach((item) => {
        this.list_default.push(obj_clone(item))
      })
    },
  },
}
</script>

<style scoped>
.resources {
  padding: 21px 0 21px 16px;
}

.resources__item {
  display: flex;
  margin-top: 17px;
  border-bottom: 2px solid #f5f5f5;
  padding-bottom: 12px;
  max-width: 508px;
}

.resources.single-value {
  padding: 16px 16px 0 16px;
}

.resources.single-value .resources__item {
  border-bottom: none;
  padding-bottom: 0;
}

.resources__item:first-child {
  margin-top: 0;
}

.resources__item__no {
  align-items: flex-end;
  gap: 0;
  width: 20px;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.resources__item__data {
  width: 100%;
}

.resources__controll--add {
  margin-top: 15px;
  margin-left: -16px;
}

.resources__controll--add.is-first {
  margin-top: 0;
}

.resources__controll__buttons {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding-left: 0;
}

.resources__controll:first-child .resources__controll__buttons {
  margin-top: 0;
}

.resources__controll__buttons__list {
  display: flex;
  gap: 8px;
}

.resource-edit__input {
  margin-top: 5px;
}

.resource-edit__input:first-child {
  margin-top: 0;
}

.resource-edit__input input {
  box-sizing: border-box;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 0 10px;
  width: 100%;
  height: 32px;

  line-height: 32px;
}

.resources.show-error .resource-edit__input input {
  border: 1px solid #f45050;
}

.resource-edit__input.hasError input {
  border: 1px solid #f45050;
}

.resource-edit__input input:focus {
  outline: none;
  border-color: #2ce0c1;
}

.resources.show-error .resource-edit__input input {
  border-color: #f45050;
}

.resource-item {
  display: block;
  position: relative;
  padding-left: 16px;
  width: 100%;
}

.resources.single-value .resource-item {
  padding-left: 4px;
}

.resource-item__title {
  max-width: 530px;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  overflow-wrap: break-word;
}

.resource-item__url {
  display: block;
  margin-top: 4px;
  max-width: 410px;
  overflow: hidden;
  color: #17252a;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resource-item__url {
  color: #2ce0c1;
  letter-spacing: -0.2px;
}

.resource-item__url a {
  color: #2ce0c1;
  letter-spacing: -0.2px;
}

.resource-item__url a {
  text-decoration: none;
}

.resource-item__url a:hover {
  text-decoration: underline;
}

.resource-item__data {
  padding-right: 59px;
  width: 100%;
}

.resource-item__controll {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  justify-content: flex-end;
  gap: 8px;
  z-index: 2;
  width: 60px;
}

.resources__controll__buttons--edit {
  margin-top: 8px !important;
  padding-left: 0;
}

.resources__controll--add .resources__controll__buttons--edit {
  padding-left: 34px;
}

.resources__controll__buttons.resources__controll__buttons--edit.single-value {
  padding-left: 0;
}


.resources.single-value .resources__controll.resources__controll--add.is-first {
  padding-bottom: 16px;
}


.resource-edit__input textarea {
  width: 100%;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 10px;
  resize: none;
}
</style>
