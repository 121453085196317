<template>
  <ContentWrapper>
    <div class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_3_2_main_title') }}</h1>

        <div class="boxed">
          <div class="box">
            <h3 class="sources_subtitle">{{ $t('15_3_2_title_brand_manual') }}</h3>

            <FormGroup :label="$t('15_3_2_label_communication_manual')" :required="false">
              <h2 class="files-title">
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('special_list_links_title') }}
                ({{brand.communicationManual.links.length}})
              </h2>
              <Resources
                :items="brand.communicationManual.links"
                :disabled="role === 'READ_ONLY'"
                :showError="resourcesIsOpenShowError"
                :multiple="true"
                transCreateNew="15_3_2_label_communication_manual_add"
                @updateResources="updateBrandManual"
                @opened="resourcesIsOpen = true"
                @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
              />

              <SourcesFileUploader
                query-type="communicationManual"
                file-type="brand-communication-manual"
                :destinationId="this.$route.params.id"
                :attachments="brand.communicationManual.files"
                @refresh="refetch"
                type="edit"
              ></SourcesFileUploader>
            </FormGroup>

            <FormGroup v-if="originalBrand.claim !== ''" :label="$t('15_3_2_label_claim')" :required="false">
              <input
                class="form-group__input"
                type="text"
                :placeholder="$t('15_3_2_placeholder_claim')"
                v-model="brand.claim"
                @input="validationError.claim = false"
                ref="contentName"
              />
            </FormGroup>

            <div
              v-if="originalBrand.vision !== '' || originalBrand.mission !== '' || originalBrand.values !== ''"
              class="column">
              <FormGroup v-if="originalBrand.vision !== ''" :label="$t('15_3_2_label_vision')" :required="false">
                  <textarea
                    class="form-group__input large"
                    :placeholder="$t('15_3_2_placeholder_vision')"
                    v-model="brand.vision"
                    @input="validationError.vision = false"
                    ref="contentName"
                  ></textarea>
              </FormGroup>

              <FormGroup v-if="originalBrand.mission !== ''" :label="$t('15_3_2_label_mission')" :required="false">
                  <textarea
                    class="form-group__input large"
                    :placeholder="$t('15_3_2_placeholder_mission')"
                    v-model="brand.mission"
                    @input="validationError.mission = false"
                    ref="contentName"
                  ></textarea>
              </FormGroup>

              <FormGroup v-if="originalBrand.values !== ''" :label="$t('15_3_2_label_values')" :required="false">
                  <textarea
                    class="form-group__input large"
                    :placeholder="$t('15_3_2_placeholder_values')"
                    v-model="brand.values"
                    @input="validationError.values = false"
                    ref="contentName"
                  ></textarea>
              </FormGroup>
            </div>

            <FormGroup
              v-if="originalBrand.keywords && originalBrand.keywords.length > 0"
              :focused="focusKeywords"
              :label="$t('15_3_2_label_used_keywords')"
              :required="false"
            >
              <Keywords
                v-model="brand.keywords"
                :placeholder="$t('15_3_2_placeholder_used_keywords')"
                @save_changed_value="updateKeywords"
                @focus="focusKeywords = true"
                @blur="focusKeywords = false"
              />
            </FormGroup>


            <FormGroup
              v-if="originalBrand.hashtags && originalBrand.hashtags.length > 0"
              :focused="focusHashtags"
              :label="$t('15_3_2_label_used_hashtags')"
              :required="false"
            >
              <Keywords
                v-model="brand.hashtags"
                :placeholder="$t('15_3_2_placeholder_used_hashtags')"
                @save_changed_value="updateHashtags"
                @focus="focusHashtags = true"
                @blur="focusHashtags = false"
              />
            </FormGroup>


            <FormGroup
              v-if="
              originalBrand && originalBrand.otherResources && originalBrand.otherResources.links &&
              (
                originalBrand.otherResources.links.length !== 0
                || originalBrand.otherResources.files.length !== 0
              )
              "
              :label="$t('15_3_2_label_other_resources')"
              :required="false"
            >
              <h2 class="files-title">
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('special_list_links_title') }}
                ({{brand.otherResources.links.length}})
              </h2>
              <Resources
                :items="brand.otherResources.links"
                :disabled="role === 'READ_ONLY'"
                :showError="resourcesIsOpenShowError"
                transCreateNew="15_3_2_other_resources_add"
                @updateResources="updateOtherResourcesLinks"
                @opened="resourcesIsOpen = true"
                @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
              />

              <SourcesFileUploader
                query-type="otherResources"
                file-type="brand-resource"
                :destinationId="this.$route.params.id"
                :attachments="brand.otherResources.files"
                @refresh="refetch"
                type="edit"
              ></SourcesFileUploader>
            </FormGroup>


            <Accordion
              v-if="
              originalBrand.claim === ''
              || (originalBrand.vision === '' || originalBrand.mission === '' || originalBrand.values === '')
              || (!originalBrand.keywords || originalBrand.keywords.length === 0)
              || (!originalBrand.hashtags || originalBrand.hashtags.length === 0)
              "
              :title="$t('15_3_2_accordion_title_brand_manual')"
              :description="$t('15_3_2_accordion_description_brand_manual')"
            >
              <FormGroup v-if="originalBrand.claim === ''" :label="$t('15_3_2_label_claim')" :required="false">
                <input
                  class="form-group__input"
                  type="text"
                  :placeholder="$t('15_3_2_placeholder_claim')"
                  v-model="brand.claim"
                  @input="validationError.claim = false"
                  ref="contentName"
                />
              </FormGroup>

              <div
                v-if="originalBrand.vision === '' || originalBrand.mission === '' || originalBrand.values === ''"
                class="column">
                <FormGroup v-if="originalBrand.vision === ''" :label="$t('15_3_2_label_vision')" :required="false">
                  <textarea
                    class="form-group__input large"
                    :placeholder="$t('15_3_2_placeholder_vision')"
                    v-model="brand.vision"
                    @input="validationError.vision = false"
                    ref="contentName"
                  ></textarea>
                </FormGroup>

                <FormGroup v-if="originalBrand.mission === ''" :label="$t('15_3_2_label_mission')" :required="false">
                  <textarea
                    class="form-group__input large"
                    :placeholder="$t('15_3_2_placeholder_mission')"
                    v-model="brand.mission"
                    @input="validationError.mission = false"
                    ref="contentName"
                  ></textarea>
                </FormGroup>

                <FormGroup v-if="originalBrand.values === ''" :label="$t('15_3_2_label_values')" :required="false">
                  <textarea
                    class="form-group__input large"
                    :placeholder="$t('15_3_2_placeholder_values')"
                    v-model="brand.values"
                    @input="validationError.values = false"
                    ref="contentName"
                  ></textarea>
                </FormGroup>
              </div>


              <FormGroup
                v-if="!originalBrand.keywords || originalBrand.keywords.length === 0"
                :focused="focusKeywords"
                :label="$t('15_3_2_label_used_keywords')"
                :required="false"
              >
                <Keywords
                  v-model="brand.keywords"
                  :placeholder="$t('15_3_2_placeholder_used_keywords')"
                  @save_changed_value="updateKeywords"
                  @focus="focusKeywords = true"
                  @blur="focusKeywords = false"
                />
              </FormGroup>


              <FormGroup
                v-if="!originalBrand.hashtags || originalBrand.hashtags.length === 0"
                :focused="focusHashtags"
                :label="$t('15_3_2_label_used_hashtags')"
                :required="false"
              >
                <Keywords
                  v-model="brand.hashtags"
                  :placeholder="$t('15_3_2_placeholder_used_hashtags')"
                  @save_changed_value="updateHashtags"
                  @focus="focusHashtags = true"
                  @blur="focusHashtags = false"
                />
              </FormGroup>


              <!--
              <FormGroup
                v-if="originalBrand.otherResources.links.length === 0 && originalBrand.otherResources.files.length === 0"
                :label="$t('15_3_2_label_other_resources')"
                :required="false"
              >
                <Resources
                  :items="brand.otherResources.links"
                  :disabled="role === 'READ_ONLY'"
                  :showError="resourcesIsOpenShowError"
                  transCreateNew="15_3_2_other_resources_add"
                  @updateResources="updateResources"
                  @opened="resourcesIsOpen = true"
                  @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                />

                <SourcesFileUploader
                  query-type="otherResources"
                  file-type="brand-resource"
                  :attachments="brand.otherResources.files"
                  @refresh="refetch"
                  type="edit"
                ></SourcesFileUploader>
              </FormGroup>
              -->

            </Accordion>
          </div>











          <div class="box">
            <h3 class="sources_subtitle">{{ $t('15_3_2_title_visual_identity') }}</h3>

            <FormGroup :label="$t('15_3_2_label_identity_manual')" :required="false">
              <h2 class="files-title">
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('special_list_links_title') }}
                ({{brand.identityManual.links.length}})
              </h2>
              <Resources
                :items="brand.identityManual.links"
                :disabled="role === 'READ_ONLY'"
                :showError="resourcesIsOpenShowError"
                :multiple="true"
                transCreateNew="15_3_2_label_identity_manual_add"
                @updateResources="updateIdentityManualLinks"
                @opened="resourcesIsOpen = true"
                @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
              />

              <SourcesFileUploader
                query-type="identityManual"
                file-type="brand-identity-manual"
                :destinationId="this.$route.params.id"
                :attachments="brand.identityManual.files"
                @refresh="refetch"
                type="edit"
              ></SourcesFileUploader>
            </FormGroup>

            <FormGroup
              v-if="originalBrand.logo.links.length !== 0 || originalBrand.logo.files.length !== 0"
              :label="$t('15_3_2_label_logo')"
              :required="false"
            >
              <h2 class="files-title">
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('special_list_links_title') }}
                ({{brand.logo.links.length}})
              </h2>
              <Resources
                :items="brand.logo.links"
                :disabled="role === 'READ_ONLY'"
                :showError="resourcesIsOpenShowError"
                :multiple="true"
                transCreateNew="15_3_2_placeholder_logo"
                @updateResources="updateLogoLinks"
                @opened="resourcesIsOpen = true"
                @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
              />
              <SourcesFileUploader
                query-type="logo"
                file-type="brand-logo"
                :destinationId="this.$route.params.id"
                :attachments="brand.logo.files"
                @refresh="refetch"
                type="edit"
              ></SourcesFileUploader>
            </FormGroup>

            <FormGroup
              v-if="
                originalBrand.font.links.length !== 0
                || originalBrand.font.files.length !== 0
                "
              :label="$t('15_3_2_label_font')"
              :required="false"
            >
              <h2 class="files-title">
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('special_list_links_title') }}
                ({{brand.font.links.length}})
              </h2>
              <Resources
                :items="brand.font.links"
                :disabled="role === 'READ_ONLY'"
                :showError="resourcesIsOpenShowError"
                :multiple="true"
                transCreateNew="15_3_2_placeholder_font"
                @updateResources="updateFontLinks"
                @opened="resourcesIsOpen = true"
                @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
              />
              <SourcesFileUploader
                query-type="font"
                file-type="brand-font"
                :destinationId="this.$route.params.id"
                :attachments="brand.font.files"
                @refresh="refetch"
                type="edit"
              ></SourcesFileUploader>
            </FormGroup>

            <Accordion
              v-if="
              (originalBrand.logo.links.length === 0 && originalBrand.logo.files.length === 0)
              || (originalBrand.font.links.length === 0 && originalBrand.font.files.length === 0)
              "
              :title="$t('15_3_2_accordion_title_visual_identity')"
              :description="$t('15_3_2_accordion_description_visual_identity')"
            >

              <FormGroup
                v-if="(originalBrand.logo && originalBrand.logo.links.length === 0)
                      && (originalBrand.logo && originalBrand.logo.files.length === 0)"
                :label="$t('15_3_2_label_logo')"
                :required="false"
              >
                <h2 class="files-title">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  {{ $t('special_list_links_title') }}
                  ({{brand.logo.links.length}})
                </h2>
                <Resources
                  :items="brand.logo.links"
                  :disabled="role === 'READ_ONLY'"
                  :showError="resourcesIsOpenShowError"
                  :multiple="true"
                  transCreateNew="15_3_2_placeholder_logo"
                  @updateResources="updateLogoLinks"
                  @opened="resourcesIsOpen = true"
                  @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                />
                <SourcesFileUploader
                  query-type="logo"
                  file-type="brand-logo"
                  :destinationId="this.$route.params.id"
                  :attachments="brand.logo.files"
                  @refresh="refetch"
                  type="edit"
                ></SourcesFileUploader>
              </FormGroup>

              <FormGroup
                v-if="(originalBrand.font && originalBrand.font.links.length === 0)
                      && (originalBrand.font && originalBrand.font.files.length === 0)"
                :label="$t('15_3_2_label_font')"
                :required="false"
              >
                <h2 class="files-title">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  {{ $t('special_list_links_title') }}
                  ({{brand.font.links.length}})
                </h2>
                <Resources
                  :items="brand.font.links"
                  :disabled="role === 'READ_ONLY'"
                  :showError="resourcesIsOpenShowError"
                  :multiple="true"Logo1
                  transCreateNew="15_3_2_placeholder_font"
                  @updateResources="updateFontLinks"
                  @opened="resourcesIsOpen = true"
                  @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                />
                <SourcesFileUploader
                  query-type="font"
                  file-type="brand-font"
                  :destinationId="this.$route.params.id"
                  :attachments="brand.font.files"
                  @refresh="refetch"
                  type="edit"
                ></SourcesFileUploader>
              </FormGroup>

              <ColorsPicker />

              <!--
              <FormGroup :label="$t('15_3_2_label_visual_identity_other_resources')" :required="false">
                <Resources
                  :items="visualIdentityOthers"
                  :disabled="role === 'READ_ONLY'"
                  :showError="visualIdentityOthersIsOpenShowError"
                  transCreateNew="15_3_2_label_visual_identity_other_resources_add"
                  @updateResources="updateVisualIdentity"
                  @opened="visualIdentityOthersIsOpen = true"
                  @closed="
                      ;(visualIdentityOthersIsOpen = false),
                        (visualIdentityOthersIsOpenShowError = false)
                    "
                />
              </FormGroup>
              -->

            </Accordion>
          </div>
          <div class="box">
            <Button
              @click="updateBrand"
              :disabled="isUpdating"
              type="primary"
            >
              {{ $t('15_3_2_btn_save_changes') }}
            </Button>
          </div>
        </div>
      </div>
    </div>

    <ModalConfirmClose
      v-if="showLeaveModal"
      @close="handleModalLeaveClose"
      @confirm="handleModalLeaveConfirm"
    />

  </ContentWrapper>
</template>

<script>
import Resources from "@/components/design/Resources.vue";
import FormGroup from "@/components/FormGroup.vue";
import IcoCarretUp from "@/components/svg/IcoCarretUp.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import Button from "@/components/button/Button.vue";
import ColorsPicker from "@/components/colorsPicker/ColorsPicker.vue";
import Keywords from "@/components/Keywords.vue";
import ButtonCopy from "@/components/buttonCopy/ButtonAdd.vue";
import ButtonOpenLink from "@/components/buttonOpenLink/ButtonOpenLink.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import Link from "../../design/Link.vue";

import COMPANY_BRAND from '@/graphql/CompanyBrand.gql';
import COMPANY_BRAND_IDENTITY_UPDATE from '@/graphql/CompanyBrandIdentityUpdate.gql';

import {arrayClone, obj_clone} from "../../../services/helpers/clone";
import FileUploader from "../../fileUploader/FileUploader.vue";
import SourcesFileUploader from "../components/sourcesFileUploader/SourcesFileUploader.vue";
import ModalConfirmClose from "../../ModalConfirmClose.vue";

export default {
  components: {
    ModalConfirmClose,
    SourcesFileUploader,
    FileUploader,
    Link,
    ContentWrapper,
    ButtonOpenLink,
    ButtonCopy,
    Keywords,
    ColorsPicker,
    Button,
    Accordion,
    IcoCarretUp,
    FormGroup,
    Resources
  },
  apollo: {
    companies: {
      query: COMPANY_BRAND,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];
        if (company && company.brand) {
          this.setBrand(company.brand);
        } else {
          this.originalBrand = obj_clone(this.brand);
          this.updateBrand();
        }
      }
    },
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    companyID() {
      return this.$route.params.id;
    },
    hasDataChanged() {
      return JSON.stringify(this.brand) !== JSON.stringify(this.originalBrand);
    }
  },
  data() {
    return {
      brandManual: [],
      identityManual: [],
      logo: [],
      font: [],

      originalBrand: {
        vision: '',
        mission: '',
        values: '',
        claim: '',
        links: [],
        keywords: [],
        hashtags: [],

        communicationManual: {
          files: [],
          links: []
        },

        identityManual: {
          files: [],
          links: []
        },

        logo: {
          files: [],
          links: []
        },

        font: {
          files: [],
          links: []
        }
      },
      brand: {
        vision: '',
        mission: '',
        values: '',
        claim: '',
        links: [],
        keywords: [],
        hashtags: [],

        communicationManual: {
          files: [],
          links: []
        },

        otherResources: {
          files: [],
          links: []
        },

        identityManual: {
          files: [],
          links: []
        },

        logo: {
          files: [],
          links: []
        },

        font: {
          files: [],
          links: []
        }
      },

      validationError: {
        claim: false,
        vision: '',
        mission: '',
        values: '',
        logo: '',
        font: '',
      },

      claim: '',
      vision: '',
      mission: '',
      values: '',

      resourcesIsOpen: false,
      visualIdentityIsOpen: false,
      visualIdentityOthersIsOpen: false,

      resourcesIsOpenShowError: false,
      visualIdentityIsOpenShowError: false,
      visualIdentityOthersIsOpenShowError: false,

      resources: [],
      visualIdentity: [],
      visualIdentityOthers: [],

      keywords: [],
      focusKeywords: false,

      hashtags: [],
      focusHashtags: false,
      isUpdating: false,
      showLeaveModal: false,
    }
  },
  methods: {
    setBrand(data) {
      try {
        if (data.communicationManual) {
          if (data.communicationManual.links) {
            this.brand.communicationManual.links = arrayClone(data.communicationManual.links);
          }

          if (data.communicationManual.files) {
            this.brand.communicationManual.files = arrayClone(data.communicationManual.files);
          }
        }


        if (data.vision) {
          this.brand.vision = data.vision;
        }

        if (data.mission) {
          this.brand.mission = data.mission;
        }

        if (data.values) {
          this.brand.values = data.values;
        }

        if (data.claim) {
          this.brand.claim = data.claim;
        }

        if (data.keywords && data.keywords.length > 0) {
          this.brand.keywords = arrayClone(data.keywords);
        }

        if (data.hashtags && data.hashtags.length > 0) {
          this.brand.hashtags = [...data.hashtags];
        }


        if (data.identityManual) {
          if (data.identityManual.links) {
            this.brand.identityManual.links = arrayClone(data.identityManual.links);
          }

          if (data.identityManual.files) {
            this.brand.identityManual.files = arrayClone(data.identityManual.files);
          }
        }

        if (data.logo) {
          if (data.logo.links) {
            this.brand.logo.links = arrayClone(data.logo.links);
          }

          if (data.logo.files) {
            this.brand.logo.files = arrayClone(data.logo.files);
          }
        }

        if (data.font) {
          if (data.font.links) {
            this.brand.font.links = arrayClone(data.font.links);
          }

          if (data.font.files) {
            this.brand.font.files = arrayClone(data.font.files);
          }
        }


        this.originalBrand = obj_clone(this.brand);

      } catch(e) {

      }
    },
    updateBrand() {
      this.isUpdating = true;

      let brand = {};

      if (
        this.brand.communicationManual
        && this.brand.communicationManual.links
        && this.brand.communicationManual.links.length > 0
      ) {
        brand.communicationManual = this.brand.communicationManual.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      }

      if (this.brand.vision) {
        brand.vision = this.brand.vision;
      } else {
        brand.vision = "";
      }

      if (this.brand.mission) {
        brand.mission = this.brand.mission;
      } else {
        brand.mission = "";
      }

      if (this.brand.values) {
        brand.values = this.brand.values;
      } else {
        brand.values = "";
      }

      if (this.brand.claim) {
        brand.claim = this.brand.claim;
      } else {
        brand.claim = "";
      }

      if (this.brand.hashtags && this.brand.hashtags.length > 0) {
        brand.hashtags = this.brand.hashtags;
      } else {
        // brand.hashtags = [];
      }

      if (this.brand.keywords && this.brand.keywords.length > 0) {
        brand.keywords = this.brand.keywords;
      } else {
        // brand.keywords = [];
      }


      if (
        this.brand.identityManual
        && this.brand.identityManual.links
        && this.brand.identityManual.links.length > 0
      ) {
        brand.identityManual = this.brand.identityManual.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      }


      if (
        this.brand.logo
        && this.brand.logo.links
        && this.brand.logo.links.length > 0
      ) {
        brand.logo = this.brand.logo.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      }


      if (
        this.brand.font
        && this.brand.font.links
        && this.brand.font.links.length > 0
      ) {
        brand.font = this.brand.font.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      }


      try {
        this.$apollo.mutate({
          mutation: COMPANY_BRAND_IDENTITY_UPDATE,
          variables: {
            id: this.companyID,
            brand,
          },
          update: (store, data) => {
            this.isUpdating = false;

            if (data.data && data.data.updateCompany && data.data.updateCompany.brand) {
              this.setBrand(data.data.updateCompany.brand);
            }
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_8_toaster_success_title_updated'),
            text: this.$t('15_8_toaster_success_description_updated'),
            type: 'success',
            duration: 5000,
          })
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_3_2_toaster_error_title_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })

        this.isUpdating = false;
      }
    },
    updateIdentityManual(identityManual) {
      this.identityManual = identityManual;
    },
    updateLogo(logo) {
      this.logo = logo;
    },
    updateFont(font) {
      this.font = font;
    },
    updateBrandManual(items) {
      this.brand.communicationManual.links = items;
    },
    updateResources(links) {
      this.brand.otherResources.links = links;
    },
    updateOtherResourcesLinks(links) {
      this.brand.otherResources.links = links;
    },
    updateIdentityManualLinks(links) {
      this.brand.identityManual.links = links;
    },
    updateLogoLinks(links) {
      this.brand.logo.links = links;
    },
    updateFontLinks(links) {
      this.brand.font.links = links;
    },
    updateVisualIdentity() {

    },
    updateKeywords(items) {
      this.brand.keywords = arrayClone(items)
    },
    updateHashtags(items) {
      this.brand.hashtags = arrayClone(items)
    },
    refetch() {
      this.$apollo.queries.companies.refetch();
    },
    confirmLeave(to, from, next) {
      if (this.hasDataChanged) {
        this.showLeaveModal = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    },
    handleModalLeaveClose() {
      this.showLeaveModal = false;
    },
    handleModalLeaveConfirm() {
      this.brand = obj_clone(this.originalBrand);

      this.showLeaveModal = false;
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.confirmLeave(to, from, next);
  },
}
</script>
