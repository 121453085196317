<template>
  <div class="fp-item">
    <div v-if="type === 'read'" class="fp-item-read">
      <div class="fp-item-header">
        <div class="fp-item-header-title">
          {{ item.name }}
        </div>
        <div class="fp-item-header-buttons">
          <Button @click="handleOpen" size="slim" type="secondary">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.0013 4.49935H4.0013C3.64768 4.49935 3.30854 4.63982 3.05849 4.88987C2.80844 5.13992 2.66797 5.47906 2.66797 5.83268V12.4993C2.66797 12.853 2.80844 13.1921 3.05849 13.4422C3.30854 13.6922 3.64768 13.8327 4.0013 13.8327H10.668C11.0216 13.8327 11.3607 13.6922 11.6108 13.4422C11.8608 13.1921 12.0013 12.853 12.0013 12.4993V8.49935M7.33464 9.16602L13.3346 3.16602M13.3346 3.16602H10.0013M13.3346 3.16602V6.49935" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </Button>
          <Button @click="handleEdit" size="slim" type="secondary">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.66797 5.16675H4.0013C3.64768 5.16675 3.30854 5.30722 3.05849 5.55727C2.80844 5.80732 2.66797 6.14646 2.66797 6.50008V12.5001C2.66797 12.8537 2.80844 13.1928 3.05849 13.4429C3.30854 13.6929 3.64768 13.8334 4.0013 13.8334H10.0013C10.3549 13.8334 10.6941 13.6929 10.9441 13.4429C11.1942 13.1928 11.3346 12.8537 11.3346 12.5001V11.8334M10.668 3.83341L12.668 5.83341M13.5913 4.89007C13.8539 4.62751 14.0014 4.27139 14.0014 3.90007C14.0014 3.52875 13.8539 3.17264 13.5913 2.91007C13.3287 2.64751 12.9726 2.5 12.6013 2.5C12.23 2.5 11.8739 2.64751 11.6113 2.91007L6.0013 8.50007V10.5001H8.0013L13.5913 4.89007Z" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </Button>
          <Button v-if="!confirmDelete" @click="handleDelete" size="slim" type="secondary">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66797 5.16667H13.3346M6.66797 7.83333V11.8333M9.33464 7.83333V11.8333M3.33464 5.16667L4.0013 13.1667C4.0013 13.5203 4.14178 13.8594 4.39183 14.1095C4.64187 14.3595 4.98101 14.5 5.33464 14.5H10.668C11.0216 14.5 11.3607 14.3595 11.6108 14.1095C11.8608 13.8594 12.0013 13.5203 12.0013 13.1667L12.668 5.16667M6.0013 5.16667V3.16667C6.0013 2.98986 6.07154 2.82029 6.19656 2.69526C6.32159 2.57024 6.49116 2.5 6.66797 2.5H9.33464C9.51145 2.5 9.68102 2.57024 9.80604 2.69526C9.93106 2.82029 10.0013 2.98986 10.0013 3.16667V5.16667" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </Button>
          <Button v-if="confirmDelete" @click="handleConfirmDelete" size="slim" type="delete">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66797 5.16667H13.3346M6.66797 7.83333V11.8333M9.33464 7.83333V11.8333M3.33464 5.16667L4.0013 13.1667C4.0013 13.5203 4.14178 13.8594 4.39183 14.1095C4.64187 14.3595 4.98101 14.5 5.33464 14.5H10.668C11.0216 14.5 11.3607 14.3595 11.6108 14.1095C11.8608 13.8594 12.0013 13.5203 12.0013 13.1667L12.668 5.16667M6.0013 5.16667V3.16667C6.0013 2.98986 6.07154 2.82029 6.19656 2.69526C6.32159 2.57024 6.49116 2.5 6.66797 2.5H9.33464C9.51145 2.5 9.68102 2.57024 9.80604 2.69526C9.93106 2.82029 10.0013 2.98986 10.0013 3.16667V5.16667" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </Button>
        </div>
      </div>
      <div class="fp-item-body">
        <div class="fp-item-items">
          <div class="fp-item-items-label">
            {{ $t('stdc_framework_label_personas') }}:
          </div>
          <div class="fp-item-items-data">
            <Label
              :title="item.persona.name"
              :item="item.persona"
            />
          </div>
        </div>

        <div class="fp-item-items">
          <div class="fp-item-items-label">
            {{ $t('stdc_framework_label_products_and_services') }}:
          </div>
          <div class="fp-item-items-data">
            <Label
              :title="item.product.name"
              :item="item.product"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Label from "@/components/sources/components/label/Label.vue";
import Button from "@/components/button/Button.vue";
import STDCFrameworksAndPhasesSelect
  from "@/components/sources/components/stdcFrameworksAndPhases/STDCFrameworksAndPhasesSelect.vue";
import COMPANY_PRODUCT_DETAIL from "@/graphql/CompanyProductDetail.gql";
import {arrayClone} from "@/services/helpers/clone";

export default {
  components: {
    STDCFrameworksAndPhasesSelect,
    Button,
    Label
  },
  apollo: {
    companies: {
      query: COMPANY_PRODUCT_DETAIL,
      variables() {
        return {
          id: this.companyID,
          productId: this.$route.params.productId
        }
      },
      update: function(data) {

        try {
          const company = data.companies.find((item) => item.id === this.companyID);

          this.channels = arrayClone(company.channels).map((channel) => {
            return {
              value: channel.id,
              name: channel.name
            }
          })

          this.personas = arrayClone(company.personas).map((persona) => {
            return {
              value: persona.id,
              name: persona.name
            }
          })

          const product = company.products.find((item) => item.id === this.$route.params.productId);
          this.setProduct(product);

        } catch(e) {

        }
      }
    },
  },
  directives: {
    ClickOutside
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      type: 'read',
      confirmDelete: false,
    }
  },
  props: ['item'],
  methods: {
    handleOpen() {
      try {
        this.$router.push({ path: `/company/${this.companyID}/stdc-detail/${this.item.id}` });
      } catch(e) {}
    },
    handleEdit() {
      this.$emit('editSTDC', this.item);
    },
    handleDelete() {
      this.confirmDelete = true;

      this.$notify({
        title: this.$t("stdc_frameworks_and_phases_title_confirm_delete"),
        text: this.$t("stdc_frameworks_and_phases_confirm_delete"),
        type: "info",
        duration: 5000,
      });

      setTimeout(() => {
        this.confirmDelete = false;
      }, 2000);
    },
    handleConfirmDelete() {
      this.confirmDelete = false;
      this.$emit('deleteSTDC', this.item.id);
    },
  }
}
</script>

<style scoped>
.fp-item {
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 20px;
}

.fp-item-read {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 0
}

.fp-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.fp-item-header-title {
  font-family: Nunito;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0;
  color: #17252A;
}

.fp-item-header-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fp-item-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fp-item-items {
  display: flex;
  gap: 8px;
}

.fp-item-items-label {
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0;
  color: #888F91;
}

.fp-item-items-body {

}

.fp-item-edit-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.fp-item-edit {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 16px;
}

.fp-item-select-btn {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.fp-item-select-btn > button{
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #E7E9E9;
  border: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fp-item-select-btn > button:hover {
  background: #d1d4d4;
  color: #17252a;
}
</style>
