<template>
  <ContentWrapper>
    <NoChannels
      v-if="list.length === 0"
      @add="handleAddChannel"
    />

    <div v-if="list.length > 0" class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_9_1_3_title') }}</h1>

        <div class="submenu">
          <div class="submenu-count">
            <div class="submenu-count-label">{{ $t('15_9_1_3_label_items_count') }}:</div>
            {{ list.length }}
          </div>
          <div class="submenu-items">
            <Button size="nav" type="secondary" @click="showModalEditImportances = true">
              <IcoChartPie/>
              {{ $t('15_9_1_3_btn_edit_importances') }}
            </Button>
            <Button @click="handleAddChannel" size="nav" type="primary">
              <IcoPlus/>
              {{ $t('15_9_1_3_btn_add_channel') }}
            </Button>
          </div>
        </div>

        <div class="items">
          <ChannelCard
            v-for="(item, index) in list"
            :item="item"
            :key="item.id"
            @delete="handleDeleteChannel(item)"
          />
        </div>

      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalEditImportances"
      @closeModal="showModalEditImportances = false"
    >
      <ModalEditImportances
        @close="showModalEditImportances = false"
        @update="updateImportances"
        v-model="list"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalAdd"
      @closeModal="showModalAdd = false"
    >
      <ChannelModalAdd
        @close="showModalAdd = false"
        @create="createChannel"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <ChannelModalDelete
        :item="channelToDelete"
        @close="showModalDelete = false"
        @delete="deleteChannel"
      />
    </Modal>

  </ContentWrapper>
</template>

<script>
import NoPersonas from "@/components/sources/components/personas/NoPersonas.vue";
import PersonaCard from "@/components/sources/components/personas/PersonaCard.vue";
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import IcoChartPie from "@/components/svg/IcoChartPie.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import NoProducts from "@/components/sources/components/products/NoProducts.vue";
import NoStdc from "@/components/sources/components/stdc/NoStdc.vue";
import NoChannels from "@/components/sources/components/channels/NoChannels.vue";
import ChannelCard from "@/components/sources/components/channels/ChannelCard.vue";
import ModalEditImportances from "@/components/sources/components/ModalEditImportances.vue";
import Modal from "@/components/Modal.vue";
import ChannelModalAdd from "@/components/sources/components/channels/ChannelModalAdd.vue";
import ProductCard from "@/components/sources/components/products/ProductCard.vue";
import StdcModalDelete from "@/components/sources/components/stdc/StdcModalDelete.vue";
import ChannelModalDelete from "@/components/sources/components/channels/ChannelModalDelete.vue";

import COMPANY_CHANNEL from '@/graphql/CompanyChannel.gql';
import CHANNEL_CREATE from "@/graphql/ChannelCreate.gql";
import CHANNEL_DELETE from "@/graphql/ChannelDelete.gql";
import CHANNEL_UPDATE_RATIO from "@/graphql/ChannelUpdateRatio.gql";

export default {
  components: {
    ChannelModalDelete,
    StdcModalDelete,
    ProductCard,
    ChannelModalAdd,
    Modal,
    ModalEditImportances,
    ChannelCard,
    NoChannels,
    NoStdc,
    NoProducts,
    ContentWrapper,
    IcoChartPie,
    PersonaCard,
    NoPersonas,
    Button,
    IcoPlus
  },
  apollo: {
    companies: {
      query: COMPANY_CHANNEL,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];

        try {
          if (company && Array.isArray(company.channels)) {
            this.list = company.channels;
          }
        } catch(e) {
          this.list = [];
        }
      }
    },
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      showList: false,
      showModalEditImportances: false,
      showModalAdd: false,
      showModalDelete: false,
      list: [],
      channelToDelete: null,
    }
  },
  methods: {
    handleAddChannel() {
      this.showList = true;
      this.showModalAdd = true;
    },
    createChannel(channel) {
      // console.log("channel: ", channel);
      this.showModalAdd = false;

      const _channel = {};
      _channel.companyId = this.companyID;

      if (channel.color.name) {
        _channel.color = {
          name: channel.color.name,
          cmyk: '',
          hex: channel.color.hex
        }
      }

      if (channel.keyInsights) {
        _channel.keyInsights = channel.keyInsights;
      }

      if (channel.name) {
        _channel.name = channel.name;
      }

      try {
        this.$apollo.mutate({
          mutation: CHANNEL_CREATE,
          variables: {
            channel: _channel
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();

            this.$notify({
              title: this.$t('15_7_1_3_toaster_success_title_create'),
              text: this.$t('15_7_1_3_toaster_success_description_create'),
              type: 'success',
              duration: 5000,
            })
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_7_1_3_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    handleDeleteChannel(channel) {
      this.showModalDelete = true;
      this.channelToDelete = channel;
    },
    deleteChannel(channel) {
      try {
        this.$apollo.mutate({
          mutation: CHANNEL_DELETE,
          variables: {
            id: channel.id
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();

            this.$notify({
              title: this.$t('15_7_1_3_toaster_success_title_create'),
              text: this.$t('15_7_1_3_toaster_success_description_create'),
              type: 'success',
              duration: 5000,
            })
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_7_1_3_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateImportances(importances) {
      try {
        this.$apollo.mutate({
          mutation: CHANNEL_UPDATE_RATIO,
          variables: {
            ratios: importances.map((item) => {
              return {
                ratio: item.ratio,
                id: item.id
              }
            })
          },
          update: (store, data) => {
            this.showModalEditImportances = false;
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    }
  }
}
</script>

<style scoped>
.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.submenu {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.submenu-count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  gap: 4px;
}

.submenu-count-label {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #AEB3B5;
}

.submenu-items {
  display: flex;
  gap: 16px;
}
</style>
