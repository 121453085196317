<template>
  <div @click="handleOpen" class="progress-label">
    <div class="progress-label-content">
      <div class="progress-label-title">{{ $t('progress_tasks_title') }} <IcoArrow /></div>
      <div class="progress-label-description">{{ completed }} / 10 {{ $t('progress_tasks_completed') }}</div>
    </div>
    <div class="progress-label-line">
      <div class="progress-label-line-current" :style="{'width': progress}"></div>
    </div>
  </div>
</template>

<script>
import IcoArrow from "@/components/progressLabel/IcoArrow.vue";
import COMPANY_PROGRESS from "@/graphql/CompanyProgress.gql";

export default {
  apollo: {
    companies: {
      query: COMPANY_PROGRESS,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const company = data.companies.find((item) => item.id === this.companyID);

        try {
          let completed = 0;

          if(company.channels.length > 0) {
            completed += 1;
          }

          if(company.competitors.length > 0) {
            completed += 1;
          }

          if(company.leanCanvases.length > 0) {
            completed += 1;
          }

          if(company.personas.length > 0) {
            completed += 1;
          }

          if(company.products.length > 0) {
            completed += 1;
          }

          if(company.stdcs.length > 0) {
            completed += 1;
          }

          if(company.userCount > 1) {
            completed += 1;
          }

          if(company.toneOfVoice !== null) {
            completed += 1;
          }

          if(company.brand !== null) {
            completed += 1;
          }

          if(company.description !== '') {
            completed += 1;
          }

          this.completed = completed;
          this.progress = Math.round((completed / 10) * 100) + '%';
        } catch(e) { }
      }
    },
  },
  components: {
    IcoArrow
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      progress: '0',
      completed: 0
    }
  },
  methods: {
    handleOpen() {
      this.$emit('open');
    }
  },
  watch: {
    companyID() {
      this.progress = '0';
      this.completed = 0;
      this.$apollo.queries.companies.refetch();
    }
  }
}
</script>

<style scoped>
  .progress-label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #9795FF14;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
  }

  .progress-label-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .progress-label-title {
    display: flex;
    justify-content: space-between;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #17252A;
  }

  .progress-label-description {
    font-family: Nunito;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #888F91;
  }

  .progress-label-line {
    display: block;
    width: 100%;
    height: 5px;
    border-radius: 4px;
    position: relative;
    background: #9795FF33;
  }

  .progress-label-line-current {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 4px;
    background: #9795FF;
  }
</style>
