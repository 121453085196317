<template>
  <div>
    <div class="upload-container">
      <div class="profile-picture" @click="showModal = true">
        <div class="profile-picture__image">
          <img
            v-if="imageValid"
            :src="`${imageUrl}?cb=${cacheBuster}`"
            alt="Company Logo"
          />
          <div v-else class="profile-picture__bg">
            <IcoNoImage />
          </div>
        </div>
        <div class="profile-picture__data">
          <div class="profile-picture__title">
            {{ title }}
          </div>
          <div class="profile-picture__name">
            {{ imageUrl ? $t('4_2_upload_logo_image') : $t('4_2_upload_logo_no_image') }}
          </div>
        </div>
      </div>
    </div>

    <Modal
      type="source"
      v-if="showModal"
      @closeModal="showModal = false"
    >
      <div class="modal-image-manager">
        <div class="modal-image-manager-body">

          <ul class="modal-image-tabs" :class="imageType">
            <li>
              <button :class="{'active': imageType === 'upload'}" @click="setTab('upload')">{{ $t('image_manager_upload_image') }}</button>
            </li>
            <li>
              <button :class="{'active': imageType === 'icon'}" @click="setTab('icon')">{{ $t('image_manager_select_icon') }}</button>
            </li>
          </ul>

          <div v-if="imageType === 'upload'" class="modal-image-manager-item modal-image-manager-item--upload">
            <div
              v-show="!edit"
              class="modal-image-manager-item-upload"
            >
              <file-upload
                :extensions="['gif','jpg','jpeg','png','webp']"
                accept="image/png,image/gif,image/jpeg,image/webp"
                name="file"
                :post-action="uploadUrl"
                :headers="{ Authorization: `Bearer ${accessToken}` }"
                :drop="!edit"
                v-model="files"
                @input-filter="inputFilter"
                @input-file="inputFile"
                @click="triggerUpload"
                ref="upload"
              >
                <div class="modal-image-manager-uploaded-image">
                  <div
                    v-if="imageValid"
                    class="modal-image-manager-uploaded-image-item"
                  >
                    <img
                      v-if="imageValid"
                      :src="`${imageUrl}?cb=${cacheBuster}`"

                      alt="Company Logo"
                    />
                    <button
                      v-if="imageUrl"
                      class="modal-image-manager-uploaded-image-delete"
                      @click.prevent="deleteImage"
                    >
                      <IcoCross />
                    </button>
                  </div>
                  <div v-else class="profile-picture__bg">
                    <IcoNoImage />
                  </div>
                </div>
              </file-upload>
            </div>


            <div class="avatar-edit" v-show="files.length && edit">
              <div class="avatar-edit-image" v-if="files.length">
                <img ref="editImage" :src="files[0].url" alt="Edit Image" />
              </div>
              <div class="avatar-edit-buttons">
                <Button @click.native="cancelEdit">
                  {{ $t('7_1_button_cancel_company_logo') }}
                </Button>
                <Button type="primary" @click.native="editSave">
                  {{ $t('7_1_button_save_company_logo') }}
                </Button>
              </div>
            </div>

          </div>

          <div v-if="imageType === 'icon'" class="modal-image-manager-item">

            <div style="height: 300px">
              <vuescroll :ops="ops">
                <div class="icon-grid">
                  <div
                    v-for="icon in icons"
                    :key="icon.key"
                    class="icon-item"
                    :class="{ selected: selectedIcon && selectedIcon.key === icon.key }"
                    @click="selectIcon(icon)"
                  >
                    <img :src="icon.url" :alt="icon.name" />
                  </div>
                </div>
              </vuescroll>
            </div>

            <Button style="width: 100%; margin-top: 20px;" v-if="selectedIcon !== null" @click="handleUseIcon()" type="primary">{{ $t('image_manager_use_icon') }}</Button>

          </div>
        </div>

        <div class="modal-image-manager-footer">
          <span></span>
          <Button @click="showModal = false" type="secondary">{{ $t('image_manager_close') }}</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import IcoCross from '@/components/svg/small/IcoCross.vue'
import IcoNoImage from '@/components/svg/IcoNoImage.vue'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import { URL, URLFiles } from '@/constants/URLConstant.js'
import Button from '@/components/button/Button.vue'
import Modal from "@/components/Modal.vue";
import ChannelModalDelete from "@/components/sources/components/channels/ChannelModalDelete.vue";
import ICON_CHANNEL_IMAGES from "@/graphql/IconChannelImages.gql";
import vuescroll from "vuescroll";

export default {
  name: 'CompanyLogoUploader',
  components: {
    vuescroll,
    ChannelModalDelete, Modal,
    IcoCross,
    IcoNoImage,
    FileUpload,
    Button,
  },
  apollo: {
    companies: {
      query: ICON_CHANNEL_IMAGES,
      update: function(data) {

        try {
          console.log("data: ", data);
          this.icons = data.channelImages;

        } catch(e) {

        }
      }
    },
  },
  props: {
    subCategory: {
      type: String,
      default: '',
    },
    subCategoryId: {
      type: String,
      default: '',
    },
    companyId: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    empty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIcon: null,
      icons: [],
      imageType: 'upload',
      showModal: false,
      files: [],
      edit: false,
      cropper: null,
      imageUrl: '',
      imageValid: false,
      currentAccessToken: '',
      cacheBuster: Date.now(),
      ops: {
        scrollPanel: {
          initialScrollY: true,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: 'right',
        },
      },
    }
  },
  computed: {
    mainUrl() {
      return URL;
    },
    uploadUrl() {
      return `${this.mainUrl}/api/profile-images/company/${this.companyId}?subCategory=${this.subCategory}&subId=${this.subCategoryId}`;
    },
    accessToken() {
      return this.currentAccessToken || localStorage.getItem('access_token');
    },
  },
  created() {
    this.loadImage();
  },
  methods: {
    handleUseIcon() {

    },
    selectIcon(icon) {
      this.selectedIcon = icon;
    },
    setTab(type) {
      this.imageType = type;
    },
    triggerUpload() {
      console.log("trigger upload");
      if (this.$refs.upload) {
        this.$refs.upload.active = true;
      }
    },
    async refreshToken() {
      try {
        const refresh_token = localStorage.getItem('refresh_token');
        const response = await fetch(`${URL}/api/login/refresh`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: refresh_token }),
        });
        const data = await response.json();
        if (data && data.access_token) {
          this.currentAccessToken = data.access_token;
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('refresh_token', data.refresh_token);
          return data.access_token;
        }
      } catch (error) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        console.error('Error refreshing token:', error);
        throw error;
      }
    },
    async deleteImage() {
      try {
        await this.refreshToken();
        const response = await fetch(this.uploadUrl, {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${this.accessToken}` },
        });
        if (response.ok) {
          this.imageUrl = '';
          this.imageValid = false;
          this.$notify({
            title: this.$t('7_1_toaster_success_title_deleted_company_logo'),
            text: this.$t('7_1_toaster_success_description_deleted_company_logo'),
            type: 'success',
            duration: 5000,
          });
          // this.loadImage();
        } else {
          throw new Error('Failed to delete image');
        }
      } catch (error) {
        console.error('Delete image error:', error);
        this.$notify({
          title: this.$t('7_1_toaster_error_title_upload_company_logo'),
          text: this.$t('7_1_toaster_error_description_upload_company_logo'),
          type: 'error',
          duration: 5000,
        });
      }
    },
    async loadImage() {
      this.imageValid = false;
      this.imageUrl = `${URLFiles}/images/company/${this.companyId}/${this.subCategory}/${this.subCategoryId}.jpg`;
      const img = new Image();
      img.src = this.imageUrl;
      img.onload = () => {
        this.imageValid = true;
        this.cacheBuster = Date.now();
      };
      img.onerror = () => {
        this.imageValid = false;
        this.imageUrl = '';
      };
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(() => {
          this.edit = true;
        });
      } else if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.$notify({
            title: this.$t('invalid_file_type'),
            text: this.$t('Please select a valid image file (gif, jpg, jpeg, png, webp).'),
            type: 'error',
            duration: 5000,
          });
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = '';
        const URLObj = window.URL || window.webkitURL;
        if (URLObj && URLObj.createObjectURL) {
          newFile.url = URLObj.createObjectURL(newFile.file);
        }
      }
    },
    async editSave() {
      if (!this.cropper || !this.files[0]) return;
      this.edit = false;
      const oldFile = this.files[0];
      // Use toBlob for better performance and reliability if available
      this.cropper.getCroppedCanvas().toBlob(async (blob) => {
        const file = new File([blob], oldFile.name, { type: oldFile.type });
        try {
          await this.refreshToken();
          this.$refs.upload.update(oldFile.id, {
            file,
            type: file.type,
            size: file.size,
            active: true,
          });
        } catch (error) {
          console.error('Error saving edited image:', error);
        }
      }, oldFile.type);
    },
    cancelEdit() {
      this.edit = false;
      if (this.$refs.upload) {
        this.$refs.upload.clear();
      }
    },
  },
  watch: {
    edit(newVal) {
      this.$nextTick(() => {
        if (newVal) {
          if (!this.$refs.editImage) return;
          this.cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1,
            viewMode: 1,
            autoCropArea: 0.65,
          });
        } else if (this.cropper) {
          this.cropper.destroy();
          this.cropper = null;
        }
      });
    },
    subCategoryId() {
      this.loadImage();
    },
    files: {
      deep: true,
      handler(newFiles) {
        const file = newFiles[0];
        if (file) {
          if (file.success === true) {
            this.$notify({
              title: this.$t('7_1_toaster_success_title_upload_company_logo'),
              text: this.$t('7_1_toaster_success_description_upload_company_logo'),
              type: 'success',
              duration: 5000,
            });
            this.imageUrl = file.url;
            this.cacheBuster = Date.now();
            this.loadImage();
          }
          if (file.error && file.error !== '') {
            this.$notify({
              title: this.$t('7_1_toaster_error_title_upload_company_logo'),
              text: this.$t('7_1_toaster_error_description_upload_company_logo'),
              type: 'error',
              duration: 5000,
            });
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.upload-container {
  position: relative;
}
.profile-picture {
  display: flex;
  gap: 20px;
  cursor: pointer;
}
.profile-picture__image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #c5c5c5;
  border-radius: 6px;
  width: 52px;
  min-width: 52px;
  max-width: 52px;
  height: 52px;
}
.profile-picture__image img {
  width: 36px;
  height: 36px;
  border-radius: 6px;
}
.profile-picture__bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 44px;
  height: 44px;
}
.profile-picture__delete {
  position: absolute;
  top: -6px;
  left: 42px;
  z-index: 9;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background: #17252a;
  padding: 0px;
  width: 22px;
  height: 22px;
}
.profile-picture__delete svg path {
  stroke: #fff;
}
.profile-picture__data {
  text-align: left;
}
.profile-picture__title {
  color: #17252a;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.profile-picture__name {
  margin-top: 1px;
  color: #888f91;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.avatar-edit {
  margin-top: 0;
}
.avatar-edit-image {
  max-width: 100%;
  margin-top: 0;
}
.avatar-edit-buttons {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0;
}
.file-uploads {
  overflow: visible !important;
}

.modal-image-manager {
  width: 450px;
  padding: 30px;
}

.modal-image-tabs {
  list-style-type: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 4px;
  border-radius: 4px;
  background-color: #E7E9E9;

  li {
    width: 100%;

    button {
      width: 100%;
      border: none;
      background: transparent;
      border-radius: 4px;
      font-family: Nunito;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0;
      text-align: center;
      color: #888F91;
      padding: 12px 0;
      text-align: center;
      cursor: pointer;

      &.active {
        background-color: #fff;
        color: #17252A;
      }
    }
  }
}

.modal-image-manager-item {
  padding: 15px 0 25px 0;
  min-height: 300px;
}

.modal-image-manager-item .profile-picture__bg {
  width: 200px;
  height: 200px;
}

.modal-image-manager-item--upload {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  min-height: 200px;
}

.modal-image-manager-footer {
  display: flex;
  justify-content: space-between;
}

.modal-image-manager-uploaded-image {
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  img {
    cursor: pointer;
  }
}

.modal-image-manager-item-upload {
  position: relative;
  display: inline-flex;
  justify-content: center;
  border: 1px dashed #c5c5c5;
  padding: 4px;
  border-radius: 4px;
  margin: 0 auto;
  min-height: 200px;
  min-width: 200px;

  img {
    border-radius: 4px;
  }
}

.modal-image-manager-uploaded-image-item {
  position: relative;
  display: block;
  min-height: 200px;
  min-width: 200px;
}

.file-uploads label {
  cursor: pointer;
}

.modal-image-manager-uploaded-image-delete {
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 9;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background: #17252a;
  padding: 0;
  width: 22px;
  height: 22px;

  svg path {
    stroke: #fff;
  }
}

.icon-grid {
  display: flex;
  flex-wrap: wrap;
}
.icon-item {
  width: 25%; /* 4 items per row */
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.icon-item img {
  max-width: 100%;
  display: block;
}
.icon-item.selected {
  border: 1px dashed #c5c5c5;
  border-radius: 4px;
}
</style>
