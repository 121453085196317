<template>
  <div
    class="source-label"
    :class="{'is-empty': !hasImage}"
    @click="handleOpenDetail()"
  >
    <div
      v-if="hasImage"
      class="source-label-ico"
      :style="backgroundStyle"
    ></div>
    <div
      class="source-label-text"
      :style="{'color': color}"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  computed: {
    hasImage() {
      return !!(this.item.logo && this.item.logo.url);
    },
    companyID() {
      return this.$route.params.id;
    },
    backgroundStyle() {
      if (!this.item) {
        return null;
      }

      if (!this.item.logo?.url) {
        return null;
      }

      return {
        backgroundImage: `url(${this.item.logo.url})`
      };
    },
    type() {
      switch ( this.item.__typename ) {
        case 'Product':
          return 'products-and-services-detail';

        case 'Persona':
          return 'personas-and-audiences-detail';

        case 'Channel':
          return 'channel-detail';

        case 'Stdc':
          return 'stdc-detail';

        case 'Competitor':
          return 'competitors-detail';
      }
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          logo: null,
          name: 'test'
        }
      }
    },
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'black',
    },
  },
  methods: {
    handleOpenDetail() {
      this.$router.push({ path: `/company/${this.companyID}/${this.type}/${this.item.id}` });
    }
  }
}
</script>

<style scoped>
.source-label {
  padding: 3px 4px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 3px;
  border: 1px solid #D1D4D4;
  gap: 6px;
  max-height: 22px;
  overflow: hidden;
  cursor: pointer;
}

.source-label:hover {
  border: 1px solid #888F91;
}

.source-label:active {
  border: 1px solid #17252A;
}

.source-label.is-empty .source-label-text {
  padding-left: 4px;
}

.source-label-ico {
  width: 16px;
  height: 14px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.source-label-text {
  padding-right: 4px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: left;
}
</style>
