<template>
  <ContentWrapper :disable-top-offset="true">

    <SlickMenu :title="competitor.name" >
      <Button @click="handleDelete" size="nav" type="delete">
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33398 4.83333H14.6673M6.33398 8.16667V13.1667M9.66732 8.16667V13.1667M2.16732 4.83333L3.00065 14.8333C3.00065 15.2754 3.17625 15.6993 3.48881 16.0118C3.80137 16.3244 4.22529 16.5 4.66732 16.5H11.334C11.776 16.5 12.1999 16.3244 12.5125 16.0118C12.8251 15.6993 13.0007 15.2754 13.0007 14.8333L13.834 4.83333M5.50065 4.83333V2.33333C5.50065 2.11232 5.58845 1.90036 5.74473 1.74408C5.90101 1.5878 6.11297 1.5 6.33398 1.5H9.66732C9.88833 1.5 10.1003 1.5878 10.2566 1.74408C10.4129 1.90036 10.5007 2.11232 10.5007 2.33333V4.83333" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_10_2_1_btn_delete') }}
      </Button>

      <!--
      <Button size="nav" type="secondary">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.67559 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.67559 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333M14.1667 7.5V4.16667C14.1667 3.72464 13.9911 3.30072 13.6785 2.98816C13.366 2.67559 12.942 2.5 12.5 2.5H7.5C7.05797 2.5 6.63405 2.67559 6.32149 2.98816C6.00893 3.30072 5.83333 3.72464 5.83333 4.16667V7.5M5.83333 12.5C5.83333 12.058 6.00893 11.634 6.32149 11.3215C6.63405 11.0089 7.05797 10.8333 7.5 10.8333H12.5C12.942 10.8333 13.366 11.0089 13.6785 11.3215C13.9911 11.634 14.1667 12.058 14.1667 12.5V15.8333C14.1667 16.2754 13.9911 16.6993 13.6785 17.0118C13.366 17.3244 12.942 17.5 12.5 17.5H7.5C7.05797 17.5 6.63405 17.3244 6.32149 17.0118C6.00893 16.6993 5.83333 16.2754 5.83333 15.8333V12.5Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Print
        {{ $t('15_10_2_1_btn_print') }}
      </Button>
      -->

      <Button size="nav" type="primary" @click="handleUpdateCompetitor" :disabled="isUpdating">{{ $t('15_10_2_1_btn_save') }}</Button>
    </SlickMenu>

    <div class="product-detail">
      <div class="product-detail-body">
        <div class="column">
          <div class="boxed boxed--full">
            <div class="box">
              <h2>{{  $t('15_10_2_1_title_main_info') }}</h2>

              <div class="column">
                <FormGroup :label="$t('15_10_2_1_label_competitor_title')" :required="true">
                  <input
                    type="text"
                    :placeholder="$t('15_10_2_1_placeholder_competitor_title')"
                    v-model="competitor.name"
                    @input="validationError.title = false"
                    ref="contentName"
                  />
                </FormGroup>
                <div class="col-full">
                  <ImageUploader
                    :title="$t('15_10_2_1_label_competitor_image')"
                    sub-category="competitor"
                    :sub-category-id="competitor.id"
                    :company-id="companyID"
                  />
                </div>
              </div>

              <div>
                <FormGroup :label="$t('15_10_2_1_label_key_insight')">
                  <input
                    type="text"
                    :placeholder="$t('15_10_2_1_placeholder_key_insight')"
                    v-model="competitor.keyInsights"
                    ref="contentName"
                  />
                </FormGroup>
                <Hint>{{ $t('15_10_2_1_hint_key_insight') }}</Hint>
              </div>

              <div class="column">
                <Dropdown
                  v-model="competitor.products"
                  :multiple="true"
                  :label="$t('15_10_2_1_label_products_and_services')"
                  :placeholder="$t('15_10_2_1_placeholder_products_and_services')"
                  :items="products"
                />
                <ButtonAdd />
              </div>

              <div
                v-if="originalCompetitor.webLinks.length !== 0"
                class="column"
              >
                <FormGroup :label="$t('15_10_2_1_label_corporate_website')" :required="false">
                  <Resources
                    :items="competitor.webLinks"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_10_2_1_add_corporate_website"
                    @updateResources="updateWebLinks"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div
                v-if="originalCompetitor.otherRelevantLinks.length !== 0"
                class="column"
              >
                <FormGroup :label="$t('15_10_2_1_label_other_relevant_link')" :required="false">
                  <Resources
                    :items="competitor.otherRelevantLinks"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_10_2_1_add_other_relevant_link"
                    @updateResources="updateOtherRelevantLinks"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>


              <Accordion
                v-if="originalCompetitor.webLinks.length === 0 || originalCompetitor.otherRelevantLinks.length === 0"
                :title="$t('15_10_2_1_accordion_title')"
                :description="$t('15_10_2_1_accordion_placeholder')"
              >

                <div
                  v-if="originalCompetitor.webLinks.length === 0"
                  class="column"
                >
                  <FormGroup :label="$t('15_10_2_1_label_corporate_website')" :required="false">
                    <Resources
                      :items="competitor.webLinks"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      transCreateNew="15_10_2_1_add_corporate_website"
                      @updateResources="updateWebLinks"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>

                <div
                  v-if="originalCompetitor.otherRelevantLinks.length === 0"
                  class="column"
                >
                  <FormGroup :label="$t('15_10_2_1_label_other_relevant_link')" :required="false">
                    <Resources
                      :items="competitor.otherRelevantLinks"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      transCreateNew="15_10_2_1_add_other_relevant_link"
                      @updateResources="updateOtherRelevantLinks"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>
              </Accordion>

            </div>
          </div>





          <div class="boxed boxed--full">
            <div class="box">
              <h2>{{  $t('15_10_2_1_title_other_info') }}</h2>

              <div
                class="column"
              >
                <FormGroup :label="$t('15_10_2_1_label_unique')" :required="false">
                  <Resources
                    :items="competitor.uniqueness"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    :name-only="true"
                    :name-large="true"
                    transCreateNew="15_10_2_1_add_unique"
                    @updateResources="updateUniqueness"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div
                class="column"
              >
                <FormGroup :label="$t('15_10_2_1_label_similar')" :required="false">
                  <Resources
                    :items="competitor.similarities"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    :name-only="true"
                    :name-large="true"
                    transCreateNew="15_10_2_1_add_similar"
                    @updateResources="updateSimilarities"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div
                v-if="originalCompetitor.betterIn.length !== 0"
                class="column"
              >
                <FormGroup :label="$t('15_10_2_1_label_better')" :required="false">
                  <Resources
                    :items="competitor.betterIn"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    :name-only="true"
                    :name-large="true"
                    transCreateNew="15_10_2_1_add_better"
                    @updateResources="updateBetterIn"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div
                v-if="originalCompetitor.worseIn.length !== 0"
                class="column"
              >
                <FormGroup :label="$t('15_10_2_1_label_worse')" :required="false">
                  <Resources
                    :items="competitor.worseIn"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    :name-only="true"
                    :name-large="true"
                    transCreateNew="15_10_2_1_add_worse"
                    @updateResources="updateWorseIn"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div
                v-if="originalCompetitor.howWeWin.length !== 0"
                class="column"
              >
                <FormGroup :label="$t('15_10_2_1_label_how_we_win')" :required="false">
                  <Resources
                    :items="competitor.howWeWin"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    :name-only="true"
                    :name-large="true"
                    transCreateNew="15_10_2_1_add_how_we_win"
                    @updateResources="updateHowWeWin"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div
                v-if="originalCompetitor.note"
                class="column"
              >
                <FormGroup :label="$t('15_10_2_1_label_note')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_10_2_1_placeholder_note')"
                      v-model="competitor.note"
                      @input="validationError.description = false"
                      ref="contentName"
                    ></textarea>
                </FormGroup>
              </div>

              <div
                v-if="(
                (originalCompetitor.otherResources && originalCompetitor.otherResources.links.length !== 0)
                || (originalCompetitor.otherResources && originalCompetitor.otherResources.files.length !== 0))"
                class="column"
              >
                <FormGroup
                  :label="$t('15_10_2_1_label_other_resources')"
                  :required="false"
                >
                  <h2 class="files-title">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    {{ $t('special_list_links_title') }}
                    ({{competitor.otherResources.links.length}})
                  </h2>
                  <Resources
                    :items="competitor.otherResources.links"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_10_2_1_add_other_resources"
                    @updateResources="updateOtherResourcesLinks"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                  <SourcesFileUploader
                    query-type="competitor"
                    file-type="competitor-resource"
                    :destinationId="this.$route.params.competitorId"
                    :attachments="competitor.otherResources.files"
                    @refresh="refetch"
                    type="edit"
                  ></SourcesFileUploader>
                </FormGroup>
              </div>

              <Accordion
                v-if="originalCompetitor.note === ''
                || originalCompetitor.betterIn.length === 0
                || originalCompetitor.worseIn.length === 0
                || originalCompetitor.howWeWin.length === 0
                || !originalCompetitor.note
                || (originalCompetitor.otherResources.links.length === 0 && originalCompetitor.otherResources.files.length === 0)
                "
                :title="$t('15_10_2_1_accordion_other_info_title')"
                :description="$t('15_10_2_1_other_info_description')"
              >
                <div
                  v-if="originalCompetitor.betterIn.length === 0"
                  class="column"
                >
                  <FormGroup :label="$t('15_10_2_1_label_better')" :required="false">
                    <Resources
                      :items="competitor.betterIn"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      :name-only="true"
                      :name-large="true"
                      transCreateNew="15_10_2_1_add_better"
                      @updateResources="updateBetterIn"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>

                <div
                  v-if="originalCompetitor.worseIn.length === 0"
                  class="column"
                >
                  <FormGroup :label="$t('15_10_2_1_label_worse')" :required="false">
                    <Resources
                      :items="competitor.worseIn"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      :name-only="true"
                      :name-large="true"
                      transCreateNew="15_10_2_1_add_worse"
                      @updateResources="updateWorseIn"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>

                <div
                  v-if="originalCompetitor.howWeWin.length === 0"
                  class="column"
                >
                  <FormGroup :label="$t('15_10_2_1_label_how_we_win')" :required="false">
                    <Resources
                      :items="competitor.howWeWin"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      :name-only="true"
                      :name-large="true"
                      transCreateNew="15_10_2_1_add_how_we_win"
                      @updateResources="updateHowWeWin"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>

                <div
                  v-if="!originalCompetitor.note"
                  class="column"
                >
                  <FormGroup :label="$t('15_10_2_1_label_note')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_10_2_1_placeholder_note')"
                      v-model="competitor.note"
                      @input="validationError.description = false"
                      ref="contentName"
                    ></textarea>
                  </FormGroup>
                </div>

                <div
                  v-if="originalCompetitor.otherResources.links.length === 0 && originalCompetitor.otherResources.files.length === 0"
                  class="column"
                >
                  <FormGroup
                    :label="$t('15_10_2_1_label_other_resources')"
                    :required="false"
                  >
                    <h2 class="files-title">
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      {{ $t('special_list_links_title') }}
                      ({{competitor.otherResources.links.length}})
                    </h2>
                    <Resources
                      :items="competitor.otherResources.links"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      transCreateNew="15_10_2_1_add_other_resources"
                      @updateResources="updateOtherResourcesLinks"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                    <SourcesFileUploader
                      query-type="competitor"
                      file-type="competitor-resource"
                      :destinationId="this.$route.params.competitorId"
                      :attachments="competitor.otherResources.files"
                      @refresh="refetch"
                      type="edit"
                    ></SourcesFileUploader>
                  </FormGroup>
                </div>
              </Accordion>

            </div>
          </div>

        </div>
      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <CompetitorModalDelete
        :title="competitor.name"
        @close="showModalDelete = false"
        @delete="deleteCompetitor"
      />
    </Modal>

    <ModalConfirmClose
      v-if="showLeaveModal"
      @close="handleModalLeaveClose"
      @confirm="handleModalLeaveConfirm"
    />
  </ContentWrapper>
</template>

<script>
import Dropdown from "@/components/dropdown/Dropdown.vue";
import Button from "@/components/button/Button.vue";
import FormGroup from "@/components/FormGroup.vue";
import Resources from "@/components/design/Resources.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import Avatar from "@/components/Avatar.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import Hint from "@/components/sources/components/hint/Hint.vue";
import ButtonAdd from "@/components/buttonCopy/ButtonAdd.vue";

import COMPANY_COMPETITOR_DETAIL from '@/graphql/CompanyCompetitorDetail.gql';
import COMPETITOR_PRODUCT_UPDATE from '@/graphql/CompetitorProductUpdate.gql';
import {arrayClone, obj_clone} from "@/services/helpers/clone";
import SourcesFileUploader from "@/components/sources/components/sourcesFileUploader/SourcesFileUploader.vue";
import COMPANY_COMPETITOR_FILES from "@/graphql/CompanyCompetitorFiles.gql";
import COMPETITOR_UPDATE from "@/graphql/CompetitorUpdate.gql";
import ModalConfirmClose from "@/components/ModalConfirmClose.vue";
import {removeTypename} from "@/utils/utils";
import Modal from "@/components/Modal.vue";
import ProductsModalDelete from "@/components/sources/components/products/ProductsModalDelete.vue";
import CompetitorModalDelete from "@/components/sources/components/competitors/CompetitorModalDelete.vue";
import COMPETITOR_DELETE from "@/graphql/CompetitorDelete.gql";
import ImageUploader from "@/components/ImageUploader.vue";

export default {
  components: {
    ImageUploader,
    CompetitorModalDelete,
    ProductsModalDelete, Modal,
    ModalConfirmClose,
    SourcesFileUploader,
    ButtonAdd,
    Hint,
    SlickMenu,
    ContentWrapper,
    CompanyLogo,
    Accordion,
    Resources,
    FormGroup,
    Button,
    Avatar,
    Dropdown
  },
  apollo: {
    companies: {
      query: COMPANY_COMPETITOR_DETAIL,
      variables() {
        return {
          id: this.companyID,
          competitorId: this.$route.params.competitorId
        }
      },
      update: function(data) {

        try {
          const company = data.companies.find((item) => item.id === this.companyID);

          this.products = arrayClone(company.products).map((product) => {
            return {
              value: product.id,
              name: product.name
            }
          })

          const competitor = company.competitors.find((item) => item.id === this.$route.params.competitorId);
          this.setCompetitor(competitor);

        } catch(e) {

        }
      }
    },
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    companyID() {
      return this.$route.params.id;
    },
    dataHasChanged() {
      return JSON.stringify(this.competitor) !== JSON.stringify(this.originalCompetitor);
    },
    productIds() {
      return this.competitor.products.map((product) => product.value);
    },
  },
  data() {
    return {
      competitor: {
        betterIn: [],
        howWeWin: [],
        keyInsights: '',
        name: '',
        note: '',
        otherResources: {
          files: [],
          links: []
        },
        ratio: '',
        similarities: [],
        otherRelevantLinks: [],
        webLinks: [],
        worseIn: [],
        products: [],
      },
      originalCompetitor: {
        betterIn: [],
        howWeWin: [],
        keyInsights: '',
        name: '',
        note: '',
        otherResources: {
          files: [],
          links: []
        },
        ratio: '',
        similarities: [],
        otherRelevantLinks: [],
        webLinks: [],
        worseIn: [],
        products: [],
      },

      showModalDelete: false,

      products: [],
      isUpdating: false,
      showLeaveModal: false,

      resources: [],
      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,

      description: '',
      title: '',
      validationError: {
        description: false,
        title: false,
      }
    }
  },
  methods: {
    updateOtherResourcesLinks(links) {
      this.competitor.otherResources.links = links;
    },
    updateWebLinks(links) {
      this.competitor.webLinks = links;
    },
    updateOtherRelevantLinks(links) {
      this.competitor.otherRelevantLinks = links;
    },
    updateUniqueness(items) {
      this.competitor.uniqueness = items;
    },
    updateSimilarities(items) {
      this.competitor.similarities = items;
    },
    updateBetterIn(items) {
      this.competitor.betterIn = items;
    },
    updateWorseIn(items) {
      this.competitor.worseIn = items;
    },
    updateHowWeWin(items) {
      this.competitor.howWeWin = items;
    },
    handleGoBack() {
      this.$router.go(-1);
    },
    handleDelete() {
      this.showModalDelete = true;
    },
    deleteCompetitor() {
      try {
        this.$apollo.mutate({
          mutation: COMPETITOR_DELETE,
          variables: {
            id: this.competitor.id
          },
          update: (store, data) => {
            this.$router.push({ path: `/company/${this.companyID}/competitors` });
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_10_2_1_toaster_success_title_deleted'),
            text: this.$t('15_10_2_1_toaster_success_description_deleted'),
            type: 'success',
            duration: 5000,
          });
        })
      } catch(e) {
        this.$notify({
          title: this.$t('15_10_2_1_toaster_error_title_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    setCompetitor(competitor) {
      this.competitor.id = competitor.id;
      this.competitor.name = competitor.name;
      this.competitor.ratio = competitor.ratio;
      this.competitor.keyInsights = competitor.keyInsights;
      this.competitor.note = competitor.note;

      if (competitor.products) {
        this.competitor.products = competitor.products.map((item) => {
          return {
            name: item.name,
            value: item.id
          }
        });
      }

      if (competitor.betterIn) {
        this.competitor.betterIn = competitor.betterIn;
      } else {
        this.competitor.betterIn = []
      }

      if (competitor.howWeWin) {
        this.competitor.howWeWin = competitor.howWeWin;
      } else {
        this.competitor.howWeWin = [];
      }

      if (competitor.uniqueness) {
        this.competitor.uniqueness = competitor.uniqueness;
      } else {
        this.competitor.uniqueness = [];
      }

      if (competitor.similarities) {
        this.competitor.similarities = competitor.similarities;
      } else {
        this.competitor.similarities = [];
      }

      if (competitor.worseIn) {
        this.competitor.worseIn = competitor.worseIn;
      } else {
        this.competitor.worseIn = [];
      }

      if (competitor.otherRelevantLinks) {
        this.competitor.otherRelevantLinks = removeTypename(arrayClone(competitor.otherRelevantLinks));
      } else {
        this.competitor.otherRelevantLinks = [];
      }

      if (competitor.webLinks) {
        this.competitor.webLinks = removeTypename(arrayClone(competitor.webLinks));
      } else {
        this.competitor.webLinks = [];
      }

      if (competitor.otherResources) {
        if (competitor.otherResources.links) {
          this.competitor.otherResources.links = arrayClone(competitor.otherResources.links);
        }

        if (competitor.otherResources.files) {
          this.competitor.otherResources.files = arrayClone(competitor.otherResources.files);
        }
      }

      if (competitor.uniqueness && competitor.uniqueness.length) {
        this.competitor.uniqueness = competitor.uniqueness.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      }

      if (competitor.similarities && competitor.similarities.length) {
        this.competitor.similarities = competitor.similarities.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      }

      if (competitor.betterIn && competitor.betterIn.length) {
        this.competitor.betterIn = competitor.betterIn.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      }

      if (competitor.worseIn && competitor.worseIn.length) {
        this.competitor.worseIn = competitor.worseIn.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      }

      if (competitor.howWeWin && competitor.howWeWin.length) {
        this.competitor.howWeWin = competitor.howWeWin.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      }

      this.originalCompetitor = obj_clone(this.competitor);
    },
    handleUpdateCompetitor() {
      const competitor = {};
      competitor.id = this.competitor.id;
      competitor.name = this.competitor.name;
      competitor.keyInsights = this.competitor.keyInsights;
      competitor.note = this.competitor.note;
      competitor.webLinks = this.competitor.webLinks;
      competitor.otherRelevantLinks = this.competitor.otherRelevantLinks;

      if (this.competitor.uniqueness && this.competitor.uniqueness.length) {
        competitor.uniqueness = this.competitor.uniqueness.map((item) => item.name);
      }

      if (this.competitor.similarities && this.competitor.similarities.length) {
        competitor.similarities = this.competitor.similarities.map((item) => item.name);
      }

      if (this.competitor.betterIn && this.competitor.betterIn.length) {
        competitor.betterIn = this.competitor.betterIn.map((item) => item.name);
      }

      if (this.competitor.howWeWin && this.competitor.howWeWin.length) {
        competitor.howWeWin = this.competitor.howWeWin.map((item) => item.name);
      }

      if (this.competitor.worseIn && this.competitor.worseIn.length) {
        competitor.worseIn = this.competitor.worseIn.map((item) => item.name);
      }

      this.isUpdating = true;

      try {
        this.$apollo.mutate({
          mutation: COMPETITOR_UPDATE,
          variables: {
            competitor
          },
          update: (store, data) => {
            this.originalCompetitor = arrayClone(this.competitor);
          }
        }).then(() => {

          try {
            this.$apollo.mutate({
              mutation: COMPETITOR_PRODUCT_UPDATE,
              variables: {
                id: competitor.id,
                productIds: this.productIds
              },
              update: (store, data) => {
                this.originalCompetitor = arrayClone(this.competitor);
              }
            }).then(() => {
              this.$notify({
                title: this.$t('15_10_2_1_toaster_success_title_saved'),
                text: this.$t('15_10_2_1_toaster_success_description_saved'),
                type: 'success',
                duration: 5000,
              });

              this.isUpdating = false;
            });
          } catch(e) {
            this.isUpdating = false;

            this.$notify({
              title: this.$t('15_10_2_1_toaster_error_title_saved'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            });
          }

        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_10_2_1_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });
      }


    },
    async refetch() {
      const response = await this.$apollo.query({
        query: COMPANY_COMPETITOR_FILES,
        variables: {
          id: this.companyID,
        }
      });

      try {
        const company = response.data.companies.find((item) => item.id === this.companyID);
        const competitor = company.competitors.find((item) => item.id === this.$route.params.competitorId);

        if (competitor.otherResources) {
          if (competitor.otherResources.files) {
            this.competitor.otherResources.files = arrayClone(competitor.otherResources.files);
          }
        }
      } catch(e) {

      }
    },
    confirmLeave(to, from, next) {
      if (this.dataHasChanged) {
        this.showLeaveModal = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    },
    handleModalLeaveClose() {
      this.showLeaveModal = false;
    },
    handleModalLeaveConfirm() {
      this.originalCompetitor = obj_clone(this.competitor);

      this.showLeaveModal = false;
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.confirmLeave(to, from, next);
  }
}
</script>

<style scoped>
.product-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-detail-header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.product-detail-header-back {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.product-detail-header-title {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 800;
  line-height: 33.6px;
  text-align: left;
}

.product-detail-header-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.col-full {
  width: 100%;
}
</style>
