<template>
  <ContentWrapper>
    <NoProducts
      v-if="list.length === 0"
      @add="handleAdd"
    />

    <div v-if="list.length > 0" class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_7_1_3_title') }}</h1>

        <div class="submenu">
          <div class="submenu-count">
            <div class="submenu-count-label">{{ $t('15_7_1_3_label_items_count') }}:</div>
            {{ list.length }}
          </div>
          <div class="submenu-items">
            <Button size="nav" type="secondary" @click="showModalEditImportances = true">
              <IcoChartPie/>
              {{ $t('15_7_1_3_btn_edit_importances') }}
            </Button>
            <Button @click="handleAdd" size="nav" type="primary">
              <IcoPlus/>
              {{ $t('15_7_1_3_btn_add_product_service') }}
            </Button>
          </div>
        </div>

        <div class="items">
          <ProductCard
            v-for="(item, index) in list"
            :item="item"
            :key="item.id"
            @delete="deleteCard(item)"
          />
        </div>

      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <ProductsModalDelete
        :item="productToDelete"
        @close="showModalDelete = false"
        @delete="deleteProduct"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalEditImportances"
      @closeModal="showModalEditImportances = false"
    >
      <ModalEditImportances
        @close="showModalEditImportances = false"
        @update="updateImportances"
        v-model="list"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalAdd"
      @closeModal="showModalAdd = false"
    >
      <ProductsModalAdd
        @close="showModalAdd = false"
        @create="createProduct"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <StdcModalDelete
        :item="channelToDelete"
        @close="showModalDelete = false"
        @delete="deleteStdc"
      />
    </Modal>
  </ContentWrapper>
</template>

<script>
import NoPersonas from "@/components/sources/components/personas/NoPersonas.vue";
import PersonaCard from "@/components/sources/components/personas/PersonaCard.vue";
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import IcoChartPie from "@/components/svg/IcoChartPie.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import NoProducts from "@/components/sources/components/products/NoProducts.vue";
import ProductCard from "@/components/sources/components/products/ProductCard.vue";
import Modal from "@/components/Modal.vue";
import ModalEditImportances from "@/components/sources/components/ModalEditImportances.vue";
import ProductsModalAdd from "@/components/sources/components/products/ProductsModalAdd.vue";
import ProductsModalDelete from "@/components/sources/components/products/ProductsModalDelete.vue";

import COMPANY_PRODUCT from '@/graphql/CompanyProduct.gql';
import PRODUCT_CREATE from '@/graphql/ProductCreate.gql';
import StdcModalDelete from "@/components/sources/components/stdc/StdcModalDelete.vue";
import PRODUCT_UPDATE_RATIO from "@/graphql/ProductUpdateRatio.gql";

export default {
  components: {
    StdcModalDelete,
    ProductsModalDelete,
    ProductsModalAdd,
    ModalEditImportances,
    Modal,
    ProductCard,
    NoProducts,
    ContentWrapper,
    IcoChartPie,
    PersonaCard,
    NoPersonas,
    Button,
    IcoPlus
  },
  apollo: {
    companies: {
      query: COMPANY_PRODUCT,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];

        try {
          if (company && Array.isArray(company.products)) {
            this.list = company.products;
          }
        } catch(e) {
          this.list = [];
        }
      }
    },
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      productToDelete: null,
      showModalDelete: false,

      list: [],
      showList: false,
      showModalEditImportances: false,
      showModalAdd: false,
    }
  },
  methods: {
    handleAdd() {
      this.showList = true;
      this.showModalAdd = true;
    },
    createProduct(product) {
      this.showModalAdd = false;

      const _product = {};
      _product.companyId = this.companyID;
      if (product.averagePrice) {
        _product.averagePrice = product.averagePrice.value;
      }
      _product.keyInsights = product.keyInsights;
      _product.name = product.name;

      console.log("_product: ", _product);

      try {
        this.$apollo.mutate({
          mutation: PRODUCT_CREATE,
          variables: {
            product: _product
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();

            this.$notify({
              title: this.$t('15_7_1_3_toaster_success_title_create'),
              text: this.$t('15_7_1_3_toaster_success_description_create'),
              type: 'success',
              duration: 5000,
            })
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_7_1_3_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateImportances(importances) {
      try {
        this.$apollo.mutate({
          mutation: PRODUCT_UPDATE_RATIO,
          variables: {
            ratios: importances.map((item) => {
              return {
                ratio: item.ratio,
                id: item.id
              }
            })
          },
          update: (store, data) => {
            this.showModalEditImportances = false;
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    }
  }
}
</script>

<style scoped>
.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.submenu {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.submenu-count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  gap: 4px;
}

.submenu-count-label {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #AEB3B5;
}

.submenu-items {
  display: flex;
  gap: 16px;
}
</style>
