<template>
  <div
    class="dropdown-single-selected"
    v-if="item && item.name"
  >
    <div class="dropdown-single-selected-name">
      <template v-if="type === 'geography'">{{ getFlagEmoji(item.value) }}</template> <template v-if="type === 'normal' || type === 'geography'">{{ item.name }}</template>
      <template v-if="type === 'currency'">{{ item.value }}</template>
    </div>
    <button
      v-if="clear"
      class="dropdown-single-selected-remove"
      @click="handleRemoveSelected">
      <IcoCross />
    </button>
  </div>
</template>

<script>
import IcoCross from '@/components/svg/small/IcoCross.vue';
import {getFlagEmoji} from "../../utils/utils";

export default {
  name: 'DropdownSingleSelected',
  components: {
    IcoCross
  },
  props: {
    item: {
      type: Object,
      default: () => ({}) // Default to an empty object to avoid undefined errors
    },
    type: {
      type: String,
      default: 'normal'
    },
    clear: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    getFlagEmoji,
    handleRemoveSelected() {
      this.$emit('removeSelected');
    }
  }
}
</script>

<style scoped>
.dropdown-single-selected {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #17252A;

  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;

  padding: 7px 19px 7px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dropdown-single-selected-remove {
  padding: 0 4px;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
</style>
