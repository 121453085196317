<template>
  <ContentWrapper>
    <div class="wrapper">
      <div class="short-container">
        <h1>{{ company.name }} <IconStar /></h1>
        <div class="company-billing-date">
          <p>
            {{ $t('6_3_company_active') }}
            <strong>
              {{ $t('6_3_company_active_until') }} {{ company.billingDate | moment('D. M. YYYY') }}
            </strong>
            <span>|</span>
            <MailToSupport
              :title="$t('6_3_contact_us')"
              :subject="$t('6_3_company_billing_info')"
              :body="mailToBody"
            />
          </p>
        </div>

        <div class="boxed">
          <div class="box">

            <div class="column">
              <div class="col">
                <FormGroup
                  :label="$t('9_1_label_name')"
                  :required="true"
                  :validationError="validationError.name"
                >
                  <input
                    type="text"
                    :placeholder="$t('9_1_placeholder_name')"
                    v-model="company.name"
                    @input="validationError.name = false"
                    ref="contentName"
                  />
                </FormGroup>
              </div>
              <div class="col">
                <div v-if="role !== 'READ_ONLY'">
                  <CompanyLogo :title="$t('6_3_image_default_name')" />
                </div>
              </div>
            </div>

            <div class="column">
              <div class="col">
                <Dropdown
                  :label="$t('15_1_label_industry')"
                  :placeholder="$t('15_1_placeholder_industry')"
                  :items="IndustryType"
                  v-model="company.industry"
                />
              </div>
              <div class="col">
                <Dropdown
                  :label="$t('15_1_label_business_type')"
                  :placeholder="$t('15_1_placeholder_business_type')"
                  :items="BusinessType"
                  v-model="company.business"
                />
              </div>
            </div>

            <div class="column">
              <div class="col">
                <Dropdown
                  :label="$t('15_1_label_annual_revenue')"
                  :placeholder="$t('15_1_placeholder_annual_revenue')"
                  :items="revenue"
                  v-model="company.revenue"
                />
              </div>
              <div class="col">
                <Dropdown
                  :label="$t('15_1_label_number_of_employees')"
                  :placeholder="$t('15_1_placeholder_number_of_employees')"
                  :items="employees"
                  v-model="company.employees"
                />
              </div>
            </div>

            <div>
              <FormGroup
                :label="$t('15_1_label_main_company_website')"
                :required="false"
              >
                <input
                  type="text"
                  :placeholder="$t('15_1_main_company_website_add')"
                  v-model="company.website"
                  ref="companyWebsite"
                />
              </FormGroup>
            </div>

            <div>
              <FormGroup
                :label="$t('15_1_label_description')"
                :required="true"
                :validationError="validationError.description"
              >
              <textarea
                class="form-group__input large"
                :placeholder="$t('15_1_placeholder_description')"
                v-model="company.description"
                @input="validationError.description = false"
                ref="contentName"
              ></textarea>
              </FormGroup>
            </div>

            <div class="column">
              <Dropdown
                type="geography"
                :label="$t('15_1_label_company_geography')"
                :placeholder="$t('15_1_placeholder_company_geography')"
                :items="countries"
                :multiple="true"
                v-model="company.markets"
              />
            </div>

            <div class="column">
              <Dropdown
                :label="$t('15_1_label_timezone')"
                :placeholder="$t('15_1_placeholder_timezone')"
                :items="timeZones"
                v-model="company.zoneId"
              />
              <div class="short-column">
                <Dropdown
                  :label="$t('15_1_label_currency')"
                  :placeholder="$t('15_1_placeholder_currency')"
                  :items="currencies"
                  type="currency"
                  v-model="company.currency"
                />
              </div>
            </div>

            <Dropdown
              v-if="company.project && company.project.name"
              :label="$t('15_1_label_project_type')"
              :placeholder="$t('15_1_placeholder_project_type')"
              :items="ProjectType"
              v-model="company.project"
            />

            <Contacts v-if="company.contacts && company.contacts.length > 0" v-model="company.contacts"  />

            <FormGroup
              v-if="company.tools.length > 0"
              :label="$t('15_1_label_tools')"
              :required="false"
            >
              <Resources
                :items="company.tools"
                :disabled="role === 'READ_ONLY'"
                :showError="toolsIsOpenShowError"
                transCreateNew="15_1_tools_add"
                @updateResources="updateTools"
                @opened="toolsIsOpen = true"
                @closed="
                        ;(toolsIsOpen = false),
                          (toolsIsOpenShowError = false)
                      "
              />
            </FormGroup>

            <Accordion
              v-if="
                (company.project && !company.project.name)
                || company.contacts.length === 0
                || company.tools.length === 0
              "
              :title="$t('15_1_accordion_title_additional_info')"
              :description="$t('15_1_accordion_description_additional_info')"
            >
              <Dropdown
                v-if="company.project && !company.project.name"
                :label="$t('15_1_label_project_type')"
                :placeholder="$t('15_1_placeholder_project_type')"
                :items="ProjectType"
                v-model="company.project"
              />

              <Contacts v-if="company.contacts.length === 0" v-model="company.contacts"  />

              <FormGroup
                v-if="company.tools.length === 0"
                :label="$t('15_1_label_tools')"
                :required="false"
              >
                <Resources
                  :items="company.tools"
                  :disabled="role === 'READ_ONLY'"
                  :showError="toolsIsOpenShowError"
                  transCreateNew="15_1_tools_add"
                  @updateResources="updateTools"
                  @opened="toolsIsOpen = true"
                  @closed="
                        ;(toolsIsOpen = false),
                          (toolsIsOpenShowError = false)
                      "
                />
              </FormGroup>

            </Accordion>
          </div>
          <div class="box">
            <Button
              type="primary"
              @click="updateCompanyInfo"
            >
              {{ $t('15_3_2_btn_save_changes') }}
            </Button>
          </div>
        </div>
      </div>
    </div>

    <ModalConfirmClose
      v-if="showLeaveModal"
      @close="handleModalLeaveClose"
      @confirm="handleModalLeaveConfirm"
    />

  </ContentWrapper>
</template>

<script>
import FormGroup from "@/components/FormGroup.vue";
import Button from "@/components/button/Button.vue";
import IcoBin from "@/components/svg/medium/IcoBin.vue";
import IcoBinSmall from "@/components/svg/IcoBinSmall.vue";
import IconPhone from "@/components/sources/icons/IconPhone.vue";
import IconMail from "@/components/sources/icons/IconMail.vue";
import textLength from "@/constants/TextLengthConstant";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import Resources from "@/components/design/Resources.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import IconStar from "@/components/sources/icons/IconStar.vue";
import MailToSupport from "@/components/MailToSupport.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";

import { DateService } from "@/services/DateService";
import { obj_clone } from "@/services/helpers/clone";

import COMPANY_SOURCE_INFO from "@/graphql/CompanySourceInfo.gql";
import COMPANY_SOURCE_INFO_UPDATE from "@/graphql/CompanySourceInfoUpdate.gql";
import ModalConfirmClose from "../../ModalConfirmClose.vue";
import {arrayClone} from "../../../services/helpers/clone";
import Contacts from "../components/contacts/Contacts.vue";

export default {
  components: {
    Contacts,
    ModalConfirmClose,
    ContentWrapper,
    MailToSupport,
    IconStar,
    CompanyLogo,
    Accordion,
    Resources,
    Dropdown,
    IconMail,
    IconPhone,
    IcoBinSmall,
    IcoBin,
    Button,
    FormGroup

  },
  apollo: {
    companies: {
      query: COMPANY_SOURCE_INFO,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];

        try {
          if (company.billingDate) {
            this.company.billingDate = company.billingDate;
          }

          if (company.name) {
            this.company.name = company.name;
          }

          if (company.description) {
            this.company.description = company.description;
          }

          if (company.website) {
            this.company.website = company.website;
          }

          if (company.business) {
            this.company.business = this.BusinessType.find((item) => item.value = company.business);
          }

          if (company.industry) {
            this.company.industry = this.IndustryType.find((item) => item.value = company.industry);
          }

          if (company.currency) {
            this.company.currency = this.currencies.find((item) => item.value = company.currency);
          }

          if (company.zoneId) {
            this.company.zoneId = this.timeZones.find((item) => item.value = company.zoneId);
          }

          if (company.markets && company.markets.length > 0) {
            company.markets.forEach((marketId) => {
              this.company.markets.push(this.countries.find((item) => item.value === marketId));
            });
          }

          if (company.employees) {
            const selectedItemValue = `${company.employees.min ?? 0}_${company.employees.max ?? 0}`;
            this.company.employees = this.employees.find((item) => item.value === selectedItemValue);
          }

          if (company.revenue) {
            const selectedItemValue = `${company.revenue.min ?? 0 }_${company.revenue.max ?? 0}`;
            this.company.revenue = this.revenue.find((item) => item.value === selectedItemValue);
          }

          if (company.project) {
            this.positionProject = 'top';
            this.company.project = this.ProjectType.find((item) => item.value = company.project);
          } else {
            this.positionProject = 'bottom';
          }

          if (company.tools && company.tools.length > 0) {
            this.positionTools = 'top';
            this.company.tools = company.tools;
          } else {
            this.positionTools = 'bottom';
          }

          if (company.contacts && company.contacts.length > 0 ) {
            this.positionContact = 'top';
            this.company.contacts = company.contacts.map((item) => {
              return {
                name: item.name,
                description: item.description,
                phoneNumbers: item.phoneNumbers.join(","),
                emails: item.emails.join(","),
              }
            });
          } else {
            this.company.contacts = [];
            this.positionContact = 'bottom';
          }

          this.originalCompany = obj_clone(this.company);

        } catch(e) {}

      },
      skip() {
        return !this.companyID
      },
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    length() {
      return textLength
    },
    timezones() {
      return DateService.getListOfAvailableTimezones()
    },
    companyID() {
      return this.$route.params.id
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    mailToBody() {
      if(!this.selectedCompany) {
        return ""
      }

      let _content = ''
      const content_cs =
        'Společnost: {companyName}, {companyId} \nZašlete mi fakturu na předplatné: Sólo Dino / Triasový Tým / Jurská Smečka / Obsahový T-Rex \nMám zájem o období: ROČNÍ / MĚSÍČNÍ \n\nSlevový kód:\nFakturační údaje: \n- Název firmy \n- Sídlo (ulice, město, psč, země) \n- IČO \n- DIČ'
      const content_en = `Company: {companyName}, {companyId} \nSend me an invoice for subscription: Solo Dino / Triassic Team / Jurassic Crew / Content T-Rex \nI am interested in this period: YEARLY / MONTHLY \n\nPromo code: \nBilling info: \n- Company name \n- Address (street, city, postal code, country) \n- ID \n- TID (VAT no.)`

      _content = content_cs

      if (this.$i18n.locale === 'en') {
        _content = content_en
      }

      _content = _content
        .replace('{companyName}', this.selectedCompany.name)
        .replace('{companyId}', this.selectedCompany.id)

      return encodeURIComponent(_content)
    },
    hasDataChanged() {
      return JSON.stringify(this.company) !== JSON.stringify(this.originalCompany);
    }
  },
  data() {
    return {
      positionProject: 'bottom',
      positionTools: 'bottom',
      positionContact: 'bottom',

      BusinessType: [
        {
          value: 'B2B',
          name: this.$t('list_BusinessType_B2B')
        },
        {
          value: 'B2C',
          name: this.$t('list_BusinessType_B2C')
        },
        {
          value: 'B2B2C',
          name: this.$t('list_BusinessType_B2B2C')
        },
        {
          value: 'D2C',
          name: this.$t('list_BusinessType_D2C')
        },
        {
          value: 'C2C',
          name: this.$t('list_BusinessType_C2C')
        },
        {
          value: 'C2B',
          name: this.$t('list_BusinessType_C2B')
        },
        {
          value: 'B2G',
          name: this.$t('list_BusinessType_B2G')
        },
        {
          value: 'GOVERNMENT_INSTITUTION',
          name: this.$t('list_BusinessType_GOVERNMENT_INSTITUTION')
        },
        {
          value: 'NON_PROFIT_NGO',
          name: this.$t('list_BusinessType_NON_PROFIT_NGO')
        },
        {
          value: 'FRANCHISE',
          name: this.$t('list_BusinessType_FRANCHISE')
        },
        {
          value: 'E_COMMERCE',
          name: this.$t('list_BusinessType_E_COMMERCE')
        },
        {
          value: 'OTHER',
          name: this.$t('list_BusinessType_OTHER')
        }
      ],
      IndustryType: [
        {
          value: 'MARKETING_SERVICES',
          name: this.$t('list_IndustryType_MARKETING_SERVICES')
        },
        {
          value: 'TECHNOLOGY',
          name: this.$t('list_IndustryType_TECHNOLOGY')
        },
        {
          value: 'RETAIL',
          name: this.$t('list_IndustryType_RETAIL')
        },
        {
          value: 'MEDIA',
          name: this.$t('list_IndustryType_MEDIA')
        },
        {
          value: 'ENTERTAINMENT',
          name: this.$t('list_IndustryType_ENTERTAINMENT')
        },
        {
          value: 'HEALTHCARE',
          name: this.$t('list_IndustryType_HEALTHCARE')
        },
        {
          value: 'FINANCE',
          name: this.$t('list_IndustryType_FINANCE')
        },
        {
          value: 'HOSPITALITY',
          name: this.$t('list_IndustryType_HOSPITALITY')
        },
        {
          value: 'REAL_ESTATE',
          name: this.$t('list_IndustryType_REAL_ESTATE')
        },
        {
          value: 'BEAUTY',
          name: this.$t('list_IndustryType_BEAUTY')
        },
        {
          value: 'LOGISTICS',
          name: this.$t('list_IndustryType_LOGISTICS')
        },
        {
          value: 'CONSULTING',
          name: this.$t('list_IndustryType_CONSULTING')
        },
        {
          value: 'LEGAL_SERVICES',
          name: this.$t('list_IndustryType_LEGAL_SERVICES')
        },
        {
          value: 'TELECOMMUNICATIONS',
          name: this.$t('list_IndustryType_TELECOMMUNICATIONS')
        },
        {
          value: 'MANUFACTURING',
          name: this.$t('list_IndustryType_MANUFACTURING')
        },
        {
          value: 'FOOD_BEVERAGE',
          name: this.$t('list_IndustryType_FOOD_BEVERAGE')
        },
        {
          value: 'EDUCATION',
          name: this.$t('list_IndustryType_EDUCATION')
        },
        {
          value: 'EVENT_PLANNING',
          name: this.$t('list_IndustryType_EVENT_PLANNING')
        },
        {
          value: 'SOCIAL_SERVICES',
          name: this.$t('list_IndustryType_SOCIAL_SERVICES')
        },
        {
          value: 'UTILITIES',
          name: this.$t('list_IndustryType_UTILITIES')
        },
        {
          value: 'OTHER',
          name: this.$t('list_IndustryType_OTHER')
        },
      ],
      ProjectType: [
        {
          value: 'LEAD_GENERATION',
          name: this.$t('list_ProjectType_LEAD_GENERATION')
        },
        {
          value: 'BRAND_AWARENESS',
          name: this.$t('list_ProjectType_BRAND_AWARENESS')
        },
        {
          value: 'PRODUCT_SERVICE_LAUNCH',
          name: this.$t('list_ProjectType_PRODUCT_SERVICE_LAUNCH')
        },
        {
          value: 'CUSTOMER_ENGAGEMENT',
          name: this.$t('list_ProjectType_CUSTOMER_ENGAGEMENT')
        },
        {
          value: 'CONTENT_STRATEGY_PLANNING',
          name: this.$t('list_ProjectType_CONTENT_STRATEGY_PLANNING')
        },
        {
          value: 'SEO_TRAFFIC_GROWTH',
          name: this.$t('list_ProjectType_SEO_TRAFFIC_GROWTH')
        },
        {
          value: 'EVENT_PROMOTION',
          name: this.$t('list_ProjectType_EVENT_PROMOTION')
        },
        {
          value: 'E_COMMERCE_MARKETING',
          name: this.$t('list_ProjectType_E_COMMERCE_MARKETING')
        },
        {
          value: 'PUBLIC_RELATIONS',
          name: this.$t('list_ProjectType_PUBLIC_RELATIONS')
        },
        {
          value: 'INTERNAL_COMMUNICATION',
          name: this.$t('list_ProjectType_INTERNAL_COMMUNICATION')
        },
        {
          value: 'SALES_ENABLEMENT',
          name: this.$t('list_ProjectType_SALES_ENABLEMENT')
        },
        {
          value: 'RECRUITING',
          name: this.$t('list_ProjectType_RECRUITING')
        },
        {
          value: 'MARKET_EDUCATION',
          name: this.$t('list_ProjectType_MARKET_EDUCATION')
        },
      ],
      countries: [
        { name: 'Afghanistan', value: 'AF' },
        { name: 'Albania', value: 'AL' },
        { name: 'Algeria', value: 'DZ' },
        { name: 'Andorra', value: 'AD' },
        { name: 'Angola', value: 'AO' },
        { name: 'Antigua and Barbuda', value: 'AG' },
        { name: 'Argentina', value: 'AR' },
        { name: 'Armenia', value: 'AM' },
        { name: 'Australia', value: 'AU' },
        { name: 'Austria', value: 'AT' },
        { name: 'Azerbaijan', value: 'AZ' },
        { name: 'Bahamas', value: 'BS' },
        { name: 'Bahrain', value: 'BH' },
        { name: 'Bangladesh', value: 'BD' },
        { name: 'Barbados', value: 'BB' },
        { name: 'Belarus', value: 'BY' },
        { name: 'Belgium', value: 'BE' },
        { name: 'Belize', value: 'BZ' },
        { name: 'Benin', value: 'BJ' },
        { name: 'Bhutan', value: 'BT' },
        { name: 'Bolivia', value: 'BO' },
        { name: 'Bosnia and Herzegovina', value: 'BA' },
        { name: 'Botswana', value: 'BW' },
        { name: 'Brazil', value: 'BR' },
        { name: 'Brunei', value: 'BN' },
        { name: 'Bulgaria', value: 'BG' },
        { name: 'Burkina Faso', value: 'BF' },
        { name: 'Burundi', value: 'BI' },
        { name: 'Cabo Verde', value: 'CV' },
        { name: 'Cambodia', value: 'KH' },
        { name: 'Cameroon', value: 'CM' },
        { name: 'Canada', value: 'CA' },
        { name: 'Central African Republic', value: 'CF' },
        { name: 'Chad', value: 'TD' },
        { name: 'Chile', value: 'CL' },
        { name: 'China', value: 'CN' },
        { name: 'Colombia', value: 'CO' },
        { name: 'Comoros', value: 'KM' },
        { name: 'Congo (Congo-Brazzaville)', value: 'CG' },
        { name: 'Costa Rica', value: 'CR' },
        { name: 'Croatia', value: 'HR' },
        { name: 'Cuba', value: 'CU' },
        { name: 'Cyprus', value: 'CY' },
        { name: 'Czechia (Czech Republic)', value: 'CZ' },
        { name: 'Denmark', value: 'DK' },
        { name: 'Djibouti', value: 'DJ' },
        { name: 'Dominica', value: 'DM' },
        { name: 'Dominican Republic', value: 'DO' },
        { name: 'Ecuador', value: 'EC' },
        { name: 'Egypt', value: 'EG' },
        { name: 'El Salvador', value: 'SV' },
        { name: 'Equatorial Guinea', value: 'GQ' },
        { name: 'Eritrea', value: 'ER' },
        { name: 'Estonia', value: 'EE' },
        { name: 'Eswatini (fmr. "Swaziland")', value: 'SZ' },
        { name: 'Ethiopia', value: 'ET' },
        { name: 'Fiji', value: 'FJ' },
        { name: 'Finland', value: 'FI' },
        { name: 'France', value: 'FR' },
        { name: 'Gabon', value: 'GA' },
        { name: 'Gambia', value: 'GM' },
        { name: 'Georgia', value: 'GE' },
        { name: 'Germany', value: 'DE' },
        { name: 'Ghana', value: 'GH' },
        { name: 'Greece', value: 'GR' },
        { name: 'Grenada', value: 'GD' },
        { name: 'Guatemala', value: 'GT' },
        { name: 'Guinea', value: 'GN' },
        { name: 'Guinea-Bissau', value: 'GW' },
        { name: 'Guyana', value: 'GY' },
        { name: 'Haiti', value: 'HT' },
        { name: 'Honduras', value: 'HN' },
        { name: 'Hungary', value: 'HU' },
        { name: 'Iceland', value: 'IS' },
        { name: 'India', value: 'IN' },
        { name: 'Indonesia', value: 'ID' },
        { name: 'Iran', value: 'IR' },
        { name: 'Iraq', value: 'IQ' },
        { name: 'Ireland', value: 'IE' },
        { name: 'Israel', value: 'IL' },
        { name: 'Italy', value: 'IT' },
        { name: 'Jamaica', value: 'JM' },
        { name: 'Japan', value: 'JP' },
        { name: 'Jordan', value: 'JO' },
        { name: 'Kazakhstan', value: 'KZ' },
        { name: 'Kenya', value: 'KE' },
        { name: 'Kiribati', value: 'KI' },
        { name: 'Kuwait', value: 'KW' },
        { name: 'Kyrgyzstan', value: 'KG' },
        { name: 'Laos', value: 'LA' },
        { name: 'Latvia', value: 'LV' },
        { name: 'Lebanon', value: 'LB' },
        { name: 'Lesotho', value: 'LS' },
        { name: 'Liberia', value: 'LR' },
        { name: 'Libya', value: 'LY' },
        { name: 'Liechtenstein', value: 'LI' },
        { name: 'Lithuania', value: 'LT' },
        { name: 'Luxembourg', value: 'LU' },
        { name: 'Madagascar', value: 'MG' },
        { name: 'Malawi', value: 'MW' },
        { name: 'Malaysia', value: 'MY' },
        { name: 'Maldives', value: 'MV' },
        { name: 'Mali', value: 'ML' },
        { name: 'Malta', value: 'MT' },
        { name: 'Marshall Islands', value: 'MH' },
        { name: 'Mauritania', value: 'MR' },
        { name: 'Mauritius', value: 'MU' },
        { name: 'Mexico', value: 'MX' },
        { name: 'Micronesia', value: 'FM' },
        { name: 'Moldova', value: 'MD' },
        { name: 'Monaco', value: 'MC' },
        { name: 'Mongolia', value: 'MN' },
        { name: 'Montenegro', value: 'ME' },
        { name: 'Morocco', value: 'MA' },
        { name: 'Mozambique', value: 'MZ' },
        { name: 'Myanmar (formerly Burma)', value: 'MM' },
        { name: 'Namibia', value: 'NA' },
        { name: 'Nauru', value: 'NR' },
        { name: 'Nepal', value: 'NP' },
        { name: 'Netherlands', value: 'NL' },
        { name: 'New Zealand', value: 'NZ' },
        { name: 'Nicaragua', value: 'NI' },
        { name: 'Niger', value: 'NE' },
        { name: 'Nigeria', value: 'NG' },
        { name: 'North Korea', value: 'KP' },
        { name: 'North Macedonia', value: 'MK' },
        { name: 'Norway', value: 'NO' },
        { name: 'Oman', value: 'OM' },
        { name: 'Pakistan', value: 'PK' },
        { name: 'Palau', value: 'PW' },
        { name: 'Palestine State', value: 'PS' },
        { name: 'Panama', value: 'PA' },
        { name: 'Papua New Guinea', value: 'PG' },
        { name: 'Paraguay', value: 'PY' },
        { name: 'Peru', value: 'PE' },
        { name: 'Philippines', value: 'PH' },
        { name: 'Poland', value: 'PL' },
        { name: 'Portugal', value: 'PT' },
        { name: 'Qatar', value: 'QA' },
        { name: 'Romania', value: 'RO' },
        { name: 'Russia', value: 'RU' },
        { name: 'Rwanda', value: 'RW' },
        { name: 'Saint Kitts and Nevis', value: 'KN' },
        { name: 'Saint Lucia', value: 'LC' },
        { name: 'Saint Vincent and the Grenadines', value: 'VC' },
        { name: 'Samoa', value: 'WS' },
        { name: 'San Marino', value: 'SM' },
        { name: 'Sao Tome and Principe', value: 'ST' },
        { name: 'Saudi Arabia', value: 'SA' },
        { name: 'Senegal', value: 'SN' },
        { name: 'Serbia', value: 'RS' },
        { name: 'Seychelles', value: 'SC' },
        { name: 'Sierra Leone', value: 'SL' },
        { name: 'Singapore', value: 'SG' },
        { name: 'Slovakia', value: 'SK' },
        { name: 'Slovenia', value: 'SI' },
        { name: 'Solomon Islands', value: 'SB' },
        { name: 'Somalia', value: 'SO' },
        { name: 'South Africa', value: 'ZA' },
        { name: 'South Korea', value: 'KR' },
        { name: 'South Sudan', value: 'SS' },
        { name: 'Spain', value: 'ES' },
        { name: 'Sri Lanka', value: 'LK' },
        { name: 'Sudan', value: 'SD' },
        { name: 'Suriname', value: 'SR' },
        { name: 'Sweden', value: 'SE' },
        { name: 'Switzerland', value: 'CH' },
        { name: 'Syria', value: 'SY' },
        { name: 'Taiwan', value: 'TW' },
        { name: 'Tajikistan', value: 'TJ' },
        { name: 'Tanzania', value: 'TZ' },
        { name: 'Thailand', value: 'TH' },
        { name: 'Timor-Leste', value: 'TL' },
        { name: 'Togo', value: 'TG' },
        { name: 'Tonga', value: 'TO' },
        { name: 'Trinidad and Tobago', value: 'TT' },
        { name: 'Tunisia', value: 'TN' },
        { name: 'Turkey', value: 'TR' },
        { name: 'Turkmenistan', value: 'TM' },
        { name: 'Tuvalu', value: 'TV' },
        { name: 'Uganda', value: 'UG' },
        { name: 'Ukraine', value: 'UA' },
        { name: 'United Arab Emirates', value: 'AE' },
        { name: 'United Kingdom', value: 'GB' },
        { name: 'United States of America', value: 'US' },
        { name: 'Uruguay', value: 'UY' },
        { name: 'Uzbekistan', value: 'UZ' },
        { name: 'Vanuatu', value: 'VU' },
        { name: 'Venezuela', value: 'VE' },
        { name: 'Vietnam', value: 'VN' },
        { name: 'Yemen', value: 'YE' },
        { name: 'Zambia', value: 'ZM' },
        { name: 'Zimbabwe', value: 'ZW' }
      ],
      currencies: [
        { name: "US Dollar", value: "USD" },
        { name: "Euro", value: "EUR" },
        { name: "British Pound", value: "GBP" },
        { name: "Canadian Dollar", value: "CAD" },
        { name: "Australian Dollar", value: "AUD" },
        { name: "Swiss Franc", value: "CHF" },
        { name: "Japanese Yen", value: "JPY" },
        { name: "Chinese Yuan", value: "CNY" },
        { name: "Indian Rupee", value: "INR" },
        { name: "Russian Ruble", value: "RUB" },
        { name: "Brazilian Real", value: "BRL" },
        { name: "Mexican Peso", value: "MXN" },
        { name: "South Korean Won", value: "KRW" },
        { name: "Singapore Dollar", value: "SGD" },
        { name: "Hong Kong Dollar", value: "HKD" },
        { name: "New Zealand Dollar", value: "NZD" },
        { name: "Swedish Krona", value: "SEK" },
        { name: "Norwegian Krone", value: "NOK" },
        { name: "Danish Krone", value: "DKK" },
        { name: "Polish Zloty", value: "PLN" },
        { name: "South African Rand", value: "ZAR" },
        { name: "Turkish Lira", value: "TRY" },
        { name: "Thai Baht", value: "THB" },
        { name: "Indonesian Rupiah", value: "IDR" },
        { name: "Philippine Peso", value: "PHP" },
        { name: "Czech Koruna", value: "CZK" },
        { name: "Hungarian Forint", value: "HUF" },
        { name: "Israeli Shekel", value: "ILS" },
        { name: "Malaysian Ringgit", value: "MYR" },
        { name: "Saudi Riyal", value: "SAR" },
        { name: "United Arab Emirates Dirham", value: "AED" }
      ],
      timeZones: [
        {
          "name": "Europe/Andorra",
          "value": "Europe/Andorra"
        },
        {
          "name": "Asia/Dubai",
          "value": "Asia/Dubai"
        },
        {
          "name": "Asia/Kabul",
          "value": "Asia/Kabul"
        },
        {
          "name": "Europe/Tirane",
          "value": "Europe/Tirane"
        },
        {
          "name": "Asia/Yerevan",
          "value": "Asia/Yerevan"
        },
        {
          "name": "Antarctica/Casey",
          "value": "Antarctica/Casey"
        },
        {
          "name": "Antarctica/Davis",
          "value": "Antarctica/Davis"
        },
        {
          "name": "Antarctica/DumontDUrville",
          "value": "Antarctica/DumontDUrville"
        },
        {
          "name": "Antarctica/Mawson",
          "value": "Antarctica/Mawson"
        },
        {
          "name": "Antarctica/Palmer",
          "value": "Antarctica/Palmer"
        },
        {
          "name": "Antarctica/Rothera",
          "value": "Antarctica/Rothera"
        },
        {
          "name": "Antarctica/Syowa",
          "value": "Antarctica/Syowa"
        },
        {
          "name": "Antarctica/Troll",
          "value": "Antarctica/Troll"
        },
        {
          "name": "Antarctica/Vostok",
          "value": "Antarctica/Vostok"
        },
        {
          "name": "America/Argentina/Buenos_Aires",
          "value": "America/Argentina/Buenos_Aires"
        },
        {
          "name": "America/Argentina/Cordoba",
          "value": "America/Argentina/Cordoba"
        },
        {
          "name": "America/Argentina/Salta",
          "value": "America/Argentina/Salta"
        },
        {
          "name": "America/Argentina/Jujuy",
          "value": "America/Argentina/Jujuy"
        },
        {
          "name": "America/Argentina/Tucuman",
          "value": "America/Argentina/Tucuman"
        },
        {
          "name": "America/Argentina/Catamarca",
          "value": "America/Argentina/Catamarca"
        },
        {
          "name": "America/Argentina/La_Rioja",
          "value": "America/Argentina/La_Rioja"
        },
        {
          "name": "America/Argentina/San_Juan",
          "value": "America/Argentina/San_Juan"
        },
        {
          "name": "America/Argentina/Mendoza",
          "value": "America/Argentina/Mendoza"
        },
        {
          "name": "America/Argentina/San_Luis",
          "value": "America/Argentina/San_Luis"
        },
        {
          "name": "America/Argentina/Rio_Gallegos",
          "value": "America/Argentina/Rio_Gallegos"
        },
        {
          "name": "America/Argentina/Ushuaia",
          "value": "America/Argentina/Ushuaia"
        },
        {
          "name": "Pacific/Pago_Pago",
          "value": "Pacific/Pago_Pago"
        },
        {
          "name": "Europe/Vienna",
          "value": "Europe/Vienna"
        },
        {
          "name": "Australia/Lord_Howe",
          "value": "Australia/Lord_Howe"
        },
        {
          "name": "Antarctica/Macquarie",
          "value": "Antarctica/Macquarie"
        },
        {
          "name": "Australia/Hobart",
          "value": "Australia/Hobart"
        },
        {
          "name": "Australia/Currie",
          "value": "Australia/Currie"
        },
        {
          "name": "Australia/Melbourne",
          "value": "Australia/Melbourne"
        },
        {
          "name": "Australia/Sydney",
          "value": "Australia/Sydney"
        },
        {
          "name": "Australia/Broken_Hill",
          "value": "Australia/Broken_Hill"
        },
        {
          "name": "Australia/Brisbane",
          "value": "Australia/Brisbane"
        },
        {
          "name": "Australia/Lindeman",
          "value": "Australia/Lindeman"
        },
        {
          "name": "Australia/Adelaide",
          "value": "Australia/Adelaide"
        },
        {
          "name": "Australia/Darwin",
          "value": "Australia/Darwin"
        },
        {
          "name": "Australia/Perth",
          "value": "Australia/Perth"
        },
        {
          "name": "Australia/Eucla",
          "value": "Australia/Eucla"
        },
        {
          "name": "Asia/Baku",
          "value": "Asia/Baku"
        },
        {
          "name": "America/Barbados",
          "value": "America/Barbados"
        },
        {
          "name": "Asia/Dhaka",
          "value": "Asia/Dhaka"
        },
        {
          "name": "Europe/Brussels",
          "value": "Europe/Brussels"
        },
        {
          "name": "Europe/Sofia",
          "value": "Europe/Sofia"
        },
        {
          "name": "Atlantic/Bermuda",
          "value": "Atlantic/Bermuda"
        },
        {
          "name": "Asia/Brunei",
          "value": "Asia/Brunei"
        },
        {
          "name": "America/La_Paz",
          "value": "America/La_Paz"
        },
        {
          "name": "America/Noronha",
          "value": "America/Noronha"
        },
        {
          "name": "America/Belem",
          "value": "America/Belem"
        },
        {
          "name": "America/Fortaleza",
          "value": "America/Fortaleza"
        },
        {
          "name": "America/Recife",
          "value": "America/Recife"
        },
        {
          "name": "America/Araguaina",
          "value": "America/Araguaina"
        },
        {
          "name": "America/Maceio",
          "value": "America/Maceio"
        },
        {
          "name": "America/Bahia",
          "value": "America/Bahia"
        },
        {
          "name": "America/Sao_Paulo",
          "value": "America/Sao_Paulo"
        },
        {
          "name": "America/Campo_Grande",
          "value": "America/Campo_Grande"
        },
        {
          "name": "America/Cuiaba",
          "value": "America/Cuiaba"
        },
        {
          "name": "America/Santarem",
          "value": "America/Santarem"
        },
        {
          "name": "America/Porto_Velho",
          "value": "America/Porto_Velho"
        },
        {
          "name": "America/Boa_Vista",
          "value": "America/Boa_Vista"
        },
        {
          "name": "America/Manaus",
          "value": "America/Manaus"
        },
        {
          "name": "America/Eirunepe",
          "value": "America/Eirunepe"
        },
        {
          "name": "America/Rio_Branco",
          "value": "America/Rio_Branco"
        },
        {
          "name": "America/Nassau",
          "value": "America/Nassau"
        },
        {
          "name": "Asia/Thimphu",
          "value": "Asia/Thimphu"
        },
        {
          "name": "Europe/Minsk",
          "value": "Europe/Minsk"
        },
        {
          "name": "America/Belize",
          "value": "America/Belize"
        },
        {
          "name": "America/St_Johns",
          "value": "America/St_Johns"
        },
        {
          "name": "America/Halifax",
          "value": "America/Halifax"
        },
        {
          "name": "America/Glace_Bay",
          "value": "America/Glace_Bay"
        },
        {
          "name": "America/Moncton",
          "value": "America/Moncton"
        },
        {
          "name": "America/Goose_Bay",
          "value": "America/Goose_Bay"
        },
        {
          "name": "America/Blanc-Sablon",
          "value": "America/Blanc-Sablon"
        },
        {
          "name": "America/Toronto",
          "value": "America/Toronto"
        },
        {
          "name": "America/Nipigon",
          "value": "America/Nipigon"
        },
        {
          "name": "America/Thunder_Bay",
          "value": "America/Thunder_Bay"
        },
        {
          "name": "America/Iqaluit",
          "value": "America/Iqaluit"
        },
        {
          "name": "America/Pangnirtung",
          "value": "America/Pangnirtung"
        },
        {
          "name": "America/Atikokan",
          "value": "America/Atikokan"
        },
        {
          "name": "America/Winnipeg",
          "value": "America/Winnipeg"
        },
        {
          "name": "America/Rainy_River",
          "value": "America/Rainy_River"
        },
        {
          "name": "America/Resolute",
          "value": "America/Resolute"
        },
        {
          "name": "America/Rankin_Inlet",
          "value": "America/Rankin_Inlet"
        },
        {
          "name": "America/Regina",
          "value": "America/Regina"
        },
        {
          "name": "America/Swift_Current",
          "value": "America/Swift_Current"
        },
        {
          "name": "America/Edmonton",
          "value": "America/Edmonton"
        },
        {
          "name": "America/Cambridge_Bay",
          "value": "America/Cambridge_Bay"
        },
        {
          "name": "America/Yellowknife",
          "value": "America/Yellowknife"
        },
        {
          "name": "America/Inuvik",
          "value": "America/Inuvik"
        },
        {
          "name": "America/Creston",
          "value": "America/Creston"
        },
        {
          "name": "America/Dawson_Creek",
          "value": "America/Dawson_Creek"
        },
        {
          "name": "America/Fort_Nelson",
          "value": "America/Fort_Nelson"
        },
        {
          "name": "America/Vancouver",
          "value": "America/Vancouver"
        },
        {
          "name": "America/Whitehorse",
          "value": "America/Whitehorse"
        },
        {
          "name": "America/Dawson",
          "value": "America/Dawson"
        },
        {
          "name": "Indian/Cocos",
          "value": "Indian/Cocos"
        },
        {
          "name": "Europe/Zurich",
          "value": "Europe/Zurich"
        },
        {
          "name": "Africa/Abidjan",
          "value": "Africa/Abidjan"
        },
        {
          "name": "Pacific/Rarotonga",
          "value": "Pacific/Rarotonga"
        },
        {
          "name": "America/Santiago",
          "value": "America/Santiago"
        },
        {
          "name": "America/Punta_Arenas",
          "value": "America/Punta_Arenas"
        },
        {
          "name": "Pacific/Easter",
          "value": "Pacific/Easter"
        },
        {
          "name": "Asia/Shanghai",
          "value": "Asia/Shanghai"
        },
        {
          "name": "Asia/Urumqi",
          "value": "Asia/Urumqi"
        },
        {
          "name": "America/Bogota",
          "value": "America/Bogota"
        },
        {
          "name": "America/Costa_Rica",
          "value": "America/Costa_Rica"
        },
        {
          "name": "America/Havana",
          "value": "America/Havana"
        },
        {
          "name": "Atlantic/Cape_Verde",
          "value": "Atlantic/Cape_Verde"
        },
        {
          "name": "America/Curacao",
          "value": "America/Curacao"
        },
        {
          "name": "Indian/Christmas",
          "value": "Indian/Christmas"
        },
        {
          "name": "Asia/Nicosia",
          "value": "Asia/Nicosia"
        },
        {
          "name": "Asia/Famagusta",
          "value": "Asia/Famagusta"
        },
        {
          "name": "Europe/Prague",
          "value": "Europe/Prague"
        },
        {
          "name": "Europe/Berlin",
          "value": "Europe/Berlin"
        },
        {
          "name": "Europe/Copenhagen",
          "value": "Europe/Copenhagen"
        },
        {
          "name": "America/Santo_Domingo",
          "value": "America/Santo_Domingo"
        },
        {
          "name": "Africa/Algiers",
          "value": "Africa/Algiers"
        },
        {
          "name": "America/Guayaquil",
          "value": "America/Guayaquil"
        },
        {
          "name": "Pacific/Galapagos",
          "value": "Pacific/Galapagos"
        },
        {
          "name": "Europe/Tallinn",
          "value": "Europe/Tallinn"
        },
        {
          "name": "Africa/Cairo",
          "value": "Africa/Cairo"
        },
        {
          "name": "Africa/El_Aaiun",
          "value": "Africa/El_Aaiun"
        },
        {
          "name": "Europe/Madrid",
          "value": "Europe/Madrid"
        },
        {
          "name": "Africa/Ceuta",
          "value": "Africa/Ceuta"
        },
        {
          "name": "Atlantic/Canary",
          "value": "Atlantic/Canary"
        },
        {
          "name": "Europe/Helsinki",
          "value": "Europe/Helsinki"
        },
        {
          "name": "Pacific/Fiji",
          "value": "Pacific/Fiji"
        },
        {
          "name": "Atlantic/Stanley",
          "value": "Atlantic/Stanley"
        },
        {
          "name": "Pacific/Chuuk",
          "value": "Pacific/Chuuk"
        },
        {
          "name": "Pacific/Pohnpei",
          "value": "Pacific/Pohnpei"
        },
        {
          "name": "Pacific/Kosrae",
          "value": "Pacific/Kosrae"
        },
        {
          "name": "Atlantic/Faroe",
          "value": "Atlantic/Faroe"
        },
        {
          "name": "Europe/Paris",
          "value": "Europe/Paris"
        },
        {
          "name": "Europe/London",
          "value": "Europe/London"
        },
        {
          "name": "Asia/Tbilisi",
          "value": "Asia/Tbilisi"
        },
        {
          "name": "America/Cayenne",
          "value": "America/Cayenne"
        },
        {
          "name": "Africa/Accra",
          "value": "Africa/Accra"
        },
        {
          "name": "Europe/Gibraltar",
          "value": "Europe/Gibraltar"
        },
        {
          "name": "America/Godthab",
          "value": "America/Godthab"
        },
        {
          "name": "America/Danmarkshavn",
          "value": "America/Danmarkshavn"
        },
        {
          "name": "America/Scoresbysund",
          "value": "America/Scoresbysund"
        },
        {
          "name": "America/Thule",
          "value": "America/Thule"
        },
        {
          "name": "Europe/Athens",
          "value": "Europe/Athens"
        },
        {
          "name": "Atlantic/South_Georgia",
          "value": "Atlantic/South_Georgia"
        },
        {
          "name": "America/Guatemala",
          "value": "America/Guatemala"
        },
        {
          "name": "Pacific/Guam",
          "value": "Pacific/Guam"
        },
        {
          "name": "Africa/Bissau",
          "value": "Africa/Bissau"
        },
        {
          "name": "America/Guyana",
          "value": "America/Guyana"
        },
        {
          "name": "Asia/Hong_Kong",
          "value": "Asia/Hong_Kong"
        },
        {
          "name": "America/Tegucigalpa",
          "value": "America/Tegucigalpa"
        },
        {
          "name": "America/Port-au-Prince",
          "value": "America/Port-au-Prince"
        },
        {
          "name": "Europe/Budapest",
          "value": "Europe/Budapest"
        },
        {
          "name": "Asia/Jakarta",
          "value": "Asia/Jakarta"
        },
        {
          "name": "Asia/Pontianak",
          "value": "Asia/Pontianak"
        },
        {
          "name": "Asia/Makassar",
          "value": "Asia/Makassar"
        },
        {
          "name": "Asia/Jayapura",
          "value": "Asia/Jayapura"
        },
        {
          "name": "Europe/Dublin",
          "value": "Europe/Dublin"
        },
        {
          "name": "Asia/Jerusalem",
          "value": "Asia/Jerusalem"
        },
        {
          "name": "Asia/Kolkata",
          "value": "Asia/Kolkata"
        },
        {
          "name": "Indian/Chagos",
          "value": "Indian/Chagos"
        },
        {
          "name": "Asia/Baghdad",
          "value": "Asia/Baghdad"
        },
        {
          "name": "Asia/Tehran",
          "value": "Asia/Tehran"
        },
        {
          "name": "Atlantic/Reykjavik",
          "value": "Atlantic/Reykjavik"
        },
        {
          "name": "Europe/Rome",
          "value": "Europe/Rome"
        },
        {
          "name": "America/Jamaica",
          "value": "America/Jamaica"
        },
        {
          "name": "Asia/Amman",
          "value": "Asia/Amman"
        },
        {
          "name": "Asia/Tokyo",
          "value": "Asia/Tokyo"
        },
        {
          "name": "Africa/Nairobi",
          "value": "Africa/Nairobi"
        },
        {
          "name": "Asia/Bishkek",
          "value": "Asia/Bishkek"
        },
        {
          "name": "Pacific/Tarawa",
          "value": "Pacific/Tarawa"
        },
        {
          "name": "Pacific/Enderbury",
          "value": "Pacific/Enderbury"
        },
        {
          "name": "Pacific/Kiritimati",
          "value": "Pacific/Kiritimati"
        },
        {
          "name": "Asia/Pyongyang",
          "value": "Asia/Pyongyang"
        },
        {
          "name": "Asia/Seoul",
          "value": "Asia/Seoul"
        },
        {
          "name": "Asia/Almaty",
          "value": "Asia/Almaty"
        },
        {
          "name": "Asia/Qyzylorda",
          "value": "Asia/Qyzylorda"
        },
        {
          "name": "Asia/Qostanay",
          "value": "Asia/Qostanay"
        },
        {
          "name": "Asia/Aqtobe",
          "value": "Asia/Aqtobe"
        },
        {
          "name": "Asia/Aqtau",
          "value": "Asia/Aqtau"
        },
        {
          "name": "Asia/Atyrau",
          "value": "Asia/Atyrau"
        },
        {
          "name": "Asia/Oral",
          "value": "Asia/Oral"
        },
        {
          "name": "Asia/Beirut",
          "value": "Asia/Beirut"
        },
        {
          "name": "Asia/Colombo",
          "value": "Asia/Colombo"
        },
        {
          "name": "Africa/Monrovia",
          "value": "Africa/Monrovia"
        },
        {
          "name": "Europe/Vilnius",
          "value": "Europe/Vilnius"
        },
        {
          "name": "Europe/Luxembourg",
          "value": "Europe/Luxembourg"
        },
        {
          "name": "Europe/Riga",
          "value": "Europe/Riga"
        },
        {
          "name": "Africa/Tripoli",
          "value": "Africa/Tripoli"
        },
        {
          "name": "Africa/Casablanca",
          "value": "Africa/Casablanca"
        },
        {
          "name": "Europe/Monaco",
          "value": "Europe/Monaco"
        },
        {
          "name": "Europe/Chisinau",
          "value": "Europe/Chisinau"
        },
        {
          "name": "Pacific/Majuro",
          "value": "Pacific/Majuro"
        },
        {
          "name": "Pacific/Kwajalein",
          "value": "Pacific/Kwajalein"
        },
        {
          "name": "Asia/Yangon",
          "value": "Asia/Yangon"
        },
        {
          "name": "Asia/Ulaanbaatar",
          "value": "Asia/Ulaanbaatar"
        },
        {
          "name": "Asia/Hovd",
          "value": "Asia/Hovd"
        },
        {
          "name": "Asia/Choibalsan",
          "value": "Asia/Choibalsan"
        },
        {
          "name": "Asia/Macau",
          "value": "Asia/Macau"
        },
        {
          "name": "America/Martinique",
          "value": "America/Martinique"
        },
        {
          "name": "Europe/Malta",
          "value": "Europe/Malta"
        },
        {
          "name": "Indian/Mauritius",
          "value": "Indian/Mauritius"
        },
        {
          "name": "Indian/Maldives",
          "value": "Indian/Maldives"
        },
        {
          "name": "America/Mexico_City",
          "value": "America/Mexico_City"
        },
        {
          "name": "America/Cancun",
          "value": "America/Cancun"
        },
        {
          "name": "America/Merida",
          "value": "America/Merida"
        },
        {
          "name": "America/Monterrey",
          "value": "America/Monterrey"
        },
        {
          "name": "America/Matamoros",
          "value": "America/Matamoros"
        },
        {
          "name": "America/Mazatlan",
          "value": "America/Mazatlan"
        },
        {
          "name": "America/Chihuahua",
          "value": "America/Chihuahua"
        },
        {
          "name": "America/Ojinaga",
          "value": "America/Ojinaga"
        },
        {
          "name": "America/Hermosillo",
          "value": "America/Hermosillo"
        },
        {
          "name": "America/Tijuana",
          "value": "America/Tijuana"
        },
        {
          "name": "America/Bahia_Banderas",
          "value": "America/Bahia_Banderas"
        },
        {
          "name": "Asia/Kuala_Lumpur",
          "value": "Asia/Kuala_Lumpur"
        },
        {
          "name": "Asia/Kuching",
          "value": "Asia/Kuching"
        },
        {
          "name": "Africa/Maputo",
          "value": "Africa/Maputo"
        },
        {
          "name": "Africa/Windhoek",
          "value": "Africa/Windhoek"
        },
        {
          "name": "Pacific/Noumea",
          "value": "Pacific/Noumea"
        },
        {
          "name": "Pacific/Norfolk",
          "value": "Pacific/Norfolk"
        },
        {
          "name": "Africa/Lagos",
          "value": "Africa/Lagos"
        },
        {
          "name": "America/Managua",
          "value": "America/Managua"
        },
        {
          "name": "Europe/Amsterdam",
          "value": "Europe/Amsterdam"
        },
        {
          "name": "Europe/Oslo",
          "value": "Europe/Oslo"
        },
        {
          "name": "Asia/Kathmandu",
          "value": "Asia/Kathmandu"
        },
        {
          "name": "Pacific/Nauru",
          "value": "Pacific/Nauru"
        },
        {
          "name": "Pacific/Niue",
          "value": "Pacific/Niue"
        },
        {
          "name": "Pacific/Auckland",
          "value": "Pacific/Auckland"
        },
        {
          "name": "Pacific/Chatham",
          "value": "Pacific/Chatham"
        },
        {
          "name": "America/Panama",
          "value": "America/Panama"
        },
        {
          "name": "America/Lima",
          "value": "America/Lima"
        },
        {
          "name": "Pacific/Tahiti",
          "value": "Pacific/Tahiti"
        },
        {
          "name": "Pacific/Marquesas",
          "value": "Pacific/Marquesas"
        },
        {
          "name": "Pacific/Gambier",
          "value": "Pacific/Gambier"
        },
        {
          "name": "Pacific/Port_Moresby",
          "value": "Pacific/Port_Moresby"
        },
        {
          "name": "Pacific/Bougainville",
          "value": "Pacific/Bougainville"
        },
        {
          "name": "Asia/Manila",
          "value": "Asia/Manila"
        },
        {
          "name": "Asia/Karachi",
          "value": "Asia/Karachi"
        },
        {
          "name": "Europe/Warsaw",
          "value": "Europe/Warsaw"
        },
        {
          "name": "America/Miquelon",
          "value": "America/Miquelon"
        },
        {
          "name": "Pacific/Pitcairn",
          "value": "Pacific/Pitcairn"
        },
        {
          "name": "America/Puerto_Rico",
          "value": "America/Puerto_Rico"
        },
        {
          "name": "Asia/Gaza",
          "value": "Asia/Gaza"
        },
        {
          "name": "Asia/Hebron",
          "value": "Asia/Hebron"
        },
        {
          "name": "Europe/Lisbon",
          "value": "Europe/Lisbon"
        },
        {
          "name": "Atlantic/Madeira",
          "value": "Atlantic/Madeira"
        },
        {
          "name": "Atlantic/Azores",
          "value": "Atlantic/Azores"
        },
        {
          "name": "Pacific/Palau",
          "value": "Pacific/Palau"
        },
        {
          "name": "America/Asuncion",
          "value": "America/Asuncion"
        },
        {
          "name": "Asia/Qatar",
          "value": "Asia/Qatar"
        },
        {
          "name": "Indian/Reunion",
          "value": "Indian/Reunion"
        },
        {
          "name": "Europe/Bucharest",
          "value": "Europe/Bucharest"
        },
        {
          "name": "Europe/Belgrade",
          "value": "Europe/Belgrade"
        },
        {
          "name": "Europe/Kaliningrad",
          "value": "Europe/Kaliningrad"
        },
        {
          "name": "Europe/Moscow",
          "value": "Europe/Moscow"
        },
        {
          "name": "Europe/Simferopol",
          "value": "Europe/Simferopol"
        },
        {
          "name": "Europe/Kirov",
          "value": "Europe/Kirov"
        },
        {
          "name": "Europe/Astrakhan",
          "value": "Europe/Astrakhan"
        },
        {
          "name": "Europe/Volgograd",
          "value": "Europe/Volgograd"
        },
        {
          "name": "Europe/Saratov",
          "value": "Europe/Saratov"
        },
        {
          "name": "Europe/Ulyanovsk",
          "value": "Europe/Ulyanovsk"
        },
        {
          "name": "Europe/Samara",
          "value": "Europe/Samara"
        },
        {
          "name": "Asia/Yekaterinburg",
          "value": "Asia/Yekaterinburg"
        },
        {
          "name": "Asia/Omsk",
          "value": "Asia/Omsk"
        },
        {
          "name": "Asia/Novosibirsk",
          "value": "Asia/Novosibirsk"
        },
        {
          "name": "Asia/Barnaul",
          "value": "Asia/Barnaul"
        },
        {
          "name": "Asia/Tomsk",
          "value": "Asia/Tomsk"
        },
        {
          "name": "Asia/Novokuznetsk",
          "value": "Asia/Novokuznetsk"
        },
        {
          "name": "Asia/Krasnoyarsk",
          "value": "Asia/Krasnoyarsk"
        },
        {
          "name": "Asia/Irkutsk",
          "value": "Asia/Irkutsk"
        },
        {
          "name": "Asia/Chita",
          "value": "Asia/Chita"
        },
        {
          "name": "Asia/Yakutsk",
          "value": "Asia/Yakutsk"
        },
        {
          "name": "Asia/Khandyga",
          "value": "Asia/Khandyga"
        },
        {
          "name": "Asia/Vladivostok",
          "value": "Asia/Vladivostok"
        },
        {
          "name": "Asia/Ust-Nera",
          "value": "Asia/Ust-Nera"
        },
        {
          "name": "Asia/Magadan",
          "value": "Asia/Magadan"
        },
        {
          "name": "Asia/Sakhalin",
          "value": "Asia/Sakhalin"
        },
        {
          "name": "Asia/Srednekolymsk",
          "value": "Asia/Srednekolymsk"
        },
        {
          "name": "Asia/Kamchatka",
          "value": "Asia/Kamchatka"
        },
        {
          "name": "Asia/Anadyr",
          "value": "Asia/Anadyr"
        },
        {
          "name": "Asia/Riyadh",
          "value": "Asia/Riyadh"
        },
        {
          "name": "Pacific/Guadalcanal",
          "value": "Pacific/Guadalcanal"
        },
        {
          "name": "Indian/Mahe",
          "value": "Indian/Mahe"
        },
        {
          "name": "Africa/Khartoum",
          "value": "Africa/Khartoum"
        },
        {
          "name": "Europe/Stockholm",
          "value": "Europe/Stockholm"
        },
        {
          "name": "Asia/Singapore",
          "value": "Asia/Singapore"
        },
        {
          "name": "America/Paramaribo",
          "value": "America/Paramaribo"
        },
        {
          "name": "Africa/Juba",
          "value": "Africa/Juba"
        },
        {
          "name": "Africa/Sao_Tome",
          "value": "Africa/Sao_Tome"
        },
        {
          "name": "America/El_Salvador",
          "value": "America/El_Salvador"
        },
        {
          "name": "Asia/Damascus",
          "value": "Asia/Damascus"
        },
        {
          "name": "America/Grand_Turk",
          "value": "America/Grand_Turk"
        },
        {
          "name": "Africa/Ndjamena",
          "value": "Africa/Ndjamena"
        },
        {
          "name": "Indian/Kerguelen",
          "value": "Indian/Kerguelen"
        },
        {
          "name": "Asia/Bangkok",
          "value": "Asia/Bangkok"
        },
        {
          "name": "Asia/Dushanbe",
          "value": "Asia/Dushanbe"
        },
        {
          "name": "Pacific/Fakaofo",
          "value": "Pacific/Fakaofo"
        },
        {
          "name": "Asia/Dili",
          "value": "Asia/Dili"
        },
        {
          "name": "Asia/Ashgabat",
          "value": "Asia/Ashgabat"
        },
        {
          "name": "Africa/Tunis",
          "value": "Africa/Tunis"
        },
        {
          "name": "Pacific/Tongatapu",
          "value": "Pacific/Tongatapu"
        },
        {
          "name": "Europe/Istanbul",
          "value": "Europe/Istanbul"
        },
        {
          "name": "America/Port_of_Spain",
          "value": "America/Port_of_Spain"
        },
        {
          "name": "Pacific/Funafuti",
          "value": "Pacific/Funafuti"
        },
        {
          "name": "Asia/Taipei",
          "value": "Asia/Taipei"
        },
        {
          "name": "Europe/Kiev",
          "value": "Europe/Kiev"
        },
        {
          "name": "Europe/Uzhgorod",
          "value": "Europe/Uzhgorod"
        },
        {
          "name": "Europe/Zaporozhye",
          "value": "Europe/Zaporozhye"
        },
        {
          "name": "Pacific/Wake",
          "value": "Pacific/Wake"
        },
        {
          "name": "America/New_York",
          "value": "America/New_York"
        },
        {
          "name": "America/Detroit",
          "value": "America/Detroit"
        },
        {
          "name": "America/Kentucky/Louisville",
          "value": "America/Kentucky/Louisville"
        },
        {
          "name": "America/Kentucky/Monticello",
          "value": "America/Kentucky/Monticello"
        },
        {
          "name": "America/Indiana/Indianapolis",
          "value": "America/Indiana/Indianapolis"
        },
        {
          "name": "America/Indiana/Vincennes",
          "value": "America/Indiana/Vincennes"
        },
        {
          "name": "America/Indiana/Winamac",
          "value": "America/Indiana/Winamac"
        },
        {
          "name": "America/Indiana/Marengo",
          "value": "America/Indiana/Marengo"
        },
        {
          "name": "America/Indiana/Petersburg",
          "value": "America/Indiana/Petersburg"
        },
        {
          "name": "America/Indiana/Vevay",
          "value": "America/Indiana/Vevay"
        },
        {
          "name": "America/Chicago",
          "value": "America/Chicago"
        },
        {
          "name": "America/Indiana/Tell_City",
          "value": "America/Indiana/Tell_City"
        },
        {
          "name": "America/Indiana/Knox",
          "value": "America/Indiana/Knox"
        },
        {
          "name": "America/Menominee",
          "value": "America/Menominee"
        },
        {
          "name": "America/North_Dakota/Center",
          "value": "America/North_Dakota/Center"
        },
        {
          "name": "America/North_Dakota/New_Salem",
          "value": "America/North_Dakota/New_Salem"
        },
        {
          "name": "America/North_Dakota/Beulah",
          "value": "America/North_Dakota/Beulah"
        },
        {
          "name": "America/Denver",
          "value": "America/Denver"
        },
        {
          "name": "America/Boise",
          "value": "America/Boise"
        },
        {
          "name": "America/Phoenix",
          "value": "America/Phoenix"
        },
        {
          "name": "America/Los_Angeles",
          "value": "America/Los_Angeles"
        },
        {
          "name": "America/Anchorage",
          "value": "America/Anchorage"
        },
        {
          "name": "America/Juneau",
          "value": "America/Juneau"
        },
        {
          "name": "America/Sitka",
          "value": "America/Sitka"
        },
        {
          "name": "America/Metlakatla",
          "value": "America/Metlakatla"
        },
        {
          "name": "America/Yakutat",
          "value": "America/Yakutat"
        },
        {
          "name": "America/Nome",
          "value": "America/Nome"
        },
        {
          "name": "America/Adak",
          "value": "America/Adak"
        },
        {
          "name": "Pacific/Honolulu",
          "value": "Pacific/Honolulu"
        },
        {
          "name": "America/Montevideo",
          "value": "America/Montevideo"
        },
        {
          "name": "Asia/Samarkand",
          "value": "Asia/Samarkand"
        },
        {
          "name": "Asia/Tashkent",
          "value": "Asia/Tashkent"
        },
        {
          "name": "America/Caracas",
          "value": "America/Caracas"
        },
        {
          "name": "Asia/Ho_Chi_Minh",
          "value": "Asia/Ho_Chi_Minh"
        },
        {
          "name": "Pacific/Efate",
          "value": "Pacific/Efate"
        },
        {
          "name": "Pacific/Wallis",
          "value": "Pacific/Wallis"
        },
        {
          "name": "Pacific/Apia",
          "value": "Pacific/Apia"
        },
        {
          "name": "Africa/Johannesburg",
          "value": "Africa/Johannesburg"
        }
      ],
      revenue: [
        {
          name: this.$t('Less than') + ' €50,000',
          value: '0_50000'
        },
        {
          name: '€50,000 - €200,000',
          value: '50000_200000'
        },
        {
          name: '€200,000 - €500,000',
          value: '200000_500000'
        },
        {
          name: '€500,000 - €1 million',
          value: '500000_1000000'
        },
        {
          name: '€1 million - €5 million',
          value: '1000000_5000000'
        },
        {
          name: '€5 million - €10 million',
          value: '5000000_10000000'
        },
        {
          name: '> €10 million',
          value: '10000000_0'
        },
      ],
      employees: [
        {
          name: '1-10',
          value: '1_10'
        },
        {
          name: '11-25',
          value: '11_25'
        },
        {
          name: '26-50',
          value: '26_50'
        },
        {
          name: '51-200',
          value: '51_200'
        },
        {
          name: '201-500',
          value: '201_500'
        },
        {
          name: '501-1,000',
          value: '501_1000'
        },
        {
          name: '> 1,000',
          value: '1000_0'
        },
      ],
      originalCompany: {},
      company: {
        name: '',
        business: null, // Business Type
        industry: null,
        project: null,
        website: '', // Main Company Website
        revenue: '', // Annual Turnover | {max: 100, min: 1}
        employees: '', // Number of Employees | {max: 100, min: 1}
        description: '',
        tools: [],
        markets: [],
        contacts: [],
        zoneId: '',
        currency: ''
      },

      isUpdating: false,

      validationError: {
        name: false,
      },
      contacts: [],

      tools: [],
      toolsIsOpen: false,
      toolsIsOpenShowError: false,

      companyWebsites: [],
      companyWebsitesIsOpen: false,
      companyWebsitesIsOpenShowError: false,

      billingOverdue: false,
      prevHeight: 0,
      availableHeight: '344px',
      focusName: false,
      focusDescription: false,
      focusTimezone: false,

      companies: [],
      invites: [],
      showModalDeleteAccount: false,
      showModalForgotPassword: false,
      showModalDeleteCompany: false,
      disabledEditCompany: true,
      disabledInviteTeamMembers: true,
      errorEditCompany: [],
      name: '',
      description: '',
      timezone: 'Europe/Prague',
      searchTeam: '',
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          initialScrollY: false,
          initialScrollX: false,
        },
        rail: {},
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          minSize: 0,
        },
      },
      invalidName: false,
      invalidDescription: false,
      invalidTimezone: false,
      userTab: 'members',
      showLeaveModal: false,
    }
  },
  methods: {
    initPage() {
      this.$apollo.queries.companies.refetch()
    },
    updateCompanyInfo() {
      this.isUpdating = true;

      let company = {};
      company.id = this.companyID;
      company.name = this.company.name ?? "";
      company.description = this.company.description ?? "";
      company.website = this.company.website ?? "";

      if (this.company.markets && this.company.markets.length) {
        company.markets = this.company.markets.map((market) => market.value);
      } else {
        company.markets = [];
      }

      if (this.company.business && this.company.business.value) {
        company.business = this.company.business.value ?? null;
      } else {
        company.business = null;
      }

      if (this.company.industry && this.company.industry.value) {
        company.industry = this.company.industry.value ?? null;
      } else {
        this.company.industry = null;
      }

      if (this.company.project && this.company.project.value) {
        company.project = this.company.project.value ?? null;
      } else {
        company.project = null;
      }

      if (this.company.currency && this.company.currency.value) {
        company.currency = this.company.currency.value ?? null;
      } else {
        company.currency = null;
      }

      if (this.company.zoneId && this.company.zoneId.value) {
        company.zoneId = this.company.zoneId.value ?? null;
      } else {
        company.zoneId = null;
      }

      if (this.company.revenue && this.company.revenue.value) {
        const [before, after] = this.company.revenue.value.split("_");
        company.revenue = {min: before === 0 ? null : before, max: after === 0 ? null : after}
      } else {
        company.revenue = null;
      }

      if (this.company.employees && this.company.employees.value) {
        const [before, after] = this.company.employees.value.split("_");
        company.employees = {min: before === 0 ? null : before, max: after === 0 ? null : after}
      } else {
        company.employees = null;
      }

      if (this.company.contacts && this.company.contacts.length) {
        company.contacts = this.company.contacts.map((item) => {
          return {
            name: item.name,
            description: item.description,
            phoneNumbers: item.phoneNumbers.split(","),
            emails: item.emails.split(","),
          }
        })
      } else {
        this.company.contacts = null;
      }

      if (this.company.tools && this.company.tools.length) {
        company.tools = this.company.tools.map((item) => {
          return {
            name: item.name,
            link: item.link
          }
        })
      } else {
        company.tools = [];
      }

      try {
        this.$apollo.mutate({
          mutation: COMPANY_SOURCE_INFO_UPDATE,
          variables: company,
          update: (store, data) => {
            this.isUpdating = false;

            if (data.data.updateCompany.project) {
              this.positionProject = 'top';
              this.company.project = this.ProjectType.find((item) => item.value = data.data.updateCompany.project);
            } else {
              this.positionProject = 'bottom';
            }

            if (data.data.updateCompany.contact) {
              this.positionContact = 'top';
            } else {
              this.positionContact = 'bottom';
            }

            if (data.data.updateCompany.tools && data.data.updateCompany.tools.length > 0) {
              this.positionTools = 'top';
              this.tools = company.tools;
            } else {
              this.positionTools = 'bottom';
            }

            this.originalCompany = obj_clone(this.company);

          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_8_toaster_success_title_updated'),
            text: this.$t('15_8_toaster_success_description_updated'),
            type: 'success',
            duration: 5000,
          })
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_3_2_toaster_error_title_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })

        this.isUpdating = false;
      }
    },
    handleAddContact() {
      this.contacts.push( obj_clone(this.contact_template) )
    },
    handleRemoveContact(index) {
      this.contacts.splice(index, 1);
    },
    updateCompanyWebsites(sites) {
      this.companyWebsites = sites;
    },
    updateTools(items) {
      this.company.tools = items;
    },
    confirmLeave(to, from, next) {
      if (this.hasDataChanged) {
        this.showLeaveModal = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    },
    handleModalLeaveClose() {
      this.showLeaveModal = false;
    },
    handleModalLeaveConfirm() {
      this.company = obj_clone(this.originalCompany);

      this.showLeaveModal = false;
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.confirmLeave(to, from, next);
  },
  watch: {
    name() {
      this.disabledEditCompany = this.name === this.company.name
    },
    description() {
      this.disabledEditCompany = this.description === this.company.description
    },
    selectedCompany() {
      console.log("company has changed");
    },
    '$route.params': 'initPage',
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.short-container {
  width: 768px;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.sources-contacts-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.company-billing-date {
  color: #17252A;
  text-align: center;
}

.company-billing-date p{
  color: #17252A;
}

.company-billing-date p span {
  color: #E7E9E9;
}
</style>
