<template>
  <ContentWrapper :disable-top-offset="true">

    <SlickMenu :title="channel.name" >
      <Button
        size="nav"
        type="delete"
        @click="showModalDelete = true"
      >
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33398 4.83333H14.6673M6.33398 8.16667V13.1667M9.66732 8.16667V13.1667M2.16732 4.83333L3.00065 14.8333C3.00065 15.2754 3.17625 15.6993 3.48881 16.0118C3.80137 16.3244 4.22529 16.5 4.66732 16.5H11.334C11.776 16.5 12.1999 16.3244 12.5125 16.0118C12.8251 15.6993 13.0007 15.2754 13.0007 14.8333L13.834 4.83333M5.50065 4.83333V2.33333C5.50065 2.11232 5.58845 1.90036 5.74473 1.74408C5.90101 1.5878 6.11297 1.5 6.33398 1.5H9.66732C9.88833 1.5 10.1003 1.5878 10.2566 1.74408C10.4129 1.90036 10.5007 2.11232 10.5007 2.33333V4.83333" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_9_2_1_btn_delete') }}
      </Button>

      <!--
      <Button size="nav" type="secondary">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.67559 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.67559 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333M14.1667 7.5V4.16667C14.1667 3.72464 13.9911 3.30072 13.6785 2.98816C13.366 2.67559 12.942 2.5 12.5 2.5H7.5C7.05797 2.5 6.63405 2.67559 6.32149 2.98816C6.00893 3.30072 5.83333 3.72464 5.83333 4.16667V7.5M5.83333 12.5C5.83333 12.058 6.00893 11.634 6.32149 11.3215C6.63405 11.0089 7.05797 10.8333 7.5 10.8333H12.5C12.942 10.8333 13.366 11.0089 13.6785 11.3215C13.9911 11.634 14.1667 12.058 14.1667 12.5V15.8333C14.1667 16.2754 13.9911 16.6993 13.6785 17.0118C13.366 17.3244 12.942 17.5 12.5 17.5H7.5C7.05797 17.5 6.63405 17.3244 6.32149 17.0118C6.00893 16.6993 5.83333 16.2754 5.83333 15.8333V12.5Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_9_2_1_btn_print') }}
      </Button>
      -->
      <Button @click="handleUpdateChannel" :disabled="isUpdating" size="nav" type="primary">{{ $t('15_9_2_1_btn_save') }}</Button>
    </SlickMenu>

    <div class="product-wrapper">
      <div class="product-detail">


        <div class="product-detail-body">
          <div class="column column--1280">
            <div class="boxed boxed--full">
              <div class="box">
                <h2>{{  $t('15_9_2_1_title_main_info') }}</h2>

                <div class="column">
                  <FormGroup
                    :label="$t('15_9_2_1_label_channel_title')"
                    :required="true"
                    :validation-error="validationError.name"
                  >
                    <input
                      type="text"
                      :placeholder="$t('15_9_2_1_placeholder_enter_title')"
                      v-model="channel.name"
                      @input="validationError.name = false"
                      ref="contentName"
                    />
                  </FormGroup>
                </div>

                <div class="column">
                  <ColorPicker
                    v-model="channel.color.name"
                    @updateColor="setColor"
                    :label="$t('15_9_2_1_label_channel_color')"
                  />

                  <div class="col-full">
                    <ImageUploader
                      :title="$t('15_9_2_1_label_channel_image')"
                      sub-category="channel"
                      :sub-category-id="channel.id"
                      :company-id="companyID"
                    />
                  </div>
                </div>

                <div>
                  <FormGroup :label="$t('15_9_2_1_label_key_insight')">
                    <input
                      type="text"
                      :placeholder="$t('15_9_2_1_placeholder_enter_key_insight')"
                      v-model="channel.keyInsights"
                    />
                  </FormGroup>
                  <Hint>{{ $t('15_9_2_1_hint_key_insight') }}</Hint>
                </div>

                <STDCFrameworksAndPhases
                  :stdcs="channel.stdcs"
                  @createSTDC="showModalAddStdc = true"
                  @deleteSTDC="deleteStdc"
                  @editSTDC="editStdc"
                />

                <div
                  v-if="channel.link && channel.link.length !== 0"
                  class="column"
                >
                  <FormGroup :label="$t('15_9_2_1_label_channel_link')" :required="false">
                    <Resources
                      :items="channel.link"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      :multiple="true"
                      transCreateNew="15_9_2_1_channel_link_add"
                      @updateResources="updateLink"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>

                <Accordion
                  v-if="channel.link && channel.link.length === 0"
                  :title="$t('Additional')"
                  :description="$t('The Additional Info section includes additional input fields that, when filled out, will display alongside the other information above.')"
                >
                  <div
                    v-if="channel.link && channel.link.length === 0"
                    class="column"
                  >
                    <FormGroup :label="$t('15_9_2_1_label_channel_link')" :required="false">
                      <Resources
                        :items="channel.link"
                        :disabled="role === 'READ_ONLY'"
                        :showError="resourcesIsOpenShowError"
                        :multiple="true"
                        transCreateNew="15_9_2_1_channel_link_add"
                        @updateResources="updateLink"
                        @opened="resourcesIsOpen = true"
                        @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                      />
                    </FormGroup>
                  </div>
                </Accordion>

              </div>
            </div>

            <div class="boxed boxed--full boxed--second-1280">
              <div class="box">
                <h2>{{$t('15_9_2_1_title_other_info') }}</h2>

                <div class="column">
                  <Dropdown
                    v-model="channel.publicationFrequency"

                    :label="$t('15_9_2_1_label_publication_frequency')"
                    :placeholder="$t('15_9_2_1_placeholder_select_publication_frequency')"
                    :items="PublicationFrequency"
                  />
                  <!--
                  <ButtonAdd />
                  -->
                </div>

                <div class="column">
                  <FormGroup :label="$t('15_9_2_1_label_formats')" :required="false">
                    <input
                      type="text"
                      :placeholder="$t('15_9_2_1_placeholder_formats')"
                      v-model="channel.formats"
                    />
                  </FormGroup>
                </div>

                <div class="column">
                  <FormGroup :label="$t('15_9_2_1_label_main_messages')" :required="false">
                    <Resources
                      :items="channel.mainMessages"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      :name-only="true"
                      :name-large="true"
                      transCreateNew="15_9_2_1_main_messages_add"
                      @updateResources="updateMainMessages"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>


                <div
                  v-if="originalChannel.topics"
                  class="column">
                  <FormGroup :label="$t('15_9_2_1_label_topics')">
                    <input
                      type="text"
                      :placeholder="$t('15_9_2_1_placeholder_topics')"
                      v-model="channel.topics"
                    />
                  </FormGroup>
                </div>

                <div
                  v-if="originalChannel.goals.length !== 0"
                  class="column"
                >
                  <FormGroup :label="$t('15_9_2_1_label_goals')" :required="false">
                    <Resources
                      :items="channel.goals"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      :name-only="true"
                      :name-large="true"
                      transCreateNew="15_9_2_1_add_label_goals"
                      @updateResources="updateGoals"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>

                <div
                  v-if="originalChannel.lessonsLearned.length !== 0"
                  class="column"
                >
                  <FormGroup :label="$t('15_9_2_1_label_lessons_learnt')" :required="false">
                    <Resources
                      :items="channel.lessonsLearned"
                      :name-only="true"
                      :name-large="true"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      transCreateNew="15_9_2_1_add_lessons_learnt"
                      @updateResources="updateLessonsLearned"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>

                <div
                  v-if="originalChannel.note"
                  class="column"
                >
                  <FormGroup :label="$t('15_9_2_1_label_note')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_9_2_1_placeholder_note')"
                      v-model="channel.note"
                    ></textarea>
                  </FormGroup>
                </div>

                <div
                  v-if="(
                      (originalChannel.otherResources && originalChannel.otherResources.links.length !== 0)
                      || (originalChannel.otherResources && originalChannel.otherResources.files.length !== 0)
                    )"
                  class="column"
                >
                  <FormGroup
                    :label="$t('15_10_2_1_label_other_resources')"
                    :required="false"
                  >
                    <h2 class="files-title">
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      {{ $t('special_list_links_title') }}
                      ({{channel.otherResources.links.length}})
                    </h2>
                    <Resources
                      :items="channel.otherResources.links"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      transCreateNew="15_10_2_1_add_other_resources"
                      @updateResources="updateOtherResourcesLinks"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                    <SourcesFileUploader
                      query-type="competitor"
                      file-type="competitor-resource"
                      :destinationId="this.$route.params.channelId"
                      :attachments="channel.otherResources.files"
                      @refresh="refetch"
                      type="edit"
                    ></SourcesFileUploader>
                  </FormGroup>
                </div>

                <Accordion
                  v-if="
                    !originalChannel.topics
                    || originalChannel.goals.length === 0
                    || originalChannel.lessonsLearned.length === 0
                    || originalChannel.note === ''
                    || (originalChannel.otherResources.links.length === 0 && originalChannel.otherResources.files.length === 0)
                  "
                  :title="$t('15_9_2_1_accordion_title_additional')"
                  :description="$t('15_9_2_1_accordion_description_additional')"
                >
                  <div
                    v-if="!originalChannel.topics"
                    class="column">
                    <FormGroup :label="$t('15_9_2_1_label_topics')">
                      <input
                        type="text"
                        :placeholder="$t('15_9_2_1_placeholder_topics')"
                        v-model="channel.topics"
                      />
                    </FormGroup>
                  </div>

                  <div
                    v-if="originalChannel.goals.length === 0"
                    class="column"
                  >
                    <FormGroup :label="$t('15_9_2_1_label_goals')" :required="false">
                      <Resources
                        :items="channel.goals"
                        :disabled="role === 'READ_ONLY'"
                        :showError="resourcesIsOpenShowError"
                        :name-only="true"
                        :name-large="true"
                        transCreateNew="15_9_2_1_add_label_goals"
                        @updateResources="updateGoals"
                        @opened="resourcesIsOpen = true"
                        @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                      />
                    </FormGroup>
                  </div>

                  <div
                    v-if="originalChannel.lessonsLearned.length === 0"
                    class="column"
                  >
                    <FormGroup :label="$t('15_9_2_1_label_lessons_learnt')" :required="false">
                      <Resources
                        :items="channel.lessonsLearned"
                        :name-only="true"
                        :name-large="true"
                        :disabled="role === 'READ_ONLY'"
                        :showError="resourcesIsOpenShowError"
                        transCreateNew="15_9_2_1_add_lessons_learnt"
                        @updateResources="updateLessonsLearned"
                        @opened="resourcesIsOpen = true"
                        @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                      />
                    </FormGroup>
                  </div>

                  <div
                    v-if="!originalChannel.note"
                    class="column"
                  >
                    <FormGroup :label="$t('15_9_2_1_label_note')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_9_2_1_placeholder_note')"
                      v-model="channel.note"
                    ></textarea>
                    </FormGroup>
                  </div>

                  <div
                    v-if="(
                      (originalChannel.otherResources && originalChannel.otherResources.links.length === 0)
                      || (originalChannel.otherResources && originalChannel.otherResources.files.length === 0)
                    )"
                    class="column"
                  >
                    <FormGroup
                      :label="$t('15_10_2_1_label_other_resources')"
                      :required="false"
                    >
                      <h2 class="files-title">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        {{ $t('special_list_links_title') }}
                        ({{channel.otherResources.links.length}})
                      </h2>
                      <Resources
                        :items="channel.otherResources.links"
                        :disabled="role === 'READ_ONLY'"
                        :showError="resourcesIsOpenShowError"
                        transCreateNew="15_10_2_1_add_other_resources"
                        @updateResources="updateOtherResourcesLinks"
                        @opened="resourcesIsOpen = true"
                        @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                      />
                      <SourcesFileUploader
                        query-type="competitor"
                        file-type="competitor-resource"
                        :destinationId="this.$route.params.channelId"
                        :attachments="channel.otherResources.files"
                        @refresh="refetch"
                        type="edit"
                      ></SourcesFileUploader>
                    </FormGroup>
                  </div>
                </Accordion>

              </div>
            </div>

          </div>
        </div>
      </div>

      <ChannelRating :ratings="topRatedContentData" />
    </div>

    <Modal
      type="source"
      v-if="showModalAddStdc"
      @closeModal="showModalAddStdc = false"
    >
      <StdcModalAdd
        :personas="personas"
        :products="products"
        :stdcToEdit="null"
        @close="showModalAddStdc = false"
        @create="createStdc"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalEditStdc"
      @closeModal="showModalEditStdc = false"
    >
      <StdcModalAdd
        :stdcToEdit="stdcToEdit"
        :personas="personas"
        :products="products"
        @close="showModalEditStdc = false"
        @update="updateStdc"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <ChannelsModalDelete
        @close="showModalDelete = false"
        @delete="deleteChannel"
        :title="channel.name"
      />
    </Modal>

    <ModalConfirmClose
      v-if="showLeaveModal"
      @close="handleModalLeaveClose"
      @confirm="handleModalLeaveConfirm"
    />
  </ContentWrapper>
</template>

<script>
import Dropdown from "@/components/dropdown/Dropdown.vue";
import Button from "@/components/button/Button.vue";
import FormGroup from "@/components/FormGroup.vue";
import Resources from "@/components/design/Resources.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import Avatar from "@/components/Avatar.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import Hint from "@/components/sources/components/hint/Hint.vue";
import ButtonAdd from "@/components/buttonCopy/ButtonAdd.vue";
import ChannelRating from "@/components/sources/components/channels/ChannelRating.vue";
import ColorPicker from "@/components/colorPicker/ColorPicker.vue";
import Modal from "@/components/Modal.vue";
import ChannelsModalDelete from "@/components/sources/components/channels/ChannelsModalDelete.vue";
import STDCFrameworksAndPhases
  from "@/components/sources/components/stdcFrameworksAndPhases/STDCFrameworksAndPhases.vue";
import {arrayClone, obj_clone} from "@/services/helpers/clone";

import COMPANY_CHANNEL_DETAIL from '@/graphql/CompanyChannelDetail.gql';
import CHANNEL_UPDATE from "@/graphql/ChannelUpdate.gql";
import CHANNEL_DELETE from "@/graphql/ChannelDelete.gql";
import COMPANY_CHANNEL_FILES from "@/graphql/CompanyChannelFiles.gql";
import COMPANY_TOP_RATED_CONTENT from "@/graphql/CompanyTopRatedContent.gql";

import SourcesFileUploader from "@/components/sources/components/sourcesFileUploader/SourcesFileUploader.vue";
import ModalConfirmClose from "@/components/ModalConfirmClose.vue";
import {DateService as DataService} from "../../../services/DateService";
import utils from "@/utils/utils";
import STDC_UPDATE from "@/graphql/StdcUpdate.gql";
import STDC_DELETE from "@/graphql/StdcDelete.gql";
import STDC_CREATE from "@/graphql/StdcCreate.gql";
import StdcModalAdd from "@/components/sources/components/stdc/StdcModalAdd.vue";
import ImageUploader from "@/components/ImageUploader.vue";

export default {
  components: {
    ImageUploader,
    StdcModalAdd,
    ModalConfirmClose,
    SourcesFileUploader,
    STDCFrameworksAndPhases,
    ChannelsModalDelete,
    Modal,
    ColorPicker,
    ChannelRating,
    ButtonAdd,
    Hint,
    SlickMenu,
    ContentWrapper,
    CompanyLogo,
    Accordion,
    Resources,
    FormGroup,
    Button,
    Avatar,
    Dropdown
  },
  apollo: {
    companies: {
      query: COMPANY_CHANNEL_DETAIL,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {

        try {
          const company = data.companies.find((item) => item.id === this.companyID);

          this.personas = arrayClone(company.personas).map((persona) => {
            return {
              value: persona.id,
              name: persona.name
            }
          })

          this.products = arrayClone(company.products).map((product) => {
            return {
              value: product.id,
              name: product.name
            }
          })

          this.competitors = arrayClone(company.competitors).map((competitor) => {
            return {
              value: competitor.id,
              name: competitor.name
            }
          })

          const channel = company.channels.find((item) => item.id === this.$route.params.channelId);
          this.setChannel(channel);

        } catch(e) {

        }
      }
    },
    topRatedContent: {
      query: COMPANY_TOP_RATED_CONTENT,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        try {
          const company = data.companies.find((item) => item.id === this.companyID);
          this.topRatedContentData = company.topRatedContents;
          // console.log("this.topRatedContent: ", this.topRatedContent);
        } catch(e) { }
      }
    },
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
    dataHasChanged() {
      return JSON.stringify(this.channel) !== JSON.stringify(this.originalChannel);
    },
    productIds() {
      return this.channel.products.map((product) => product.value);
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
  },
  data() {
    return {
      topRatedContentData: [],
      stdcToEdit: null,
      showModalAddStdc: false,
      showModalEditStdc: false,

      PublicationFrequency: DataService.getPublicationFrequency(),

      personas: [],
      products: [],
      competitors: [],
      showLeaveModal: false,
      channel: {
        stdcs: [],
        competitors: [],
        personas: [],
        products: [],
        color: {
          cmyk: '',
          hex: '',
          name: ''
        },
        formats: '',
        goals: [],
        id: '',
        keyInsights: '',
        lessonsLearned: [],
        link: [],
        logoKey: '',
        mainMessages: [],
        name: '',
        note: '',
        otherResources: {
          files: [],
          links: []
        },
        publicationFrequency: '',
        ratio: 0,
        topics: ''
      },
      originalChannel: {
        competitors: [],
        personas: [],
        products: [],
        color: {
          cmyk: '',
          hex: '',
          name: ''
        },
        formats: '',
        goals: [],
        id: '',
        keyInsights: '',
        lessonsLearned: [],
        link: [],
        logoKey: '',
        mainMessages: [],
        name: '',
        note: '',
        otherResources: {
          files: [],
          links: []
        },
        publicationFrequency: '',
        ratio: 0,
        topics: ''
      },

      isUpdating: false,

      showModalDelete: false,
      resources: [],
      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,
      ratedContent: [
        {
          date: '8 Sep 2024, 09:00',
          title: 'Lorem ipsum dolor sit amet, ei iusto delenit pro, et sale soleat vix',
          rating: 4.5,
          user: ''
        },
        {
          date: '8 Sep 2024, 09:00',
          title: 'Lorem ipsum dolor sit amet, ei iusto delenit pro, et sale soleat vix',
          rating: 4.5,
          user: ''
        },
        {
          date: '8 Sep 2024, 09:00',
          title: 'Lorem ipsum dolor sit amet, ei iusto delenit pro, et sale soleat vix',
          rating: 4.5,
          user: ''
        },
        {
          date: '8 Sep 2024, 09:00',
          title: 'Lorem ipsum dolor sit amet, ei iusto delenit pro, et sale soleat vix',
          rating: 4.5,
          user: ''
        }
      ],

      validationError: {
        name: false,
      }
    }
  },
  methods: {
    updateStdc(stdc) {
      this.showModalEditStdc = false;

      stdc = obj_clone(utils.removeTypename(stdc));
      delete stdc.companyId;

      if (stdc.personas && stdc.personas.length && !stdc.personaIds) {
        stdc.personaIds = stdc.personas.map((item) => item.value);
      }
      delete stdc.personas;
      delete stdc.persona;

      if (stdc.products && stdc.products.length && !stdc.productIds) {
        stdc.productIds = stdc.products.map((item) => item.value);
      }
      delete stdc.products;
      delete stdc.product;

      const see = stdc.phases.find((item) => item.phaseType === 'SEE');
      see.phaseData.channelIds = [];
      if (see.phaseData.channels && see.phaseData.channels.length) {
        see.phaseData.channelIds = [];
        see.phaseData.channelIds = see.phaseData.channels.map((item) => item.value);
      }
      delete see.phaseData.channels

      const think = stdc.phases.find((item) => item.phaseType === 'THINK');
      think.phaseData.channelIds = [];
      if (think.phaseData.channels && think.phaseData.channels.length) {
        think.phaseData.channelIds = think.phaseData.channels.map((item) => item.value);
      }
      delete think.phaseData.channels

      const _do = stdc.phases.find((item) => item.phaseType === 'DO');
      _do.phaseData.channelIds = [];
      if (_do.phaseData.channels && _do.phaseData.channels.length) {
        _do.phaseData.channelIds = _do.phaseData.channels.map((item) => item.value);
      }
      delete _do.phaseData.channels

      const care = stdc.phases.find((item) => item.phaseType === 'CARE');
      care.phaseData.channelIds = [];
      if (care.phaseData.channels && care.phaseData.channels.length) {
        care.phaseData.channelIds = care.phaseData.channels.map((item) => item.value);
      }
      delete care.phaseData.channels

      if (
        stdc.otherResources
        && stdc.otherResources.links
        && stdc.otherResources.links.length > 0
      ) {
        stdc.otherResources = stdc.otherResources.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      } else {
        stdc.otherResources = [];
      }

      if (JSON.stringify(stdc.description) === "{}" || stdc.description === null) {
        stdc.description = null;
      }

      if (!stdc.productId) {
        stdc.productId = null;
      }

      if (!stdc.personaId) {
        stdc.personaId = null;
      }

      console.log("stdc to save: ", stdc);

      try {
        this.$apollo.mutate({
          mutation: STDC_UPDATE,
          variables: {
            stdc
          },
          update: (store, data) => {
            this.stdc = data.data.updateSTDC;
            this.originalStdc = arrayClone(this.stdc);
          }
        }).then(() => {

          this.$apollo.queries.companies.refetch();

          this.$notify({
            title: this.$t('15_8_toaster_success_title_saved'),
            text: this.$t('15_8_toaster_success_description_saved'),
            type: 'success',
            duration: 5000,
          });

          this.savingStdc = false;
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });

        this.savingStdc = false;
      }
    },
    editStdc(stdc) {
      console.log("stdc to edit: ", stdc);
      this.showModalEditStdc = true;
      this.stdcToEdit = stdc;
    },
    deleteStdc(id) {
      this.showModalDelete = false

      try {
        this.$apollo.mutate({
          mutation: STDC_DELETE,
          variables: {
            id
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_8_toaster_success_title_deleted'),
            text: this.$t('15_8_toaster_success_description_deleted'),
            type: 'success',
            duration: 5000,
          })
        })
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    createStdc(stdc) {
      this.showModalAddStdc = false;
      stdc.companyId = this.companyID;

      try {
        this.$apollo.mutate({
          mutation: STDC_CREATE,
          variables: {
            stdc: stdc
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();

            this.$notify({
              title: this.$t('15_8_toaster_success_title_created'),
              text: this.$t('15_8_toaster_success_description_created'),
              type: 'success',
              duration: 5000,
            })
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    setColor(color) {
      this.channel.color.name = color;
      this.channel.color.hex = color;
    },

    updateLink(links) {
      this.channel.link = links;
    },
    updateGoals(links) {
      this.channel.goals = links;
    },
    updateLessonsLearned(links) {
      this.channel.lessonsLearned = links;
    },
    updateMainMessages(links) {
      this.channel.mainMessages = links;
    },
    updateOtherResourcesLinks(links) {
      this.channel.otherResources.links = links;
    },
    handleGoBack() {
      this.$router.go(-1);
    },
    handleDelete() {
      this.showModalDelete = true;
    },
    deleteChannel() {
      this.isUpdating = true;

      try {
        this.$apollo.mutate({
          mutation: CHANNEL_DELETE,
          variables: {
            id: this.$route.params.channelId
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_9_2_1_toaster_success_title_deleted'),
            text: this.$t('15_9_2_1_toaster_success_description_deleted'),
            type: 'success',
            duration: 5000,
          });

          this.$router.push({ path: `/company/${this.companyID}/channels` });
          this.showModalDelete = false;
          this.isUpdating = false;
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_9_2_1_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });

        this.showModalDelete = false;
        this.isUpdating = false;
      }
    },
    setChannel(channel) {
      console.log("channel: ", channel);
      this.channel.id = channel.id;
      this.channel.name = channel.name;
      this.channel.ratio = channel.ratio;
      this.channel.keyInsights = channel.keyInsights;
      this.channel.note = channel.note;
      this.channel.formats = channel.formats;
      this.channel.formats = channel.formats;
      this.channel.logoKey = channel.logoKey;
      this.channel.logoKey = channel.logoKey;
      this.channel.publicationFrequency = channel.publicationFrequency;
      this.channel.topics = channel.topics;
      this.channel.stdcs = channel.stdcs;

      if (channel.products && channel.products.length) {
        this.channel.products = channel.products.map((item) => {
          return {
            name: item.name,
            value: item.id
          }
        });
      }

      if (channel.competitors && channel.competitors.length) {
        this.channel.competitors = channel.competitors.map((item) => {
          return {
            name: item.name,
            value: item.id
          }
        });
      }

      if (channel.personas && channel.personas.length) {
        this.channel.personas = channel.personas.map((item) => {
          return {
            name: item.name,
            value: item.id
          }
        });
      }

      if (channel.goals && channel.goals.length) {
        this.channel.goals = channel.goals.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      }

      if (channel.lessonsLearned && channel.lessonsLearned.length) {
        this.channel.lessonsLearned = channel.lessonsLearned.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      }

      if (channel.mainMessages && channel.mainMessages.length) {
        this.channel.mainMessages = channel.mainMessages.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      }

      if (channel.otherResources) {
        if (channel.otherResources.links) {
          this.channel.otherResources.links = arrayClone(channel.otherResources.links);
        }

        if (channel.otherResources.files) {
          this.channel.otherResources.files = arrayClone(channel.otherResources.files);
        }
      }

      if (channel.color && channel.color.name) {
        this.channel.color.name = channel.color.name;
        this.channel.color.hex = channel.color.hex;
      }

      if (channel.link) {
        this.channel.link = [];
        this.channel.link.push({
          name: channel.link.name,
          link: channel.link.link,
        })
      }

      this.originalChannel = obj_clone(this.channel);
    },
    handleUpdateChannel() {

      if (this.channel.name === '') {
        this.validationError.name = true;
        return;
      }

      const channel = {};
      channel.id =this.channel.id;
      channel.name = this.channel.name;

      if (this.channel.link && this.channel.link.length > 0) {
        channel.link = {
          link: this.channel.link[0].link,
          name: this.channel.link[0].name
        }
      }

      if (this.channel.ratio) {
        channel.ratio = this.channel.ratio;
      }

      if (this.channel.keyInsights) {
        channel.keyInsights = this.channel.keyInsights;
      }

      if (this.channel.note) {
        channel.note = this.channel.note;
      }

      if (this.channel.formats) {
        channel.formats = this.channel.formats;
      }

      if (this.channel.logoKey) {
        channel.logoKey = this.channel.logoKey;
      }

      if (this.channel.publicationFrequency) {
        channel.publicationFrequency = this.channel.publicationFrequency;
      }

      if (this.channel.topics) {
        channel.topics = this.channel.topics;
      }

      if (this.channel.goals && this.channel.goals.length) {
        channel.goals = this.channel.goals.map((item) => item.name);
      }

      if (this.channel.lessonsLearned && this.channel.lessonsLearned.length) {
        channel.lessonsLearned = this.channel.lessonsLearned.map((item) => item.name);
      }

      if (this.channel.mainMessages && this.channel.mainMessages.length) {
        channel.mainMessages = this.channel.mainMessages.map((item) => item.name);
      }

      if (
        this.channel.otherResources
        && this.channel.otherResources.links
        && this.channel.otherResources.links.length > 0
      ) {
        channel.otherResources = this.channel.otherResources.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      }

      if (this.channel.color.name) {
        channel.color = {
          name: this.channel.color.name,
          cmyk: '',
          hex: this.channel.color.hex
        }
      }

      this.isUpdating = true;

      try {
        this.$apollo.mutate({
          mutation: CHANNEL_UPDATE,
          variables: {
            channel
          },
          update: (store, data) => {
            this.originalChannel = arrayClone(this.channel);
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_9_2_1_toaster_success_title_saved'),
            text: this.$t('15_9_2_1_toaster_success_description_saved'),
            type: 'success',
            duration: 5000,
          });

          this.isUpdating = false;
        });
      } catch(e) {
        this.isUpdating = false;

        this.$notify({
          title: this.$t('15_9_2_1_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });
      }
    },
    confirmLeave(to, from, next) {
      if (this.dataHasChanged) {
        this.showLeaveModal = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    },
    handleModalLeaveClose() {
      this.showLeaveModal = false;
    },
    handleModalLeaveConfirm() {
      this.originalChannel = obj_clone(this.channel);

      this.showLeaveModal = false;
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      }
    },
    async refetch() {
      const response = await this.$apollo.query({
        query: COMPANY_CHANNEL_FILES,
        variables: {
          id: this.companyID,
        }
      });

      try {
        const company = response.data.companies.find((item) => item.id === this.companyID);
        const channel = company.channels.find((item) => item.id === this.$route.params.channelId);

        if (channel.otherResources) {
          if (channel.otherResources.files) {
            this.channel.otherResources.files = arrayClone(channel.otherResources.files);
          }
        }
      } catch(e) {

      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.confirmLeave(to, from, next);
  }
}
</script>

<style scoped>

.product-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 44px;
}

.product-detail {
  width: 100%;
}

.col-full {
  width: 100%;
}
</style>
