<template>
  <div class="stdc-card">
    <div class="stdc-inner">
      <div class="stdc-data">
        <div class="stdc-card-labels-list">
          <div class="stdc-title">
            {{ item.name }}
          </div>

          <div class="stdc-description">
            {{ item.description }}
          </div>

          <div v-if="item.product" class="stdc-card-labels">
            <div class="stdc-card-labels-title">
              {{ $t('15_7_1_3_label_products_and_services') }}:
            </div>
            <div class="stdc-card-labels-data">
              <Label
                :item="item.product"
                :title="item.product.name"
              />
            </div>
          </div>

          <div v-if="item.persona" class="stdc-card-labels">
            <div class="stdc-card-labels-title">
              {{ $t('15_7_1_3_label_personas') }}:
            </div>
            <div class="stdc-card-labels-data">
              <Label
                :item="item.persona"
                :title="item.persona.name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="stdc-card-control">
        <Button @click="handleOpenDetail" type="ico-only" size="small">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83301 6.33343H4.99967C4.55765 6.33343 4.13372 6.50903 3.82116 6.82159C3.5086 7.13415 3.33301 7.55807 3.33301 8.0001V15.5001C3.33301 15.9421 3.5086 16.3661 3.82116 16.6786C4.13372 16.9912 4.55765 17.1668 4.99967 17.1668H12.4997C12.9417 17.1668 13.3656 16.9912 13.6782 16.6786C13.9907 16.3661 14.1663 15.9421 14.1663 15.5001V14.6668M13.333 4.66677L15.833 7.16677M16.9872 5.98759C17.3154 5.65938 17.4998 5.21424 17.4998 4.75009C17.4998 4.28594 17.3154 3.84079 16.9872 3.51259C16.659 3.18438 16.2138 3 15.7497 3C15.2855 3 14.8404 3.18438 14.5122 3.51259L7.49967 10.5001V13.0001H9.99967L16.9872 5.98759Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </Button>
        <Button @click="handleDelete" type="ico-only" size="small">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33301 6.33333H16.6663M8.33301 9.66667V14.6667M11.6663 9.66667V14.6667M4.16634 6.33333L4.99967 16.3333C4.99967 16.7754 5.17527 17.1993 5.48783 17.5118C5.80039 17.8244 6.22431 18 6.66634 18H13.333C13.775 18 14.199 17.8244 14.5115 17.5118C14.8241 17.1993 14.9997 16.7754 14.9997 16.3333L15.833 6.33333M7.49967 6.33333V3.83333C7.49967 3.61232 7.58747 3.40036 7.74375 3.24408C7.90003 3.0878 8.11199 3 8.33301 3H11.6663C11.8874 3 12.0993 3.0878 12.2556 3.24408C12.4119 3.40036 12.4997 3.61232 12.4997 3.83333V6.33333" stroke="#F45050" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import LabelStdc from "@/components/sources/components/labelStdc/LabelStdc.vue";
import Label from "@/components/sources/components/label/Label.vue";

export default {
  components: {
    Label,
    LabelStdc,
    Button

  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
  },
  props: [
    'item'
  ],
  data() {
    return {

    }
  },
  methods: {
    handleOpenDetail() {
      this.$router.push({ path: `/company/${this.companyID}/stdc-detail/${this.item.id}` });
    },
    handleDelete() {
      this.$emit('delete', this.item.id);
    }
  }
}
</script>

<style scoped>
.stdc-card {
  padding: 20px;
  box-shadow: 0px 2px 2px 1px #0000000D;
  background-color: #fff;
  border-radius: 12px;
}

.stdc-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.stdc-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.stdc-title {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  color: #17252A;
}

.stdc-description {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #888F91;
}

.stdc-card-control {
  display: flex;
  gap: 4px;
  min-width: 70px;
  max-width: 70px;
}

.stdc-card-labels {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.stdc-card-labels-title {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #888F91;
}

.stdc-card-labels-data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.stdc-card-labels-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
