<template>
  <div class="persona-card">
    <div class="persona-card-inner">

      <div
        class="persona-card-avatar"
        :style="backgroundStyle"
      ></div>

      <div class="persona-card-data">
        <div class="persona-card-header">
          <div class="persona-card-header-content">
            <div class="persona-card-header-main">
              <div class="persona-card-header-title">
                {{ item.name }}
              </div>
              <div class="persona-card-header-data">
                <div class="persona-card-header-performance">

                </div>
                <div class="persona-card-header-percentages">
                  <template v-if="item.ratio">{{ item.ratio }}%</template>
                </div>
              </div>
            </div>
            <div class="persona-card-header-label">
              {{ labels.join("&nbsp;&nbsp;•&nbsp;&nbsp;") }}
            </div>
          </div>
          <button
            class="persona-card-open-detail"
            @click="handleOpenDetail"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 6L15 12L9 18" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

        <div class="persona-card-description">
          {{ item.keyInsights }}
        </div>

        <div v-if="stdcProducts && stdcProducts.length" class="persona-card-labels-list">
          <div class="persona-card-labels">
            <div class="persona-card-labels-title">
              {{ $t('15_6_1_3_label_products_and_services') }}:
            </div>
            <div class="persona-card-labels-data">
              <Label
                v-for="(item, index) in stdcProducts"
                :key="`product-index-${index}`"
                :item="item"
                :title="item.name"
              />
            </div>
          </div>

          <div v-if="stdcChannels && stdcChannels.length" class="persona-card-labels">
            <div class="persona-card-labels-title">
              {{ $t('15_6_1_3_label_channels') }}:
            </div>
            <div class="persona-card-labels-data">
              <Label
                v-for="(item, index) in stdcChannels"
                :key="`channel-index-${index}`"
                :title="item.name"
                :item="item"
              />
            </div>
          </div>

          <div class="persona-card-labels">
            <div class="persona-card-labels-title">
              {{ $t('15_6_1_3_label_number_of_stdc_frameworks') }}:
            </div>
            <div class="persona-card-labels-data">
              {{ item.stdcs.length }}
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";
import Label from "@/components/sources/components/label/Label.vue";
import LabelStdc from "@/components/sources/components/labelStdc/LabelStdc.vue";
import {getChannelsFromStdc, getProductFromStdc, getProductsFromStdc} from "@/utils/utils";
export default {
  components: {
    LabelStdc,
    Label,
    Avatar,
  },
  props: ['item'],
  computed: {
    stdcProducts() {
      return getProductsFromStdc( this.item.stdcs );
    },
    stdcChannels() {
      return getChannelsFromStdc( this.item.stdcs );
    },
    backgroundStyle() {
      if (!this.item.logo?.url) {
        return null;
      }

      return {
        backgroundImage: `url(${this.item.logo.url})`
      };
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    labels() {
      const _labels = [];

      if (this.item.gender) {
        _labels.push(this.item.gender);
      }

      if (this.item.age) {
        _labels.push(this.item.age.min + " - " + this.item.age.max + this.$t(' years old'));
      }

      if (this.item.demographics) {
        _labels.push(this.$t('lives in ') + (this.item.demographics));
      }

      if (this.item.education) {
        _labels.push(this.item.education);
      }

      if (this.item.occupation) {
        _labels.push(this.item.occupation);
      }

      return _labels;
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleOpenDetail() {
      this.$router.push({ path: `/company/${this.selectedCompany.id}/personas-and-audiences-detail/${this.item.id}` });
    }
  }
}
</script>

<style scoped>
.persona-card {
  padding: 20px;
  box-shadow: 0px 2px 2px 1px #0000000D;
  background-color: #fff;
  border-radius: 12px;
}

.persona-card-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.persona-card-avatar {
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #E7E9E9;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.persona-card-data {
  width: 100%;
}

.persona-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.persona-card-open-detail {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.persona-card-header-content {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.persona-card-header-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.persona-card-header-title {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
}

.persona-card-header-data {
  position: relative;
}

.persona-card-header-data::after {
  content: '';
  display: block;
  position: absolute;
  left: -6px;
  top: 3px;
  bottom: 3px;
  width: 1px;
  background-color: #E7E9E9;
}

.persona-card-header-percentages {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.02em;
  text-align: left;
}

.persona-card-header-label {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #888F91;
}

.persona-card-description {
  padding: 12px 0;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #17252A;
}


.persona-card-labels {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.persona-card-labels-title {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #888F91;
}

.persona-card-labels-data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.persona-card-labels-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
