<template>
  <FormGroup
    :label="label"
    :focused="showData"
    :validation-error="isInvalid"
  >
    <div class="dropdown" v-click-outside="hide" :class="{'position-top': itemsPosition === 'top'}">
      <div v-if="!multiple"
           class="dropdown__selected"
           :class="{'is-disabled': disabled}"
           @click="toggle"
      >
        <DropdownSingleSelected
          v-if="selected && selected.value"
          :item="selected"
          :type="type"
          :clear="clear"
          @removeSelected="removeItem()"
        />
        <div v-else class="dropdown__placeholder">{{ placeholder }}</div>
        <span :class="{'rotate': showData}">
          <IcoDown />
        </span>
      </div>

      <div v-if="multiple" class="dropdown__selected dropdown__selected__multi">
        <DropdownMultiSelected
          v-for="(item, index) in selected"
          :key="item.value"
          :item="item"
          :type="type"
          @removeMultiSelected="removeMultiSelected(index)"
        >
          {{ item.name }}
        </DropdownMultiSelected>
        <div
          @click="toggle"
          class="dropdown__placeholder dropdown__placeholder--inline"
        >
          {{ placeholder }}
        </div>

        <span
          class="dropdown__toggle"
          :class="{'rotate': showData}"
          @click="toggle"
        >
          <IcoDown />
        </span>
      </div>

      <div v-if="showData && itemList.length > 0" class="dropdown__data">
        <vuescroll :ops="scrollPanel">
          <DropdownItem
            v-for="(item, index) in itemList"
            :key="index"
            :item="item"
            :index="index"
            :type="type"
            :selected="selected"
            @selectItem="selectItem(item)"
          />
        </vuescroll>
      </div>

      <div v-if="showData && itemList.length === 0" class="dropdown__data">
        <p style="padding: 15px;">{{ $t("dropdown_select_no_options") }}</p>
      </div>
    </div>
  </FormGroup>
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import ClickOutside from 'vue-click-outside';
import IcoDown from '@/components/svg/IcoDown.vue';
import DropdownItem from "@/components/dropdown/DropdownItem.vue";
import DropdownSingleSelected from "./DropdownSingleSelected.vue";
import DropdownMultiSelected from "./DropdownMultiSelected.vue";
import vuescroll from "vuescroll";

export default {
  components: {
    vuescroll,
    DropdownMultiSelected,
    DropdownSingleSelected,
    DropdownItem,
    FormGroup,
    IcoDown
  },
  directives: {
    ClickOutside
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clear: {
      type: Boolean,
      default: true
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'normal'
    },
    itemsPosition: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return {
      scrollPanel: {
        initialScrollY: false,
        initialScrollX: false,
        scrollingX: true,
        scrollingY: true,
        speed: 300,
        easing: undefined,
        verticalNativeBarPos: 'right',
      },
      showData: false,
      selected: this.multiple ? [] : null
    }
  },
  computed: {
    itemList() {
      return this.items
        .map(item => ({ value: item.value, name: item.name }))
        // .sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  created() {
    this.selected = this.value;
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    }
  },
  methods: {
    removeMultiSelected(index) {
      if(this.multiple) {
        this.selected.splice(index, 1);
      }
    },
    removeItem(item = null) {
      if (this.multiple && item) {
        this.selected = this.selected.filter(i => i.value !== item.value);
      } else {
        this.selected = {};
      }
      this.$emit('input', this.selected);
    },
    selectItem(item) {
      if (this.multiple) {
        const isSelected = this.selected.some(i => i.value === item.value);
        if (isSelected) {
          this.selected = this.selected.filter(i => i.value !== item.value);
        } else {
          this.selected.push(item);
        }
      } else {
        this.selected = item;
        this.showData = false;
      }
      this.$emit('input', this.selected);
    },
    hide() {
      this.showData = false;
      this.$emit('blur');
    },
    toggle() {
      if (this.disabled) return;
      this.showData = !this.showData;
      this.$emit(this.showData ? 'focus' : 'blur');
    }
  }
}
</script>

<style>
.sp.is-open .dropdown__selected span svg path{
  stroke: #2CE0C1 !important;
}
.dropdown-item span {
  overflow: hidden;
}
</style>

<style scoped>
.dropdown{
  position: relative;
  padding: 5px 15px 5px 15px;
}

.dropdown.slim{
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 4px;
}

.dropdown__selected{
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 34px;
  line-height: 34px;
  padding-right: 24px;
  cursor: pointer;
  overflow: hidden;
}

span.dropdown__toggle {
  position: absolute;
  width: 36px;
  height: 36px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: 0;
  right: -10px;
}

.dropdown__selected__multi {
  display: flex;
  gap: 6px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 8px;
  padding-bottom: 6px;
  height: auto;
  line-height: 1;
  flex-wrap: wrap;
}

.dropdown__selected.is-disabled{
  cursor: auto !important;
}

.dropdown__selected span svg{
  transform: rotate(0deg);
  transition-duration: 0.2s;
  transition-property: transform;
  display: inline-block;
  height: 8px;
  line-height: 8px;

  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
}

.dropdown__selected span svg{
  height: 8px;
  line-height: 8px;
}

.dropdown__selected span.rotate svg{
  transform: rotate(180deg);
  transition-duration: 0.2s;
  transition-property: transform;
}

.dropdown__placeholder{
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #C5C5C5;
  line-height: 1;
  padding: 11px 15px 7px 0px;
}

.dropdown__placeholder--inline {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 0;
  padding-left: 4px;
  line-height: 1;
}

.dropdown__data{
  position: absolute;
  z-index: 99992;
  padding: 0;
  top: 100%;
  left: 10px;
  right: 10px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  height: 200px;
}

.dropdown__data .dropdown-item {
  gap: 8px;
}

.dropdown-item span {
  overflow: hidden;
}

.dropdown__data .dropdown-item span {
  overflow: hidden;
}

.dropdown.position-top .dropdown__data{
  bottom: 100%;
  margin-bottom: -6px;
  top: auto;
}
</style>
