<template>
  <ContentWrapper>
    <NoLeanCanvas
      v-if="list.length === 0"
      @add="handleAddLeanCanvas"
    />

    <div v-if="list.length > 0" class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_4_1_2_title') }}</h1>

        <div class="submenu">
          <div class="submenu-count">
            <div class="submenu-count-label">{{ $t('15_4_1_2_label_items_count') }}:</div>
            {{ list.length }}
          </div>
          <div class="submenu-items">
            <Button
              @click="handleAddLeanCanvas"
              size="nav"
              type="primary"
            >
              <IcoPlus />
              {{ $t('15_4_1_2_btn_add_canvas') }}
            </Button>
          </div>
        </div>

        <div class="items">
          <LeanCanvasCard
            v-for="(item) in list"
            :item="item"
            :key="item.id"
            @delete="toDeleteLeanCanvas"
          />
        </div>
      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <LeanCanvasModalDelete
        :item="leanCanvasToDelete"
        @close="cancelDeleteLeanCanvas"
        @delete="deleteLeanCanvas"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalAddLeanCanvas"
      @closeModal="showModalAddLeanCanvas = false"
    >
      <LeanCanvasModalAdd
        @close="showModalAddLeanCanvas = false"
        @create="createLeanCanvas"
      />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import NoLeanCanvas from "@/components/sources/components/leanCanvas/NoLeanCanvas.vue";
import Button from "@/components/button/Button.vue";
import LeanCanvasCard from "@/components/sources/components/leanCanvas/LeanCanvasCard.vue";
import Modal from "@/components/Modal.vue";
import LeanCanvasModalAdd from "@/components/sources/components/leanCanvas/LeanCanvasModalAdd.vue";
import COMPANY_LEAN_CANVAS from '@/graphql/CompanyLeanCanvas.gql';
import LEAN_CANVAS_CREATE from '@/graphql/LeanCanvasCreate.gql';
import LEAN_CANVAS_DELETE from '@/graphql/LeanCanvasDelete.gql';
import StdcModalDelete from "../components/stdc/StdcModalDelete.vue";
import LeanCanvasModalDelete from "../components/leanCanvas/LeanCanvasModalDelete.vue";

export default {
  components: {
    LeanCanvasModalDelete,
    StdcModalDelete,
    Modal,
    LeanCanvasCard,
    NoLeanCanvas,
    ContentWrapper,
    IcoPlus,
    Button,
    LeanCanvasModalAdd
  },
  apollo: {
    companies: {
      query: COMPANY_LEAN_CANVAS,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];

        try {
          if(company) {
            this.list = company.leanCanvases;
          }
        } catch(e) {
          this.list = [];
        }
      }
    },
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      list: [],
      showModalAddLeanCanvas: false,
      showModalDelete: false,
      leanCanvasToDelete: null,
      editLeanCanvas: null,
    }
  },
  methods: {
    handleAddLeanCanvas() {
      this.showModalAddLeanCanvas = true;
    },
    cancelDeleteLeanCanvas() {
      this.showModalDelete = false;
      this.leanCanvasToDelete = null;
    },
    createLeanCanvas(leanCanvas) {
      const _leanCanvas = {};
      _leanCanvas.companyId = this.companyID;

      if (leanCanvas.name) {
        _leanCanvas.name = leanCanvas.name;
      }

      if (leanCanvas.description) {
        _leanCanvas.description = leanCanvas.description;
      }

      if (leanCanvas.productId) {
        _leanCanvas.productId = leanCanvas.productId;
      }

      try {
        this.$apollo.mutate({
          mutation: LEAN_CANVAS_CREATE,
          variables: {
            leanCanvas: _leanCanvas
          },
          update: (store, data) => {
            this.list.push(data.data.createLeanCanvas);
            this.showModalAddLeanCanvas = false;
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_4_1_2_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    toDeleteLeanCanvas(item) {
      this.leanCanvasToDelete = item;
      this.showModalDelete = true;
    },
    deleteLeanCanvas(leanCanvas) {
      try {
        this.$apollo.mutate({
          mutation: LEAN_CANVAS_DELETE,
          variables: {
            id: leanCanvas.id
          },
          update: (store, data) => {
            this.list.splice(this.list.findIndex((item) => item.id === leanCanvas.id), 1);
            this.showModalDelete = false
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_4_1_2_toaster_success_title_deleted'),
            text: this.$t('15_4_1_2_toaster_success_description_deleted'),
            type: 'success',
            duration: 5000,
          })
        })
      } catch(e) {
        this.$notify({
          title: this.$t('15_4_1_2_toaster_error_title_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    }
  }
}
</script>

<style scoped>
.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.submenu {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.submenu-count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  gap: 4px;
}

.submenu-count-label {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #AEB3B5;
}

.submenu-items {
  display: flex;
  gap: 16px;
}
</style>
