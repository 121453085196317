<template>
  <div class="file-item">
    <div class="file-item-ico">
      <div class="file-item-ico-uploaded">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19M14 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V8M14 3L19 8M9 15L11 17L15 13" stroke="#9795FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="file-item-data">
      <div class="file-item-context">
        <div class="file-item-context-title" ref="filenameContainer">
          {{ file.name }}
        </div>
        <div class="file-item-context-meta">
          <span>{{ translatedFileType }}</span>
          <span>{{ readableFileSize }}</span>
          <span>{{ formattedLastModifiedDate }}</span>
          <span>{{ file.author.name }}</span>
        </div>
      </div>
      <div class="file-item-control">
        <button v-if="!file.uploaded" @click="handleDownload">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 11.3327V12.666C2.66675 13.0196 2.80722 13.3588 3.05727 13.6088C3.30732 13.8589 3.64646 13.9993 4.00008 13.9993H12.0001C12.3537 13.9993 12.6928 13.8589 12.9429 13.6088C13.1929 13.3588 13.3334 13.0196 13.3334 12.666V11.3327M4.66675 7.33268L8.00008 10.666M8.00008 10.666L11.3334 7.33268M8.00008 10.666V2.66602" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <button v-if="!confirmDelete && !file.uploaded" @click="handleDelete">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 4.66667H13.3334M6.66675 7.33333V11.3333M9.33341 7.33333V11.3333M3.33341 4.66667L4.00008 12.6667C4.00008 13.0203 4.14056 13.3594 4.39061 13.6095C4.64065 13.8595 4.97979 14 5.33341 14H10.6667C11.0204 14 11.3595 13.8595 11.6096 13.6095C11.8596 13.3594 12.0001 13.0203 12.0001 12.6667L12.6667 4.66667M6.00008 4.66667V2.66667C6.00008 2.48986 6.07032 2.32029 6.19534 2.19526C6.32037 2.07024 6.48994 2 6.66675 2H9.33341C9.51023 2 9.67979 2.07024 9.80482 2.19526C9.92984 2.32029 10.0001 2.48986 10.0001 2.66667V4.66667" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <button v-if="confirmDelete && !file.uploaded" class="delete" @click="handleConfirmDelete">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 4.66667H13.3334M6.66675 7.33333V11.3333M9.33341 7.33333V11.3333M3.33341 4.66667L4.00008 12.6667C4.00008 13.0203 4.14056 13.3594 4.39061 13.6095C4.64065 13.8595 4.97979 14 5.33341 14H10.6667C11.0204 14 11.3595 13.8595 11.6096 13.6095C11.8596 13.3594 12.0001 13.0203 12.0001 12.6667L12.6667 4.66667M6.00008 4.66667V2.66667C6.00008 2.48986 6.07032 2.32029 6.19534 2.19526C6.32037 2.07024 6.48994 2 6.66675 2H9.33341C9.51023 2 9.67979 2.07024 9.80482 2.19526C9.92984 2.32029 10.0001 2.48986 10.0001 2.66667V4.66667" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <button v-if="!confirmTempDelete && file.uploaded" @click="handleTempDelete">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 4.66667H13.3334M6.66675 7.33333V11.3333M9.33341 7.33333V11.3333M3.33341 4.66667L4.00008 12.6667C4.00008 13.0203 4.14056 13.3594 4.39061 13.6095C4.64065 13.8595 4.97979 14 5.33341 14H10.6667C11.0204 14 11.3595 13.8595 11.6096 13.6095C11.8596 13.3594 12.0001 13.0203 12.0001 12.6667L12.6667 4.66667M6.00008 4.66667V2.66667C6.00008 2.48986 6.07032 2.32029 6.19534 2.19526C6.32037 2.07024 6.48994 2 6.66675 2H9.33341C9.51023 2 9.67979 2.07024 9.80482 2.19526C9.92984 2.32029 10.0001 2.48986 10.0001 2.66667V4.66667" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <button v-if="confirmTempDelete && file.uploaded" class="delete" @click="handleConfirmTempDelete">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 4.66667H13.3334M6.66675 7.33333V11.3333M9.33341 7.33333V11.3333M3.33341 4.66667L4.00008 12.6667C4.00008 13.0203 4.14056 13.3594 4.39061 13.6095C4.64065 13.8595 4.97979 14 5.33341 14H10.6667C11.0204 14 11.3595 13.8595 11.6096 13.6095C11.8596 13.3594 12.0001 13.0203 12.0001 12.6667L12.6667 4.66667M6.00008 4.66667V2.66667C6.00008 2.48986 6.07032 2.32029 6.19534 2.19526C6.32037 2.07024 6.48994 2 6.66675 2H9.33341C9.51023 2 9.67979 2.07024 9.80482 2.19526C9.92984 2.32029 10.0001 2.48986 10.0001 2.66667V4.66667" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import COMPANY_COMMUNICATION_MANUAL_FILES from'@/graphql/CompanyCommunicationManualFiles.gql'
import gql from 'graphql-tag'
import { FileUploaderService } from "@/components/fileUploader/FileUploaderService";
import { SourcesFileUploaderService } from "./SourcesFileUploaderService";
import {arrayClone} from "../../../../services/helpers/clone";

export default {
  name: 'SourcesFileItem',
  components: {},
  computed: {
    translatedFileType() {
      const fileTypeMappings = {
        'application/pdf': 'PDF',
        'application/msword': 'Word Document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word Document (DOCX)',
        'application/vnd.ms-excel': 'Excel Spreadsheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel Spreadsheet (XLSX)',
        'image/jpeg': 'JPEG Image',
        'image/png': 'PNG Image',
        'text/plain': 'Text File',
        'application/zip': 'ZIP Archive',
        'application/x-rar-compressed': 'RAR Archive',
        // Add more file type mappings as needed
      };

      return fileTypeMappings[this.file.type] || this.file.type; // Return the translated type or the original if no match is found
    },
    readableFileSize() {
      const bytes = this.file.size;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      const size = bytes / Math.pow(1024, i);
      return size.toFixed(2) + ' ' + sizes[i];
    },
    formattedLastModifiedDate() {
      const fileDate = new Date(this.file.lastModified);
      const now = new Date();
      const timeDifference = now - fileDate; // Difference in milliseconds
      const seconds = Math.floor(timeDifference / 1000);
      let minutes = Math.floor(seconds / 60);
      if (minutes < 1) minutes = 1;
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (days === 0) {
        if (hours === 0) {
          return `${this.$t('special_list_inputs_upload_minutes_before')}${minutes}${this.$t('special_list_inputs_upload_minutes_after')}`;
        }
        return `${this.$t('special_list_inputs_upload_hours_before')}${hours}${this.$t('special_list_inputs_upload_hours_after')}`;
      }

      const day = String(fileDate.getDate()).padStart(2, '0');
      const month = String(fileDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
      const year = fileDate.getFullYear();

      return `${day}.${month}.${year}`;
    }
  },
  props: [
    'file',
    'companyId',
    'destinationId',
    'type',
    'queryType',
    'fileType'
  ],
  data() {
    return {
      confirmDelete: false,
      confirmTempDelete: false,
    }
  },
  methods: {
    async downloadProductItem() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query contents($companyId: String!) {
            companies(filter: [{name: id, operator: EQ, value: $companyId}]) {
                  id
                  products {
                    id
                    otherResources {
                        files {
                            name
                            size
                            key
                            type
                            url
                        }
                    }
                  }
                }
            }`,
          variables: {
            companyId: this.companyId,
          }
        });

        const company = response.data.companies.find((item) => item.id === this.$route.params.id);
        const product = company.products.find((item) => item.id === this.$route.params.productId);

        if (product.otherResources) {
          if (product.otherResources.files) {
            const files = arrayClone(product.otherResources.files);
            const attachment = files.filter((item) => item.key === this.file.key);
            const attachmentUrl = attachment[0].url;

            if (attachmentUrl) {
              const a = document.createElement('a');
              a.href = attachmentUrl;
              a.target = '_blank';
              a.download = this.file.name;
              a.click();
            }
          }
        }
      } catch(e) {}
    },
    async downloadCompetitorItem() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query contents($companyId: String!) {
            companies(filter: [{name: id, operator: EQ, value: $companyId}]) {
                  id
                  competitors {
                    id
                    otherResources {
                        files {
                            name
                            size
                            key
                            type
                            url
                        }
                    }
                  }
                }
            }`,
          variables: {
            companyId: this.companyId,
          }
        });

        const company = response.data.companies.find((item) => item.id === this.$route.params.id);
        const competitor = company.competitors.find((item) => item.id === this.$route.params.competitorId);

        if (competitor.otherResources) {
          if (competitor.otherResources.files) {
            const files = arrayClone(competitor.otherResources.files);
            const attachment = files.filter((item) => item.key === this.file.key);
            const attachmentUrl = attachment[0].url;

            if (attachmentUrl) {
              const a = document.createElement('a');
              a.href = attachmentUrl;
              a.target = '_blank';
              a.download = this.file.name;
              a.click();
            }
          }
        }
      } catch(e) {}
    },
    async downloadPersonaItem() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query contents($companyId: String!) {
            companies(filter: [{name: id, operator: EQ, value: $companyId}]) {
                  id
                  personas {
                    id
                    otherResources {
                        files {
                            name
                            size
                            key
                            type
                            url
                        }
                    }
                  }
                }
            }`,
          variables: {
            companyId: this.companyId,
          }
        });

        const company = response.data.companies.find((item) => item.id === this.$route.params.id);
        const persona = company.personas.find((item) => item.id === this.$route.params.personaId);

        if (persona.otherResources) {
          if (persona.otherResources.files) {
            const files = arrayClone(persona.otherResources.files);
            const attachment = files.filter((item) => item.key === this.file.key);
            const attachmentUrl = attachment[0].url;

            if (attachmentUrl) {
              const a = document.createElement('a');
              a.href = attachmentUrl;
              a.target = '_blank';
              a.download = this.file.name;
              a.click();
            }
          }
        }
      } catch(e) {}
    },
    async downloadStdcItem() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query contents($companyId: String!) {
            companies(filter: [{name: id, operator: EQ, value: $companyId}]) {
                  id
                  stdcs {
                    id
                    otherResources {
                        files {
                            name
                            size
                            key
                            type
                            url
                        }
                    }
                  }
                }
            }`,
          variables: {
            companyId: this.companyId,
          }
        });

        const company = response.data.companies.find((item) => item.id === this.$route.params.id);
        const stdc = company.stdcs.find((item) => item.id === this.$route.params.stdcId);

        if (stdc.otherResources) {
          if (stdc.otherResources.files) {
            const files = arrayClone(stdc.otherResources.files);
            const attachment = files.filter((item) => item.key === this.file.key);
            const attachmentUrl = attachment[0].url;

            if (attachmentUrl) {
              const a = document.createElement('a');
              a.href = attachmentUrl;
              a.target = '_blank';
              a.download = this.file.name;
              a.click();
            }
          }
        }
      } catch(e) {}
    },
    async handleDownload() {

      if(this.queryType === 'persona') {
        await this.downloadPersonaItem();
        return;
      }

      if(this.queryType === 'competitor') {
        await this.downloadCompetitorItem();
        return;
      }

      if(this.queryType === 'product') {
        await this.downloadProductItem();
        return;
      }

      if(this.queryType === 'stdc') {
        await this.downloadStdcItem();
        return;
      }

      const today = new Date();
      const lastMonth = new Date();
      lastMonth.setDate(today.getDate() - 30);

      try {
        const response = await this.$apollo.query({
          query: gql`
          query contents($companyId: String!) {
            companies(filter: [{name: id, operator: EQ, value: $companyId}]) {
                    id
                    brand {
                        ${this.queryType} {
                            files {
                                name
                                size
                                key
                                lastModified
                                author {
                                    id
                                    name
                                }
                                type
                                url
                            }
                        }
                    }
                }
            }`,
          variables: {
            companyId: this.companyId,
          }
        });

        const attachment = response.data.companies[0].brand[this.queryType].files.filter((item) => item.key === this.file.key);
        const attachmentUrl = attachment[0].url;

        if(attachmentUrl) {
          const a = document.createElement('a');
          a.href = attachmentUrl;
          a.target = '_blank';
          a.download = this.file.name;
          a.click();
        }

      } catch (error) {
        console.log("Error:", error);
        this.$notify({
          title: this.$t('trs_cannot_load_data'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });
      }
    },
    handleDelete() {
      this.confirmDelete = true;

      this.$notify({
        title: this.$t("toaster_info_title_confirm_delete"),
        text: this.$t("toaster_info_description_confirm_delete"),
        type: "info",
        duration: 5000,
      });

      setTimeout(() => {
        this.confirmDelete = false;
      }, 2000);
    },
    async handleConfirmDelete() {
      this.confirmDelete = false;

      try {
        await SourcesFileUploaderService.deleteAttachment(this.file.key, this.destinationId, this.fileType);
        this.$emit("deleted");
      } catch(e) {

      }

    },
    handleTempDelete() {
      this.confirmTempDelete = true;

      this.$notify({
        title: this.$t("toaster_info_title_confirm_delete"),
        text: this.$t("toaster_info_description_confirm_delete"),
        type: "info",
        duration: 5000,
      });

      setTimeout(() => {
        this.confirmTempDelete = false;
      }, 2000);
    },
    async handleConfirmTempDelete() {
      this.confirmTempDelete = false;

      try {
        await SourcesFileUploaderService.deleteTempContentAttachment(this.file.formData.key);
        this.$emit("removeFromList", this.file);
      } catch(e) {

      }

    }
  }
}
</script>

<style scoped>
.file-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
}

.file-item-ico {
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
}

.file-item-ico-uploaded {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: rgba(151, 149, 255, 0.1);
}

.file-item-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.file-item-context {
  width: 100%;
}

.file-item-control {
  display: flex;
  min-width: 56px;
  max-width: 56px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.file-item-control button{
  border: none;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.file-item-control button:hover {
  background: #d1d4d4;
  color: #17252a;
}

.file-item-control button.delete {
  background: #f45050;
  color: #fff;
}

.file-item-control button.delete:hover {
  background: #d23838;
  color: #fff;
}

.file-item-control button.delete.active {
  background: #d23838;
  color: #fff;
}

.file-item-control button.delete:active {
  background: #b02828;
  color: #fff;
}

.file-item-context-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-item-context-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(136, 143, 145, 1);
}

.file-item-context-meta span:after {
  content: '•';
  display: inline-block;
  padding-left: 4px;
}

.file-item-context-meta span:last-child:after {
  content: '';
}
</style>
