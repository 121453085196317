<template>
  <ContentWrapper :disable-top-offset="true">

    <SlickMenu :title="product.name" >
      <Button @click="showModalDelete = true" size="nav" type="delete">
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33398 4.83333H14.6673M6.33398 8.16667V13.1667M9.66732 8.16667V13.1667M2.16732 4.83333L3.00065 14.8333C3.00065 15.2754 3.17625 15.6993 3.48881 16.0118C3.80137 16.3244 4.22529 16.5 4.66732 16.5H11.334C11.776 16.5 12.1999 16.3244 12.5125 16.0118C12.8251 15.6993 13.0007 15.2754 13.0007 14.8333L13.834 4.83333M5.50065 4.83333V2.33333C5.50065 2.11232 5.58845 1.90036 5.74473 1.74408C5.90101 1.5878 6.11297 1.5 6.33398 1.5H9.66732C9.88833 1.5 10.1003 1.5878 10.2566 1.74408C10.4129 1.90036 10.5007 2.11232 10.5007 2.33333V4.83333" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_7_2_1_btn_delete') }}
      </Button>
      <!--
      <Button size="nav" type="secondary">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.67559 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.67559 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333M14.1667 7.5V4.16667C14.1667 3.72464 13.9911 3.30072 13.6785 2.98816C13.366 2.67559 12.942 2.5 12.5 2.5H7.5C7.05797 2.5 6.63405 2.67559 6.32149 2.98816C6.00893 3.30072 5.83333 3.72464 5.83333 4.16667V7.5M5.83333 12.5C5.83333 12.058 6.00893 11.634 6.32149 11.3215C6.63405 11.0089 7.05797 10.8333 7.5 10.8333H12.5C12.942 10.8333 13.366 11.0089 13.6785 11.3215C13.9911 11.634 14.1667 12.058 14.1667 12.5V15.8333C14.1667 16.2754 13.9911 16.6993 13.6785 17.0118C13.366 17.3244 12.942 17.5 12.5 17.5H7.5C7.05797 17.5 6.63405 17.3244 6.32149 17.0118C6.00893 16.6993 5.83333 16.2754 5.83333 15.8333V12.5Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_7_2_1_btn_print') }}
      </Button>
      -->
      <Button @click="updateProduct" :disabled="isUpdating" size="nav" type="primary">{{ $t('15_7_2_1_btn_save') }}</Button>
    </SlickMenu>


    <div class="product-detail">
      <div class="product-detail-body">
        <div class="column">
          <div class="boxed boxed--full">
            <div class="box">
              <h2>{{  $t('15_7_2_1_title_main_info') }}</h2>

              <div class="column">
                  <FormGroup
                    :label="$t('15_7_2_1_label_product_service_name')"
                    :required="true"
                    :validationError="validationError.name"
                  >
                    <input
                      type="text"
                      :placeholder="$t('15_7_2_1_placeholder_product_service_name')"
                      v-model="product.name"
                      @input="validationError.name = false"
                      ref="contentName"
                    />
                  </FormGroup>

                  <div class="col-full">
                    <ImageUploader
                      :title="$t('15_7_2_1_label_product_image')"
                      sub-category="product"
                      :sub-category-id="product.id"
                      :company-id="companyID"
                    />
                  </div>
              </div>

              <!--
              <div class="column">
                <div style="min-width: 50%; max-width: 50%">
                  <FormGroup
                    :label="$t('15_7_2_1_label_product_service_name')"
                    :required="true"
                    :validationError="validationError.name"
                  >
                    <input
                      type="text"
                      :placeholder="$t('15_7_2_1_placeholder_product_service_name')"
                      v-model="product.name"
                      @input="validationError.name = false"
                      ref="contentName"
                    />
                  </FormGroup>
                </div>
                <div class="col-full">
                  <CompanyLogo :title="$t('15_7_2_1_label_product_service_image')" />
                </div>
              </div>
              -->

              <div class="">
                <FormGroup
                  :label="$t('15_7_2_1_label_key_insight')"
                  :required="false"
                >
                  <input
                    type="text"
                    :placeholder="$t('15_7_2_1_placeholder_key_insight')"
                    v-model="product.keyInsights"
                  />
                </FormGroup>
                <Hint>{{ $t('15_7_2_1_hint_key_insight') }}</Hint>
              </div>

              <div class="column">
                <Dropdown
                  v-model="product.averagePrice"
                  :label="$t('15_7_2_1_label_average_price')"
                  :placeholder="$t('15_7_2_1_placeholder_average_price')"
                  :items="AveragePrice"
                />
              </div>

              <!--
              <div class="column">
                <Dropdown
                  v-model="product.personas"
                  :multiple="true"
                  :label="$t('15_7_2_1_label_personas')"
                  :placeholder="$t('15_7_2_1_placeholder_personas')"
                  :items="personas"
                />
                <ButtonAdd />
              </div>
              -->

              <!--
              <div class="column">
                <Dropdown
                  :multiple="true"
                  v-model="product.channels"
                  :label="$t('15_7_2_1_label_channels')"
                  :placeholder="$t('15_7_2_1_placeholder_channels')"
                  :items="channels"
                />
                <ButtonAdd />
              </div>
              -->
              <div class="column">
                <Dropdown
                  v-model="product.competitors"
                  :multiple="true"
                  :label="$t('15_7_2_1_label_competitors')"
                  :placeholder="$t('15_7_2_1_placeholder_competitors')"
                  :items="competitors"
                />
                <ButtonAdd @click="showModalAddCompetitor = true" />
              </div>

              <STDCFrameworksAndPhases
                :stdcs="product.stdcs"
                @createSTDC="showModalAddStdc = true"
                @deleteSTDC="deleteStdc"
                @editSTDC="editStdc"
              />

              <FormGroup v-if="product.leanCanvas" :label="$t('15_7_2_1_label_lean_canvas')" :required="false">
                <div class="product-lean-canvas">
                  <span>
                    {{ product.leanCanvas.name }}
                  </span>
                  <button @click="openLeanCanvas">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.99935 4.49935H3.99935C3.64573 4.49935 3.30659 4.63982 3.05654 4.88987C2.80649 5.13992 2.66602 5.47906 2.66602 5.83268V12.4993C2.66602 12.853 2.80649 13.1921 3.05654 13.4422C3.30659 13.6922 3.64573 13.8327 3.99935 13.8327H10.666C11.0196 13.8327 11.3588 13.6922 11.6088 13.4422C11.8589 13.1921 11.9993 12.853 11.9993 12.4993V8.49935M7.33268 9.16602L13.3327 3.16602M13.3327 3.16602H9.99935M13.3327 3.16602V6.49935" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
              </FormGroup>

              <div v-if="originalProduct.type && originalProduct.type.value" class="column">
                <Dropdown
                  v-model="product.type"
                  :label="$t('15_7_2_1_label_type')"
                  :placeholder="$t('15_7_2_1_placeholder_type')"
                  :items="computedProductTypes"
                />
              </div>

              <div v-if="originalProduct.markets" class="column">
                <FormGroup :label="$t('15_7_2_1_label_marker_location')" :required="false">
                  <input
                    type="text"
                    :placeholder="$t('15_7_2_1_placeholder_marker_location')"
                    v-model="product.markets"
                  />
                </FormGroup>
              </div>

              <div v-if="originalProduct.totalAddressableMarket" class="column">
                <FormGroup :label="$t('15_7_2_1_label_total_addressable_marker')" :required="false">
                  <input
                    type="text"
                    :placeholder="$t('15_7_2_1_placeholder_total_addressable_marker')"
                    v-model="product.totalAddressableMarket"
                    ref="contentName"
                  />
                </FormGroup>
              </div>

              <Accordion
                v-if="
                  originalProduct.type.value === null
                  || !originalProduct.markets
                  || !originalProduct.totalAddressableMarket
                "
                :title="$t('15_7_2_1_accordion_title')"
                :description="$t('15_7_2_1_accordion_description')"
              >
                <div v-if="!(originalProduct.type.value && originalProduct.type)" class="column">
                  <Dropdown
                    v-model="product.type"
                    :label="$t('15_7_2_1_label_type')"
                    :placeholder="$t('15_7_2_1_placeholder_type')"
                    :items="ProductType"
                  />
                </div>

                <div v-if="!originalProduct.markets" class="column">
                  <FormGroup :label="$t('15_7_2_1_label_marker_location')" :required="false">
                    <input
                      type="text"
                      :placeholder="$t('15_7_2_1_placeholder_marker_location')"
                      v-model="product.markets"
                    />
                  </FormGroup>
                </div>

                <div v-if="!originalProduct.totalAddressableMarket" class="column">
                  <FormGroup :label="$t('15_7_2_1_label_total_addressable_marker')" :required="false">
                    <input
                      type="text"
                      :placeholder="$t('15_7_2_1_placeholder_total_addressable_marker')"
                      v-model="product.totalAddressableMarket"
                      ref="contentName"
                    />
                  </FormGroup>
                </div>
              </Accordion>

            </div>
          </div>

          <div class="boxed boxed--full">
            <div class="box">
              <h2>{{  $t('15_7_2_1_other_info') }}</h2>

              <div class="column">
                <FormGroup :label="$t('15_7_2_1_label_description')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_7_2_1_placeholder_description')"
                      v-model="product.description"
                    ></textarea>
                </FormGroup>
              </div>

              <div class="column">
                <FormGroup :label="$t('15_7_2_1_label_argumantation_beneftis')" :required="false">
                  <Resources
                    :items="product.argumentsBenefits.links"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    :nameOnly="true"
                    transCreateNew="15_7_2_1_add_argumantation_beneftis"
                    @updateResources="updateArgumentsBenefitsList"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div class="column">
                <FormGroup :label="$t('15_7_2_1_label_unique_selling_proposition')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_7_2_1_placeholder_unique_selling_proposition')"
                      v-model="product.uniqueSellingProposition"
                      ref="contentName"
                    ></textarea>
                </FormGroup>
              </div>

              <div v-if="originalProduct.note" class="column">
                <FormGroup :label="$t('15_7_2_1_label_note')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_7_2_1_placeholder_note')"
                      v-model="product.note"
                    ></textarea>
                </FormGroup>
              </div>

              <div v-if="(
                (originalProduct.otherResources && originalProduct.otherResources.links.length !== 0)
                || (originalProduct.otherResources && originalProduct.otherResources.files.length !== 0))" class="column">
                <FormGroup
                  :label="$t('15_7_2_1_label_other_resources')"
                  :required="false"
                >
                  <h2 class="files-title">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    {{ $t('special_list_links_title') }}
                    ({{product.otherResources.links.length}})
                  </h2>
                  <Resources
                    :items="product.otherResources.links"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_7_2_1_add_other_resources"
                    @updateResources="updateOtherResourcesLinks"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                  <SourcesFileUploader
                    query-type="product"
                    file-type="product-resource"
                    :destinationId="this.$route.params.productId"
                    :attachments="product.otherResources.files"
                    @refresh="refetch"
                    type="edit"
                  ></SourcesFileUploader>
                </FormGroup>
              </div>

              <Accordion
                v-if="!originalProduct.note
                || (originalProduct.otherResources.links.length === 0 && originalProduct.otherResources.files.length === 0)
                "
                :title="$t('15_7_2_1_accordion_other_title')"
                :description="$t('15_7_2_1_accordion_other_description')"
              >
                <div v-if="!originalProduct.note" class="column">
                  <FormGroup :label="$t('15_7_2_1_label_note')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_7_2_1_placeholder_note')"
                      v-model="product.note"
                    ></textarea>
                  </FormGroup>
                </div>

                <div v-if="originalProduct.otherResources.links.length === 0 && originalProduct.otherResources.files.length === 0" class="column">
                  <FormGroup
                    :label="$t('15_7_2_1_label_other_resources')"
                    :required="false"
                  >
                    <h2 class="files-title">
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      {{ $t('special_list_links_title') }}
                      ({{product.otherResources.links.length}})
                    </h2>
                    <Resources
                      :items="product.otherResources.links"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      transCreateNew="15_7_2_1_add_other_resources"
                      @updateResources="updateOtherResourcesLinks"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                    <SourcesFileUploader
                      query-type="product"
                      file-type="product-resource"
                      :destinationId="this.$route.params.productId"
                      :attachments="product.otherResources.files"
                      @refresh="refetch"
                      type="edit"
                    ></SourcesFileUploader>
                  </FormGroup>
                </div>
              </Accordion>

            </div>
          </div>

        </div>
      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <ProductsModalDelete
        @close="showModalDelete = false"
        @delete="deleteProduct"
      />
    </Modal>

    <ModalConfirmClose
      v-if="showLeaveModal"
      @close="handleModalLeaveClose"
      @confirm="handleModalLeaveConfirm"
    />

    <Modal
      type="source"
      v-if="showModalAddCompetitor"
      @closeModal="showModalAddCompetitor = false"
    >
      <CompetitorModalAdd
        :title="title"
        @close="showModalAddCompetitor = false"
        @create="createCompetitor"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalAddStdc"
      @closeModal="showModalAddStdc = false"
    >
      <StdcModalAdd
        :personas="personas"
        :products="products"
        :stdcToEdit="null"
        @close="showModalAddStdc = false"
        @create="createStdc"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalEditStdc"
      @closeModal="showModalEditStdc = false"
    >
      <StdcModalAdd
        :stdcToEdit="stdcToEdit"
        :selectedPersonas="stdcSelectedPersonas"
        :selectedProducts="stdcSelectedProducts"
        :personas="personas"
        :products="products"
        @close="showModalEditStdc = false"
        @update="updateStdc"
      />
    </Modal>
  </ContentWrapper>
</template>

<script>
import Dropdown from "@/components/dropdown/Dropdown.vue";
import Button from "@/components/button/Button.vue";
import FormGroup from "@/components/FormGroup.vue";
import Resources from "@/components/design/Resources.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import Avatar from "@/components/Avatar.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import Hint from "@/components/sources/components/hint/Hint.vue";
import ButtonAdd from "@/components/buttonCopy/ButtonAdd.vue";
import STDCFrameworksAndPhases
  from "@/components/sources/components/stdcFrameworksAndPhases/STDCFrameworksAndPhases.vue";
import ProductsModalDelete from "@/components/sources/components/products/ProductsModalDelete.vue";
import Modal from "@/components/Modal.vue";
import {arrayClone, obj_clone} from "../../../services/helpers/clone";

import COMPANY_PRODUCT from '@/graphql/CompanyProduct.gql';
import COMPANY_PRODUCT_DETAIL from '@/graphql/CompanyProductDetail.gql';
import COMPANY_PRODUCT_FILES from '@/graphql/CompanyProductFiles.gql';
import PRODUCT_UPDATE from '@/graphql/ProductUpdate.gql';
import PRODUCT_DELETE from '@/graphql/ProductDelete.gql';
import SourcesFileUploader from "../components/sourcesFileUploader/SourcesFileUploader.vue";
import {DateService as DataService} from "../../../services/DateService";
import ModalConfirmClose from "../../ModalConfirmClose.vue";
import CompetitorModalAdd from "@/components/sources/components/competitors/CompetitorModalAdd.vue";
import COMPETITOR_CREATE from "@/graphql/CompetitorCreate.gql";
import StdcModalAdd from "@/components/sources/components/stdc/StdcModalAdd.vue";
import STDC_CREATE from "@/graphql/StdcCreate.gql";
import STDC_DELETE from "@/graphql/StdcDelete.gql";
import utils from "@/utils/utils";
import STDC_UPDATE from "@/graphql/StdcUpdate.gql";
import ImageUploader from "@/components/ImageUploader.vue";

export default {
  components: {
    ImageUploader,
    StdcModalAdd,
    CompetitorModalAdd,
    ModalConfirmClose,
    SourcesFileUploader,
    Modal,
    ProductsModalDelete,
    STDCFrameworksAndPhases,
    ButtonAdd,
    Hint,
    SlickMenu,
    ContentWrapper,
    CompanyLogo,
    Accordion,
    Resources,
    FormGroup,
    Button,
    Avatar,
    Dropdown
  },
  apollo: {
    companies: {
      query: COMPANY_PRODUCT_DETAIL,
      variables() {
        return {
          id: this.companyID,
          productId: this.$route.params.productId
        }
      },
      update: function(data) {
        try {
          const company = data.companies.find((item) => item.id === this.companyID);

          this.competitors = arrayClone(company.competitors).map((competitor) => {
            return {
              value: competitor.id,
              name: competitor.name
            }
          })

          this.products = company.products;
          this.personas = company.personas;
          const product = company.products.find((item) => item.id === this.$route.params.productId);
          this.setProduct(product);

        } catch(e) {

        }
      }
    },
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    companyID() {
      return this.$route.params.id;
    },
    competitorIds() {
      if (Array.isArray(this.product.competitors)) {
        return this.product.competitors.map((competitor) => competitor.value);
      }
      return [];
    },
    computedProductTypes() {
      return this.ProductType.map((item) => {
        return {
          value: item.value,
          name: this.$t(item.name),
        }
      })
    },
    dataHasChanged() {
      return JSON.stringify(this.product) !== JSON.stringify(this.originalProduct);
    }
  },
  data() {
    return {
      stdcToEdit: null,
      stdcSelectedPersonas: [],
      stdcSelectedProducts: [],
      showModalAddStdc: false,
      showModalEditStdc: false,
      showModalAddCompetitor: false,
      competitors: [],
      personas: [],
      products: [],
      channels: [],
      product: {
        stdcs: [],
        argumentsBenefits: {
          links: []
        },
        averagePrice: 0,
        companyId: this.companyID,
        competitors: [],
        description: '',
        channels: [],
        id: this.$route.params.productId,
        keyInsights: '',
        logo: null,
        markets: '',
        name: '',
        note: '',
        otherResources: {
          files: [],
          links: []
        },
        personas: [],
        totalAddressableMarket: '',
        type: {
          value: null
        },
        uniqueSellingProposition: ''
      },
      originalProduct: {
        argumentsBenefits: {
          links: []
        },
        averagePrice: 0,
        companyId: this.companyID,
        competitors: [],
        description: '',
        channels: [],
        id: this.$route.params.productId,
        keyInsights: '',
        logo: null,
        markets: '',
        name: '',
        note: '',
        otherResources: {
          files: [],
          links: []
        },
        personas: [],
        totalAddressableMarket: '',
        type: {
          value: null
        },
        uniqueSellingProposition: ''
      },

      resources: [],
      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,

      description: '',
      title: '',
      validationError: {
        description: false,
        title: false,
      },
      showModalDelete: false,
      isUpdating: false,

      ProductType: DataService.getProductTypes(),
      AveragePrice: DataService.getAveragePrices(),
      showLeaveModal: false,
      nextRoute: null,
    }
  },
  methods: {
    updateStdc(stdc) {
      this.showModalEditStdc = false;

      stdc = obj_clone(utils.removeTypename(stdc));
      delete stdc.companyId;

      if (stdc.personas && stdc.personas.length && !stdc.personaIds) {
        stdc.personaIds = stdc.personas.map((item) => item.value);
      }
      delete stdc.personas;
      delete stdc.persona;

      if (stdc.products && stdc.products.length && !stdc.productIds) {
        stdc.productIds = stdc.products.map((item) => item.value);
      }
      delete stdc.products;
      delete stdc.product;

      const see = stdc.phases.find((item) => item.phaseType === 'SEE');
      see.phaseData.channelIds = [];
      if (see.phaseData.channels && see.phaseData.channels.length) {
        see.phaseData.channelIds = [];
        see.phaseData.channelIds = see.phaseData.channels.map((item) => item.value);
      }
      delete see.phaseData.channels

      const think = stdc.phases.find((item) => item.phaseType === 'THINK');
      think.phaseData.channelIds = [];
      if (think.phaseData.channels && think.phaseData.channels.length) {
        think.phaseData.channelIds = think.phaseData.channels.map((item) => item.value);
      }
      delete think.phaseData.channels

      const _do = stdc.phases.find((item) => item.phaseType === 'DO');
      _do.phaseData.channelIds = [];
      if (_do.phaseData.channels && _do.phaseData.channels.length) {
        _do.phaseData.channelIds = _do.phaseData.channels.map((item) => item.value);
      }
      delete _do.phaseData.channels

      const care = stdc.phases.find((item) => item.phaseType === 'CARE');
      care.phaseData.channelIds = [];
      if (care.phaseData.channels && care.phaseData.channels.length) {
        care.phaseData.channelIds = care.phaseData.channels.map((item) => item.value);
      }
      delete care.phaseData.channels

      if (
        stdc.otherResources
        && stdc.otherResources.links
        && stdc.otherResources.links.length > 0
      ) {
        stdc.otherResources = stdc.otherResources.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      } else {
        stdc.otherResources = [];
      }

      if (JSON.stringify(stdc.description) === "{}" || stdc.description === null) {
        stdc.description = null;
      }

      if (!stdc.productId) {
        stdc.productId = null;
      }

      if (!stdc.personaId) {
        stdc.personaId = null;
      }

      console.log("stdc to save: ", stdc);

      try {
        this.$apollo.mutate({
          mutation: STDC_UPDATE,
          variables: {
            stdc
          },
          update: (store, data) => {
            this.stdc = data.data.updateSTDC;
            this.originalStdc = arrayClone(this.stdc);
          }
        }).then(() => {

          this.$apollo.queries.companies.refetch();

          this.$notify({
            title: this.$t('15_8_toaster_success_title_saved'),
            text: this.$t('15_8_toaster_success_description_saved'),
            type: 'success',
            duration: 5000,
          });

          this.savingStdc = false;
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });

        this.savingStdc = false;
      }
    },
    editStdc(stdc) {
      console.log("stdc to edit: ", stdc);
      this.showModalEditStdc = true;
      this.stdcToEdit = stdc;
    },
    deleteStdc(id) {
      this.showModalDelete = false

      try {
        this.$apollo.mutate({
          mutation: STDC_DELETE,
          variables: {
            id
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_8_toaster_success_title_deleted'),
            text: this.$t('15_8_toaster_success_description_deleted'),
            type: 'success',
            duration: 5000,
          })
        })
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    createStdc(stdc) {
      this.showModalAddStdc = false;
      stdc.companyId = this.companyID;

      try {
        this.$apollo.mutate({
          mutation: STDC_CREATE,
          variables: {
            stdc: stdc
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();

            this.$notify({
              title: this.$t('15_8_toaster_success_title_create'),
              text: this.$t('15_8_toaster_success_description_created'),
              type: 'success',
              duration: 5000,
            })
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    openLeanCanvas() {
      try {
        this.$router.push({ path: `/company/${this.companyID}/lean-canvas-detail/${this.product.leanCanvas.id}` });
      } catch(e) {}
    },
    createCompetitor(competitor) {
      this.showModalAddCompetitor = false;

      competitor.companyId = this.companyID;

      const _competitor = {};
      _competitor.companyId = this.companyID;
      _competitor.name = competitor.name;
      _competitor.keyInsights = competitor.keyInsights;

      try {
        this.$apollo.mutate({
          mutation: COMPETITOR_CREATE,
          variables: {
            competitor: _competitor
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();

            this.$notify({
              title: this.$t('15_10_1_3_toaster_success_title_create'),
              text: this.$t('15_10_1_3_toaster_success_description_create'),
              type: 'success',
              duration: 5000,
            })
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_10_1_3_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    handleModalLeaveClose() {
      this.showLeaveModal = false;
    },
    handleModalLeaveConfirm() {
      this.originalProduct = obj_clone(this.product);

      this.showLeaveModal = false;
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      }
    },
    deleteProduct() {
      this.isUpdating = true;

      try {
        this.$apollo.mutate({
          mutation: PRODUCT_DELETE,
          variables: {
            id: this.companyID,
            productId: this.$route.params.productId
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_7_2_1_toaster_success_title_deleted'),
            text: this.$t('15_7_2_1_toaster_success_description_deleted'),
            type: 'success',
            duration: 5000,
          });

          this.$router.push({ path: `/company/${this.companyID}/products-and-services` });
          this.showModalDelete = false;
          this.isUpdating = false;
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_7_2_1_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });

        this.showModalDelete = false;
        this.isUpdating = false;
      }
    },
    handleGoBack() {
      this.$router.go(-1);
    },
    updateOtherResourcesLinks(links) {
      this.product.otherResources.links = links;
    },
    updateArgumentsBenefitsList(links) {
      this.product.argumentsBenefits.links = links;
    },
    async refetch() {
      const response = await this.$apollo.query({
        query: COMPANY_PRODUCT_FILES,
        variables: {
          id: this.companyID,
        }
      });

      try {
        const company = response.data.companies.find((item) => item.id === this.companyID);
        const product = company.products.find((item) => item.id === this.$route.params.productId);

        if (product.otherResources) {
          if (product.otherResources.files) {
            this.product.otherResources.files = arrayClone(product.otherResources.files);
          }
        }
      } catch(e) {

      }
    },
    setProduct(product) {
      this.product.id = product.id;
      this.product.ratio = product.ratio;
      this.product.description = product.description;
      this.product.keyInsights = product.keyInsights;
      this.product.markets = product.markets;
      this.product.name = product.name;
      this.product.note = product.note;
      this.product.totalAddressableMarket = product.totalAddressableMarket;
      this.product.uniqueSellingProposition = product.uniqueSellingProposition;
      this.product.leanCanvas = product.leanCanvas;
      this.product.stdcs = product.stdcs;

      if (product.otherResources) {
        if (product.otherResources.links) {
          this.product.otherResources.links = arrayClone(product.otherResources.links);
        }

        if (product.otherResources.files) {
          this.product.otherResources.files = arrayClone(product.otherResources.files);
        }
      }

      if (product.argumentsBenefits) {
        this.product.argumentsBenefits.links = product.argumentsBenefits.map((item) => {
          return {
            name: item,
            link: ''
          }
        });
      }

      if (product.competitors) {
        this.product.competitors = product.competitors.map((item) => {
          return {
            name: item.name,
            value: item.id
          }
        });
      } else {
        this.product.competitors = [];
      }

      /*
      if (product.personas) {
        this.product.personas = product.personas.map((item) => {
          return {
            name: item.name,
            value: item.id
          }
        });
      }

      if (product.channels) {
        this.product.channels = product.channels.map((item) => {
          return {
            name: item.name,
            value: item.id
          }
        });
      }
       */

      if (product.averagePrice) {
        this.product.averagePrice = this.AveragePrice.find((item) => item.value === product.averagePrice.toString());
      }

      if(product.type) {
        this.product.type = this.computedProductTypes.find((item) => item.value === product.type);
      }

      this.originalProduct = obj_clone(this.product);
    },
    updateProduct() {
      const product = {};
      product.id = this.product.id;
      product.ratio = this.product.ratio;
      product.description = this.product.description;
      product.keyInsights = this.product.keyInsights;
      product.markets = this.product.markets;
      product.name = this.product.name;
      product.note = this.product.note;
      product.totalAddressableMarket = this.product.totalAddressableMarket;
      product.uniqueSellingProposition = this.product.uniqueSellingProposition;

      if (
        this.product.otherResources
        && this.product.otherResources.links
        && this.product.otherResources.links.length > 0
      ) {
        product.otherResources = this.product.otherResources.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      }

      // argumentsBenefits
      if (
        this.product.argumentsBenefits
        && this.product.argumentsBenefits.links
        && this.product.argumentsBenefits.links.length > 0
      ) {
        product.argumentsBenefits = this.product.argumentsBenefits.links.map((item) => item.name);
      }

      // averagePrice
      if (this.product.averagePrice) {
        product.averagePrice = parseInt(this.product.averagePrice.value);
      }

      // competitors

      // channels
      // product.channelIds = this.channelIds;

      // personas
      // product.personaIds = this.personaIds;

      // Competitors
      product.competitorIds = this.competitorIds;

      // logo

      // type
      if(this.product.type && this.product.type.value) {
        product.type = this.product.type.value;
      }

      this.isUpdating = true;

      console.log("product: ", product);

      try {
        this.$apollo.mutate({
          mutation: PRODUCT_UPDATE,
          variables: {
            product
          },
          update: (store, data) => {
            this.originalProduct = arrayClone(this.product);
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_7_2_1_toaster_success_title_saved'),
            text: this.$t('15_7_2_1_toaster_success_description_saved'),
            type: 'success',
            duration: 5000,
          });

          this.isUpdating = false;
        }).catch(() => {
          this.$notify({
            title: this.$t('15_7_2_1_toaster_error_title_saved'),
            text: this.$t('global_toaster_error_description'),
            type: 'error',
            duration: 5000,
          });

          this.isUpdating = false;
        });
      } catch(e) {
        this.isUpdating = false;

        this.$notify({
          title: this.$t('15_7_2_1_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });
      }
    },
    confirmLeave(to, from, next) {
      if (this.dataHasChanged) {
        // Show the modal if there are unsaved changes
        this.showLeaveModal = true;
        this.nextRoute = to; // Store the route the user wants to go to
        next(false); // Prevent immediate navigation
      } else {
        // No changes, allow navigation
        next();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.confirmLeave(to, from, next);
  },
}
</script>

<style scoped>
.product-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-detail-header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.product-detail-header-back {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.product-detail-header-title {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 800;
  line-height: 33.6px;
  text-align: left;
}

.product-detail-header-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.col-full {
  width: 100%;
}

.product-lean-canvas {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 16px;
  line-height: 1;

  span {
    font-family: Nunito;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0;
    color: #17252A;
    padding-top: 4px;
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #e7e9e9;
    color: #17252a;
    cursor: pointer;
    line-height: 1;

    &:hover {
      background: #d1d4d4;
      color: #17252a;
    }
  }
}
</style>
