<template>
  <div class="channel-rating">
    <div class="channel-rating-header">
      <div class="channel-rating-header-title">
        {{ $t('Top 5 Rated Content') }}
      </div>
    </div>

    <div class="channel-rating-body">
      <ChannelRatingItem v-for="(rating, index) in ratings" :key="index" :rating="rating" />
    </div>
  </div>
</template>

<script>
import ChannelRatingItem from "@/components/sources/components/channels/ChannelRatingItem.vue";

export default {
  components: {
    ChannelRatingItem
  },
  props: ['ratings'],
}
</script>

<style class="scoped">
.channel-rating {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  background: #E7E9E9;
  padding: 16px;
  margin-top: 52px;
  border-radius: 4px;
}

.channel-rating-header-title {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  text-align: left;
  color: #17252A;
}

.channel-rating-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}
</style>
