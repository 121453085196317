import { DateTime } from 'luxon'

function timeZoneToUTC(date, timeZone) {
  const overrideZone = DateTime.fromISO(new Date(date).toISOString()).setZone(
    timeZone,
    { keepLocalTime: true }
  )

  return `${overrideZone.toUTC().toFormat('yyyy-MM-dd')}T${overrideZone
    .toUTC()
    .toFormat('HH:mm:ss')}Z`
}

function formatDate(dateToFormat) {
  const date = new Date(dateToFormat)

  let month = date.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }

  let day = date.getDate()
  if (day < 10) {
    day = '0' + day
  }

  let hours = date.getHours()
  if (hours < 10) {
    hours = '0' + hours
  }

  let minutes = date.getMinutes()
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  let seconds = date.getSeconds()
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  return `${date.getFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
}

function dateToUTC(date) {
  let year = date.getUTCFullYear()

  let month = date.getUTCMonth() + 1
  if (month < 10) {
    month = '0' + month
  }

  let day = date.getUTCDate()
  if (day < 10) {
    day = '0' + day
  }

  let hours = date.getUTCHours()
  if (hours < 10) {
    hours = '0' + hours
  }

  let minutes = date.getUTCMinutes()
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  let seconds = date.getUTCSeconds()
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  const utc_date =
    year +
    '-' +
    month +
    '-' +
    day +
    'T' +
    hours +
    ':' +
    minutes +
    ':' +
    seconds +
    'Z'

  return utc_date
}

function dateToUTCEnd(date) {
  let year = date.getFullYear()

  let month = date.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }

  let day = date.getDate()
  if (day < 10) {
    day = '0' + day
  }

  return year + '-' + month + '-' + day + 'T23:59:59Z'
}

function dateToUTCStart(date) {
  let year = date.getFullYear()

  let month = date.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }

  let day = date.getDate()
  if (day < 10) {
    day = '0' + day
  }

  return year + '-' + month + '-' + day + 'T00:00:00Z'
}

function deltaDate(input, days, months, years) {
  return new Date(
    input.getFullYear() + years,
    input.getMonth() + months,
    Math.min(
      input.getDate() + days,
      new Date(
        input.getFullYear() + years,
        input.getMonth() + months + 1,
        0
      ).getDate()
    )
  )
}

function getDateToTimezone(date, timeZone) {
  if (typeof date === 'string') {
    return new Date(date).toLocaleString('en-US', {
      timeZone,
    })
  }

  return date.toLocaleString('en-US', {
    timeZone,
  })
}

function getListOfAvailableTimezones() {
  var arrayInTimeZones = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg',
  ]

  arrayInTimeZones.sort()

  const arr = []
  arrayInTimeZones.forEach((item) => {
    arr.push({
      label: item.replaceAll('_', ' '),
      value: item,
    })
  })

  return arr
}

function getMonthName(monthNumber) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return months[monthNumber]
}

//@ Return date from string in current timezone
function dateFromString(stringDate) {
  const items = stringDate.split('T')
  const date = items[0].split('-')
  const time = items[1].replace('Z', '').split(':')

  return new Date(date[0], date[1] - 1, date[2], time[0], time[1], time[2])
}

function getDateFromString(stringDate) {
  const items = stringDate.split('T')
  const date = items[0].split('-')
  const time = items[1].replace('Z', '').split(':')

  return [...date, ...time]
}

function lxnGetEODFromDate(date, zone) {
  return DateTime.fromISO(date.toISOString())
    .setZone(zone)
    .set({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: 23,
      minute: 59,
      seconds: 59,
    })
    .toUTC()
    .toISO()
}

function lxnGetEODFromDateUTC(date, zone) {
  const dateParts = getDateFromString(date)

  return DateTime.fromISO(date)
    .toUTC()
    .set({
      year: dateParts[0],
      month: dateParts[1],
      day: dateParts[2],
      hour: 11,
      minute: 59,
      seconds: 59,
    })
    .setZone(zone)
    .set({
      hour: 23,
      minute: 59,
      seconds: 59,
    })
    .toUTC()
    .toISO()
}

function checkIfDateRangeIsMonth(dateFrom, dateTo) {
  const _dateFrom = DateTime.fromISO(dateFrom.toISOString()).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  const _dateTo = DateTime.fromISO(dateTo.toISOString()).set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0,
  })
  const firstDayOFMonth = _dateFrom.startOf('month').set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  const lastDayOFMonth = _dateTo.endOf('month').set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0,
  })

  return (
    _dateFrom.toMillis() === firstDayOFMonth.toMillis() &&
    _dateTo.toMillis() === lastDayOFMonth.toMillis()
  )
}

function getMonthsDuration(dateFrom, dateTo) {
  const _dateFrom = DateTime.fromISO(dateFrom.toISOString())
  const _dateTo = DateTime.fromISO(dateTo.toISOString())
  const diff = _dateFrom.diff(_dateTo, ['months'])
  const diffObject = diff.toObject()
  return Math.ceil(Math.abs(diffObject.months))
}

function getMonthShiftStartDate(date, duration) {
  return new Date(
    DateTime.fromISO(date.toISOString()).plus({ months: duration }).toISO()
  )
}

function getMonthShiftEndDate(date, duration) {
  return new Date(
    DateTime.fromISO(date.toISOString())
      .startOf('month')
      .plus({ months: duration })
      .endOf('month')
      .toISO()
  )
}

function getProductTypes() {
  return [
    { value: 'PHYSICAL_PRODUCT', name: 'list_ProductType_PHYSICAL_PRODUCT'},
    { value: 'DIGITAL_PRODUCT', name: 'list_ProductType_DIGITAL_PRODUCT'},
    { value: 'SERVICE', name: 'list_ProductType_SERVICE'},
    { value: 'SUPPORT_MAINTENANCE', name: 'list_ProductType_SUPPORT_MAINTENANCE'},
    { value: 'IMPLEMENTATION_INTEGRATION', name: 'list_ProductType_IMPLEMENTATION_INTEGRATION'},
    { value: 'PARTS_CONSUMABLES', name: 'list_ProductType_PARTS_CONSUMABLES'},
    { value: 'EDUCATION_TRAINING', name: 'list_ProductType_EDUCATION_TRAINING'},
    { value: 'SUBSCRIPTION_MEMBERSHIP', name: 'list_ProductType_SUBSCRIPTION_MEMBERSHIP'},
    { value: 'FINANCES_TRANSACTIONS', name: 'list_ProductType_FINANCES_TRANSACTIONS'},
    { value: 'LOGISTICS_OPERATIONS', name: 'list_ProductType_LOGISTICS_OPERATIONS'},
    { value: 'MARKETPLACE', name: 'list_ProductType_MARKETPLACE'},
    { value: 'DATA_ANALYTICS', name: 'list_ProductType_DATA_ANALYTICS'},
    { value: 'COMMUNITY_EVENTS', name: 'list_ProductType_COMMUNITY_EVENTS'},
    { value: 'OTHER', name: 'list_ProductType_OTHER'}
  ]
}

function getProductType(requestedType) {
  const types = getProductTypes();
  const type = types.find((item) => item.value === requestedType);
  console.log("type", type);
  return type.name ?? '';
}

function getAveragePrices() {
  return [
    { value: '10', name: '< €10'},
    { value: '50', name: '€10 - €50'},
    { value: '200', name: '€51 - €200'},
    { value: '500', name: '€201 - €500'},
    { value: '1000', name: '€501 - €1,000'},
    { value: '5000', name: '€1,001 - €5,000'},
    { value: '10000', name: '€5,001 - €10,000'},
    { value: '50000', name: '€10,001 - €50,000'},
    { value: '50001', name: '> €50,000'},
  ];
}

function getGender() {
  return [
    {name: `Male`, value: 'male'},
    {name: `Female`, value: 'female'},
    {name: `Non-binary / Third gender`, value: 'non_binary_third_gender'},
    {name: `Prefer not to say`, value: 'prefer_not_to_say'},
    {name: `Other`, value: 'other'}
  ];
}

function getAge() {
  return [
    {name: `Not relevant`, value: '0_0'},
    {name: `0 - 12`, value: '0_12'},
    {name: `13 - 17`, value: '13_17'},
    {name: `18 - 24`, value: '18_24'},
    {name: `25 - 34`, value: '25_34'},
    {name: `35 - 44`, value: '35_44'},
    {name: `45 - 54`, value: '45_54'},
    {name: `55 - 64`, value: '55_64'},
    {name: `65+`, value: '65_65'}
  ];
}

function getEducation() {
  return [
    {name: `Elementary`, value: 'elementary'},
    {name: `University`, value: 'university'},
    {name: `No Formal Education`, value: 'no_formal_education'},
    {name: `Primary Education`, value: 'primary_education'},
    {name: `Secondary Education (High School or Equivalent)`, value: 'secondary_education'},
    {name: `Vocational/Technical Education`, value: 'vocational_technical_education'},
    {name: `Some College/University (No Degree)`, value: 'some_college_university'},
    {name: `Bachelor’s Degree`, value: 'bachelors_degree'},
    {name: `Master’s Degree`, value: 'masters_degree'},
    {name: `Doctorate (PhD) or Professional Degree`, value: 'doctorate_professional_degree'}
  ];
}

function getPublicationFrequency() {
  return [
    {name: 'Occasionally', value: 'occasionally'},
    {name: 'Biweekly', value: 'biweekly'},
    {name: 'Weekly', value: 'weekly'},
    {name: 'Multiple Times per Week', value: 'multiple_times_per_week'},
    {name: 'Multiple Times per Day', value: 'multiple_times_per_day'}
  ];
}

export const DateService = {
  lxnGetEODFromDate,
  lxnGetEODFromDateUTC,
  dateToUTC,
  deltaDate,
  getDateToTimezone,
  getListOfAvailableTimezones,
  getMonthName,
  timeZoneToUTC,
  dateToUTCStart,
  dateToUTCEnd,
  formatDate,
  dateFromString,
  getDateFromString,
  checkIfDateRangeIsMonth,
  getMonthsDuration,
  getMonthShiftStartDate,
  getMonthShiftEndDate,
  getProductTypes,
  getProductType,
  getAveragePrices,
  getGender,
  getEducation,
  getAge,
  getPublicationFrequency
}
