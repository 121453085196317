<template>
  <div class="channel-rating-item">
    <div class="channel-rating-item-date">
      {{ rating.date }}
    </div>
    <div class="channel-rating-item-title">
      {{ rating.name }}
    </div>
    <div class="channel-rating-item-footer">
      <div class="channel-rating-item-rating">
        <star-rating
          :star-size="14"
          :increment="0.5"
          v-model="rating.review.value"
          aria-readonly="true"
        ></star-rating>
      </div>
      <div class="channel-rating-item-user">
        {{ rating.user }}
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  components: {
    StarRating
  },
  props: ['rating'],
}
</script>

<style scoped>
.channel-rating-item {
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 2px 2px 1px #0000000D;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #fff;
}
.channel-rating-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel-rating-item-date {
  color: #AEB3B5;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-align: left;
}

.channel-rating-item-title {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  text-align: left;
  color: #17252A;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 16.8px;
  height: 36px;
}

.channel-rating-item-user {
  width: 22px;
  min-width: 22px;
  max-width: 22px;
  border-radius: 50%;
  height: 22px;
  align-items: center;
  justify-content: space-between;
  background-color: #E7E9E9;
}
</style>
