function removeTypename(obj) {
  if (Array.isArray(obj)) {
    return obj.map(item => removeTypename(item));
  } else if (typeof obj === 'object') {
    const newObj = {};
    for (let key in obj) {
      if (key !== '__typename') {
        newObj[key] = removeTypename(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
}

function getFlagEmoji(countryCode) {
  return countryCode
    .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397));
}

const translateStdc = (item) => {
  if(item === 'SEE') {
    return 'See';
  }

  if(item === 'THINK') {
    return 'Think';
  }

  if(item === 'DO') {
    return 'Do';
  }

  if(item === 'CARE') {
    return 'Care';
  }

  return item;
}

const getProductsFromStdc = (data) => {
  const uniqueProducts = new Set();

  data.forEach(item => {
    uniqueProducts.add(item.product);
  });

  return Array.from(uniqueProducts);
}

const getPersonasFromStdc = (data) => {
  const uniquePersonas = new Set();

  data.forEach(item => {
    uniquePersonas.add(item.persona);
  });

  return Array.from(uniquePersonas);
}

const getChannelsFromStdc = (data) => {
  const uniqueChannels = new Set();

  data.forEach(item => {
    item.phases.forEach(phaseEntry => {
      const channels = phaseEntry.phaseData.channels;
      if (channels && Array.isArray(channels)) {
        channels.forEach(channel => uniqueChannels.add(channel));
      }
    });
  });

  return Array.from(uniqueChannels);
}

module.exports = {
  getFlagEmoji,
  removeTypename,
  translateStdc,
  getProductsFromStdc,
  getPersonasFromStdc,
  getChannelsFromStdc
}
