<template>
  <ContentWrapper>
    <NoPersonas v-if="!list.length" @add="handleAdd" />

    <div v-if="list.length" class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_6_1_3_title') }}</h1>

        <div class="submenu">
          <div class="submenu-count">
            <div class="submenu-count-label">{{ $t('15_6_1_3_label_items_count') }}:</div> {{ list.length }}
          </div>
          <div class="submenu-items">
            <Button size="nav" type="secondary" @click="showModalEditImportances = true"><IcoChartPie /> {{ $t('15_6_1_3_btn_edit_importances') }}</Button>
            <Button @click="handleAdd" size="nav" type="primary"><IcoPlus /> {{ $t('15_6_1_3_btn_add_persona') }}</Button>
          </div>
        </div>

        <div class="items">
          <PersonaCard
            v-for="(item, index) in list"
            :item="item"
            :key="item.id"
          />
        </div>

      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalEditImportances"
      @closeModal="showModalEditImportances = false"
    >
      <ModalEditImportances
        @close="showModalEditImportances = false"
        @update="updateImportances"
        v-model="list"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalAdd"
      @closeModal="showModalAdd = false"
    >
      <PersonasModalAdd
        @close="showModalAdd = false"
        @create="createPersona"
      />
    </Modal>

  </ContentWrapper>
</template>

<script>
import NoPersonas from "@/components/sources/components/personas/NoPersonas.vue";
import PersonaCard from "@/components/sources/components/personas/PersonaCard.vue";
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import IcoChartPie from "@/components/svg/IcoChartPie.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import Modal from "@/components/Modal.vue";
import ModalEditImportances from "@/components/sources/components/ModalEditImportances.vue";
import PersonasModalAdd from "@/components/sources/components/personas/PersonasModalAdd.vue";
import PERSONA_CREATE from "@/graphql/PersonaCreate.gql";
import PERSONA_UPDATE_RATIO from "@/graphql/PersonaUpdateRatio.gql";
import COMPANY_PERSONA from '@/graphql/CompanyPersona.gql';
import ProductCard from "@/components/sources/components/products/ProductCard.vue";
import {obj_clone} from "@/services/helpers/clone";
import ModalConfirmClose from "@/components/ModalConfirmClose.vue";

export default {
  components: {
    ModalConfirmClose,
    ProductCard,
    PersonasModalAdd,
    ModalEditImportances,
    Modal,
    ContentWrapper,
    IcoChartPie,
    PersonaCard,
    NoPersonas,
    Button,
    IcoPlus
  },
  apollo: {
    companies: {
      query: COMPANY_PERSONA,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        console.log("data: ", data);
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];

        try {
          if (company && Array.isArray(company.personas)) {
            this.list = company.personas;
          }
        } catch(e) {
          this.list = [];
        }
      }
    },
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      showList: false,
      showModalEditImportances: false,
      showModalAdd: false,
      list: [],
    }
  },
  methods: {
    handleAdd() {
      this.showList = true;
      this.showModalAdd = true;
    },
    createPersona(persona) {
      console.log("persona: ", persona);
      let _persona = {};

      _persona.name = persona.name;

      if(persona.keyInsights) {
        _persona.keyInsights = persona.keyInsights;
      }

      if (persona.gender.value) {
        _persona.gender = persona.gender.value;
      }

      if (persona.education.value) {
        _persona.education = persona.education.value;
      }
      _persona.companyId = this.companyID;

      if (persona.age && persona.age.value) {
        const values = persona.age.value.split("_");
        _persona.age = {
          max: parseInt(values[1]),
          min: parseInt(values[0])
        }
      }

      try {
        this.$apollo.mutate({
          mutation: PERSONA_CREATE,
          variables: {
            persona: _persona
          },
          update: (store, data) => {
            this.showModalAdd = false;
            this.$apollo.queries.companies.refetch();
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateImportances(importances) {
      try {
        this.$apollo.mutate({
          mutation: PERSONA_UPDATE_RATIO,
          variables: {
            ratios: importances.map((item) => {
              return {
                ratio: item.ratio,
                id: item.id
              }
            })
          },
          update: (store, data) => {
            this.showModalEditImportances = false;
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
}
</script>

<style scoped>
.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.submenu {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.submenu-count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  gap: 4px;
}

.submenu-count-label {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #AEB3B5;
}

.submenu-items {
  display: flex;
  gap: 16px;
}
</style>
