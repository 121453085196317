<template>
  <ContentWrapper :disable-top-offset="true">

    <SlickMenu :title="persona.name" >
      <Button @click="showModalDelete = true" size="nav" type="delete">
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33398 4.83333H14.6673M6.33398 8.16667V13.1667M9.66732 8.16667V13.1667M2.16732 4.83333L3.00065 14.8333C3.00065 15.2754 3.17625 15.6993 3.48881 16.0118C3.80137 16.3244 4.22529 16.5 4.66732 16.5H11.334C11.776 16.5 12.1999 16.3244 12.5125 16.0118C12.8251 15.6993 13.0007 15.2754 13.0007 14.8333L13.834 4.83333M5.50065 4.83333V2.33333C5.50065 2.11232 5.58845 1.90036 5.74473 1.74408C5.90101 1.5878 6.11297 1.5 6.33398 1.5H9.66732C9.88833 1.5 10.1003 1.5878 10.2566 1.74408C10.4129 1.90036 10.5007 2.11232 10.5007 2.33333V4.83333" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_6_2_1_btn_delete') }}
      </Button>
      <!--
      <Button size="nav" type="secondary">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.67559 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.67559 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333M14.1667 7.5V4.16667C14.1667 3.72464 13.9911 3.30072 13.6785 2.98816C13.366 2.67559 12.942 2.5 12.5 2.5H7.5C7.05797 2.5 6.63405 2.67559 6.32149 2.98816C6.00893 3.30072 5.83333 3.72464 5.83333 4.16667V7.5M5.83333 12.5C5.83333 12.058 6.00893 11.634 6.32149 11.3215C6.63405 11.0089 7.05797 10.8333 7.5 10.8333H12.5C12.942 10.8333 13.366 11.0089 13.6785 11.3215C13.9911 11.634 14.1667 12.058 14.1667 12.5V15.8333C14.1667 16.2754 13.9911 16.6993 13.6785 17.0118C13.366 17.3244 12.942 17.5 12.5 17.5H7.5C7.05797 17.5 6.63405 17.3244 6.32149 17.0118C6.00893 16.6993 5.83333 16.2754 5.83333 15.8333V12.5Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_6_2_1_btn_print') }}
      </Button>
      -->
      <Button @click="handleUpdate" :disabled="isUpdating" size="nav" type="primary">{{ $t('15_6_2_1_btn_save') }}</Button>
    </SlickMenu>

    <div class="persona-detail">
      <div class="persona-detail-body">
        <div class="column">
          <div class="boxed boxed--full">
            <div class="box">
              <h2>{{ $t('15_6_2_1_title_main_info') }}</h2>

              <div class="column">
                <div style="width: 100%;">
                  <FormGroup :validation-error="validationError.name" :label="$t('15_6_2_1_label_persona_audience_name')" :required="true">
                    <input
                      type="text"
                      :placeholder="$t('15_6_2_1_placeholder_persona_audience_name')"
                      v-model="persona.name"
                      @input="validationError.name = false"
                      ref="contentName"
                    />
                  </FormGroup>
                </div>
                <div class="col-full">
                  <ImageUploader
                    :title="$t('15_6_2_1_avatar_image')"
                    sub-category="persona"
                    :sub-category-id="persona.id"
                    :company-id="companyID"
                  />
                </div>
              </div>

              <div>
                <FormGroup
                  :label="$t('15_6_2_1_label_key_insight')"
                  :required="false"
                >
                  <input
                    type="text"
                    :placeholder="$t('15_6_2_1_placeholder_key_insight')"
                    v-model="persona.keyInsights"
                  />
                </FormGroup>
                <Hint>{{ $t('15_6_2_1_hint_key_insight') }}</Hint>
              </div>


              <div class="column">
                <Dropdown
                  v-model="persona.gender"
                  :label="$t('15_6_2_1_label_gender')"
                  :placeholder="$t('15_6_2_1_placeholder_gender')"
                  :items="Gender"
                />

                <Dropdown
                  v-model="persona.age"
                  :label="$t('15_6_2_1_label_age')"
                  :placeholder="$t('15_6_2_1_placeholder_age')"
                  :items="Age"
                />
              </div>

              <div class="column">
                <FormGroup
                  :label="$t('15_6_2_1_label_demographics')"
                  :required="false"
                >
                  <input
                    type="text"
                    :placeholder="$t('15_6_2_1_placeholder_demographics')"
                    v-model="persona.demographics"
                    ref="contentName"
                  />
                </FormGroup>
              </div>

              <div class="column">
                <div style="width: 100%;">
                  <Dropdown
                    v-model="persona.education"
                    :label="$t('15_6_2_1_label_education')"
                    :placeholder="$t('15_6_2_1_placeholder_education')"
                    :items="Education"
                  />
                </div>

                <FormGroup
                  :label="$t('15_6_2_1_label_occupation')"
                  :required="false"
                >
                  <input
                    type="text"
                    :placeholder="$t('15_6_2_1_placeholder_occupation')"
                    v-model="persona.occupation"
                    ref="contentName"
                  />
                </FormGroup>
              </div>

              <div class="column">
                <Dropdown
                  v-model="persona.channels"
                  :multiple="true"
                  :label="$t('15_6_2_1_label_channels')"
                  :placeholder="$t('15_6_2_1_placeholder_channels')"
                  :items="channels"
                />
                <!--
                <ButtonAdd />
                -->
              </div>

              <div class="column">
                <Dropdown
                  v-model="persona.products"
                  :multiple="true"
                  :label="$t('15_6_2_1_label_product_and_services')"
                  :placeholder="$t('15_6_2_1_placeholder_product_and_services')"
                  :items="products"
                />
                <!--
                <ButtonAdd />
                -->
              </div>

              <STDCFrameworksAndPhases
                :stdcs="persona.stdcs"
                @createSTDC="showModalAddStdc = true"
                @deleteSTDC="deleteStdc"
                @editSTDC="editStdc"
              />

            </div>
          </div>

          <div class="boxed boxed--full">
            <div class="box">
              <h2>Other Info{{ $t('15_6_2_1_title_other_info') }}</h2>

              <div class="column">
                <FormGroup :label="$t('15_6_2_1_label_needs')" :required="false">
                  <Resources
                    :items="persona.needs"
                    :name-only="true"
                    :name-large="true"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_6_2_1_add_needs"
                    @updateResources="updateNeeds"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div class="column">
                <FormGroup :label="$t('15_6_2_1_label_desires')" :required="false">
                  <Resources
                    :items="persona.desires"
                    :name-only="true"
                    :name-large="true"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_6_2_1_add_desires"
                    @updateResources="updateDesires"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div class="column">
                <FormGroup :label="$t('15_6_2_1_label_problems')" :required="false">
                  <Resources
                    :items="persona.problems"
                    :name-only="true"
                    :name-large="true"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_6_2_1_add_problems"
                    @updateResources="updateProblems"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div class="column">
                <FormGroup :label="$t('15_6_2_1_label_solutions')" :required="false">
                  <Resources
                    :items="persona.solutions"
                    :name-only="true"
                    :name-large="true"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_6_2_1_add_solutions"
                    @updateResources="updateSolutions"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div
                class="column"
              >
                <FormGroup :label="$t('15_6_2_1_label_concerns')" :required="false">
                  <Resources
                    :items="persona.concerns"
                    :name-only="true"
                    :name-large="true"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_6_2_1_add_concerns"
                    @updateResources="updateConcerns"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div
                v-if="originalPersona.contentSpecifics.length !== 0"
                class="column"
              >
                <FormGroup :label="$t('15_6_2_1_label_content_specifics')" :required="false">
                  <Resources
                    :items="persona.contentSpecifics"
                    :name-only="true"
                    :name-large="true"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_6_2_1_add_content_specifics"
                    @updateResources="updateContentSpecifics"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div
                v-if="originalPersona.note"
                class="column"
              >
                <FormGroup :label="$t('15_6_2_1_label_note')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_6_2_1_placeholder_note')"
                      v-model="persona.note"
                    ></textarea>
                </FormGroup>
              </div>

              <div
                v-if="(
                    (originalPersona.otherResources && originalPersona.otherResources.links && originalPersona.otherResources.links.length !== 0)
                    || (originalPersona.otherResources && originalPersona.otherResources.files && originalPersona.otherResources.files.length !== 0)
                    )"
                class="column"
              >
                <FormGroup
                  :label="$t('15_6_2_1_label_other_resources')"
                  :required="false"
                >
                  <h2 class="files-title">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    {{ $t('special_list_links_title') }}
                    ({{persona.otherResources.links.length}})
                  </h2>
                  <Resources
                    :items="persona.otherResources.links"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_6_2_1_add_other_resources"
                    @updateResources="updateOtherResourcesLinks"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                  <SourcesFileUploader
                    query-type="persona"
                    file-type="persona-resource"
                    :destinationId="this.$route.params.personaId"
                    :attachments="persona.otherResources.files"
                    @refresh="refetch"
                    type="edit"
                  ></SourcesFileUploader>
                </FormGroup>
              </div>

              <Accordion
                v-if="originalPersona.note === ''
                || originalPersona.contentSpecifics.length === 0
                || (originalPersona.otherResources.links.length === 0 && originalPersona.otherResources.files.length === 0)
                "
                :title="$t('15_6_2_1_accordion_title')"
                :description="$t('15_6_2_1_accordion_description')"
              >
                <div
                  v-if="originalPersona.contentSpecifics.length === 0"
                  class="column"
                >
                  <FormGroup :label="$t('15_6_2_1_label_content_specifics')" :required="false">
                    <Resources
                      :items="persona.contentSpecifics"
                      :name-only="true"
                      :name-large="true"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      transCreateNew="15_6_2_1_add_content_specifics"
                      @updateResources="updateContentSpecifics"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>

                <div
                  v-if="originalPersona.note === ''"
                  class="column"
                >
                  <FormGroup :label="$t('15_6_2_1_label_note')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_6_2_1_placeholder_note')"
                      v-model="persona.note"
                    ></textarea>
                  </FormGroup>
                </div>

                <div
                  v-if="(
                    (originalPersona.otherResources && originalPersona.otherResources.links.length === 0)
                    && (originalPersona.otherResources && originalPersona.otherResources.files.length === 0)
                    )"
                  class="column"
                >
                  <FormGroup
                    :label="$t('15_6_2_1_label_other_resources')"
                    :required="false"
                  >
                    <h2 class="files-title">
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50085 13.0009L12.5009 8.00085M9.16752 5.50091L9.55336 5.05424C10.3349 4.27285 11.3948 3.83391 12.4999 3.83398C13.605 3.83406 14.6649 4.27315 15.4463 5.05466C16.2277 5.83616 16.6666 6.89606 16.6665 8.0012C16.6664 9.10634 16.2274 10.1662 15.4459 10.9476L15.0009 11.3342M10.8341 15.5009L10.5033 15.9459C9.71264 16.7277 8.64559 17.1662 7.53369 17.1662C6.42178 17.1662 5.35473 16.7277 4.5641 15.9459C4.1744 15.5605 3.86501 15.1017 3.65386 14.596C3.44271 14.0902 3.33398 13.5476 3.33398 12.9996C3.33398 12.4516 3.44271 11.909 3.65386 11.4032C3.86501 10.8975 4.1744 10.4387 4.5641 10.0534L5.00077 9.66752" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      {{ $t('special_list_links_title') }}
                      ({{persona.otherResources.links.length}})
                    </h2>
                    <Resources
                      :items="persona.otherResources.links"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      transCreateNew="15_6_2_1_add_other_resources"
                      @updateResources="updateOtherResourcesLinks"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                    <SourcesFileUploader
                      query-type="persona"
                      file-type="persona-resource"
                      :destinationId="this.$route.params.personaId"
                      :attachments="persona.otherResources.files"
                      @refresh="refetch"
                      type="edit"
                    ></SourcesFileUploader>
                  </FormGroup>
                </div>
              </Accordion>

            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalAddStdc"
      @closeModal="showModalAddStdc = false"
    >
      <StdcModalAdd
        :personas="personas"
        :products="products"
        :stdcToEdit="null"
        @close="showModalAddStdc = false"
        @create="createStdc"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalEditStdc"
      @closeModal="showModalEditStdc = false"
    >
      <StdcModalAdd
        :stdcToEdit="stdcToEdit"
        :personas="personas"
        :products="products"
        @close="showModalEditStdc = false"
        @update="updateStdc"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <PersonasModalDelete
        @close="showModalDelete = false"
        @delete="deletePersona"
        :title="persona.name"
      />
    </Modal>

    <ModalConfirmClose
      v-if="showLeaveModal"
      @close="handleModalLeaveClose"
      @confirm="handleModalLeaveConfirm"
    />
  </ContentWrapper>
</template>

<script>
import Dropdown from "@/components/dropdown/Dropdown.vue";
import Button from "@/components/button/Button.vue";
import FormGroup from "@/components/FormGroup.vue";
import Resources from "@/components/design/Resources.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import Avatar from "@/components/Avatar.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import STDCFrameworksAndPhases
  from "@/components/sources/components/stdcFrameworksAndPhases/STDCFrameworksAndPhases.vue";
import Modal from "@/components/Modal.vue";
import PersonasModalDelete from "@/components/sources/components/personas/PersonasModalDelete.vue";
import ButtonAdd from "@/components/buttonCopy/ButtonAdd.vue";
import SourcesFileUploader from "@/components/sources/components/sourcesFileUploader/SourcesFileUploader.vue";

import COMPANY_PERSONA_FILES from "@/graphql/CompanyPersonaFiles.gql";
import COMPANY_PERSONA_DETAIL from '@/graphql/CompanyPersonaDetail.gql';
import PERSONA_UPDATE from "@/graphql/PersonaUpdate.gql";
import PERSONA_DELETE from "@/graphql/PersonaDelete.gql";

import {arrayClone, obj_clone} from "@/services/helpers/clone";
import COMPETITOR_UPDATE from "@/graphql/CompetitorUpdate.gql";
import PRODUCT_DELETE from "@/graphql/ProductDelete.gql";
import {DateService as DataService} from "../../../services/DateService";
import ModalConfirmClose from "@/components/ModalConfirmClose.vue";
import Hint from "@/components/sources/components/hint/Hint.vue";
import StdcModalAdd from "@/components/sources/components/stdc/StdcModalAdd.vue";
import STDC_CREATE from "@/graphql/StdcCreate.gql";
import utils from "@/utils/utils";
import STDC_UPDATE from "@/graphql/StdcUpdate.gql";
import STDC_DELETE from "@/graphql/StdcDelete.gql";
import ImageUploader from "@/components/ImageUploader.vue";

export default {
  components: {
    ImageUploader,
    StdcModalAdd,
    Hint,
    ModalConfirmClose,
    SourcesFileUploader,
    ButtonAdd,
    PersonasModalDelete,
    Modal,
    STDCFrameworksAndPhases,
    SlickMenu,
    ContentWrapper,
    CompanyLogo,
    Accordion,
    Resources,
    FormGroup,
    Button,
    Avatar,
    Dropdown
  },
  apollo: {
    companies: {
      query: COMPANY_PERSONA_DETAIL,
      variables() {
        return {
          id: this.companyID,
          // personaId: this.$route.params.personaId
        }
      },
      update: function(data) {

        try {
          const company = data.companies.find((item) => item.id === this.companyID);

          this.products = arrayClone(company.products).map((product) => {
            return {
              value: product.id,
              name: product.name
            }
          })

          this.personas = arrayClone(company.personas).map((persona) => {
            return {
              value: persona.id,
              name: persona.name
            }
          })

          this.channels = arrayClone(company.channels).map((channel) => {
            return {
              value: channel.id,
              name: channel.name
            }
          })

          const persona = company.personas.find((item) => item.id === this.$route.params.personaId);
          this.setPersona(persona);

        } catch(e) {

        }
      }
    },
  },
  computed: {
    dataHasChanged() {
      return JSON.stringify(this.persona) !== JSON.stringify(this.originalPersona);
    },
    companyID() {
      return this.$route.params.id;
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    productIds() {
      return this.persona.products.map((product) => product.value);
    },
    channelIds() {
      return this.persona.channels.map((channel) => channel.value);
    },
  },
  data() {
    return {
      stdcToEdit: null,
      showModalAddStdc: false,
      showModalEditStdc: false,
      showLeaveModal: false,
      Age: DataService.getAge(),
      Gender: DataService.getGender(),
      Education: DataService.getEducation(),

      isUpdating: false,
      persona: {
        stdcs: [],
        age: '',
        concerns: [],
        contentSpecifics: [],
        demographics: '',
        desires: [],
        education: '',
        gender: '',
        channels: [],
        id: '',
        keyInsights: '',
        name: '',
        needs: [],
        note: '',
        occupation: '',
        otherResources: {
          files: [],
          links: []
        },
        problems: [],
        products: [],
        ratio: 0,
        solutions: []
      },
      originalPersona: {
        age: '',
        concerns: [],
        contentSpecifics: [],
        demographics: '',
        desires: [],
        education: '',
        gender: '',
        channels: [],
        id: '',
        keyInsights: '',
        name: '',
        needs: [],
        note: '',
        occupation: '',
        otherResources: {
          files: [],
          links: []
        },
        problems: [],
        products: [],
        ratio: 0,
        solutions: []
      },

      channels: [],
      products: [],
      personas: [],

      resources: [],
      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,

      validationError: {
        name: false,
      },
      showModalDelete: false,
    }
  },
  methods: {
    updateStdc(stdc) {
      this.showModalEditStdc = false;

      stdc = obj_clone(utils.removeTypename(stdc));
      delete stdc.companyId;

      if (stdc.personas && stdc.personas.length && !stdc.personaIds) {
        stdc.personaIds = stdc.personas.map((item) => item.value);
      }
      delete stdc.personas;
      delete stdc.persona;

      if (stdc.products && stdc.products.length && !stdc.productIds) {
        stdc.productIds = stdc.products.map((item) => item.value);
      }
      delete stdc.products;
      delete stdc.product;

      const see = stdc.phases.find((item) => item.phaseType === 'SEE');
      see.phaseData.channelIds = [];
      if (see.phaseData.channels && see.phaseData.channels.length) {
        see.phaseData.channelIds = [];
        see.phaseData.channelIds = see.phaseData.channels.map((item) => item.value);
      }
      delete see.phaseData.channels

      const think = stdc.phases.find((item) => item.phaseType === 'THINK');
      think.phaseData.channelIds = [];
      if (think.phaseData.channels && think.phaseData.channels.length) {
        think.phaseData.channelIds = think.phaseData.channels.map((item) => item.value);
      }
      delete think.phaseData.channels

      const _do = stdc.phases.find((item) => item.phaseType === 'DO');
      _do.phaseData.channelIds = [];
      if (_do.phaseData.channels && _do.phaseData.channels.length) {
        _do.phaseData.channelIds = _do.phaseData.channels.map((item) => item.value);
      }
      delete _do.phaseData.channels

      const care = stdc.phases.find((item) => item.phaseType === 'CARE');
      care.phaseData.channelIds = [];
      if (care.phaseData.channels && care.phaseData.channels.length) {
        care.phaseData.channelIds = care.phaseData.channels.map((item) => item.value);
      }
      delete care.phaseData.channels

      if (
        stdc.otherResources
        && stdc.otherResources.links
        && stdc.otherResources.links.length > 0
      ) {
        stdc.otherResources = stdc.otherResources.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      } else {
        stdc.otherResources = [];
      }

      if (JSON.stringify(stdc.description) === "{}" || stdc.description === null) {
        stdc.description = null;
      }

      if (!stdc.productId) {
        stdc.productId = null;
      }

      if (!stdc.personaId) {
        stdc.personaId = null;
      }

      console.log("stdc to save: ", stdc);

      try {
        this.$apollo.mutate({
          mutation: STDC_UPDATE,
          variables: {
            stdc
          },
          update: (store, data) => {
            this.stdc = data.data.updateSTDC;
            this.originalStdc = arrayClone(this.stdc);
          }
        }).then(() => {

          this.$apollo.queries.companies.refetch();

          this.$notify({
            title: this.$t('15_8_toaster_success_title_saved'),
            text: this.$t('15_8_toaster_success_description_saved'),
            type: 'success',
            duration: 5000,
          });

          this.savingStdc = false;
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });

        this.savingStdc = false;
      }
    },
    editStdc(stdc) {
      console.log("stdc to edit: ", stdc);
      this.showModalEditStdc = true;
      this.stdcToEdit = stdc;
    },
    deleteStdc(id) {
      this.showModalDelete = false

      try {
        this.$apollo.mutate({
          mutation: STDC_DELETE,
          variables: {
            id
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_8_toaster_success_title_deleted'),
            text: this.$t('15_8_toaster_success_description_deleted'),
            type: 'success',
            duration: 5000,
          })
        })
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    createStdc(stdc) {
      this.showModalAddStdc = false;
      stdc.companyId = this.companyID;

      try {
        this.$apollo.mutate({
          mutation: STDC_CREATE,
          variables: {
            stdc: stdc
          },
          update: (store, data) => {
            this.$apollo.queries.companies.refetch();

            this.$notify({
              title: this.$t('15_8_toaster_success_title_create'),
              text: this.$t('15_8_toaster_success_description_created'),
              type: 'success',
              duration: 5000,
            })
          }
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    async refetch() {
      const response = await this.$apollo.query({
        query: COMPANY_PERSONA_FILES,
        variables: {
          id: this.companyID,
        }
      });

      try {
        const company = response.data.companies.find((item) => item.id === this.companyID);
        const persona = company.personas.find((item) => item.id === this.$route.params.personaId);

        if (persona.otherResources) {
          if (persona.otherResources.files) {
            this.persona.otherResources.files = arrayClone(persona.otherResources.files);
          }
        }
      } catch(e) {

      }
    },
    updateConcerns(links) {
      this.persona.concerns = links;
    },
    updateContentSpecifics(links) {
      this.persona.contentSpecifics = links;
    },
    updateDesires(links) {
      this.persona.desires = links;
    },
    updateNeeds(links) {
      this.persona.needs = links;
    },
    updateOtherResourcesLinks(links) {
      this.persona.otherResources.links = links;
    },
    updateProblems(links) {
      this.persona.problems = links;
    },
    updateSolutions(links) {
      this.persona.solutions = links;
    },
    updateResources() {

    },
    handleGoBack() {
      this.$router.go(-1);
    },
    setPersona(persona) {
      this.persona.id = persona.id;
      this.persona.name = persona.name;
      this.persona.stdcs = persona.stdcs;

      if (persona.age) {
        const key = `${persona.age.min}_${persona.age.max}`;
        this.persona.age = this.Age.find((item) => item.value === key);
      }

      this.persona.demographics = persona.demographics;

      if (persona.education) {
        this.persona.education = this.Education.find((item) => item.value === persona.education.toString());
      }

      if (persona.gender) {
        this.persona.gender = this.Gender.find((item) => item.value === persona.gender.toString());
      }

      this.persona.keyInsights = persona.keyInsights;
      this.persona.note = persona.note;
      this.persona.occupation = persona.occupation;
      this.persona.ratio = persona.ratio;

      if (persona.products && persona.products.length) {
        this.persona.products = persona.products.map((item) => {
          return {
            name: item.name,
            value: item.id
          }
        });
      }

      if (persona.channels && persona.channels.length) {
        this.persona.channels = persona.channels.map((item) => {
          return {
            name: item.name,
            value: item.id
          }
        });
      }

      if (persona.concerns && persona.concerns.length) {
        this.persona.concerns = persona.concerns.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      } else {
        this.persona.concerns = []
      }

      if (persona.contentSpecifics && persona.contentSpecifics.length) {
        this.persona.contentSpecifics = persona.contentSpecifics.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      } else {
        this.persona.contentSpecifics = []
      }

      if (persona.desires && persona.desires.length) {
        this.persona.desires = persona.desires.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      } else {
        this.persona.desires = []
      }

      if (persona.needs && persona.needs.length) {
        this.persona.needs = persona.needs.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      } else {
        this.persona.needs = []
      }

      if (persona.problems && persona.problems.length) {
        this.persona.problems = persona.problems.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      } else {
        this.persona.problems = []
      }

      if (persona.solutions && persona.solutions.length) {
        this.persona.solutions = persona.solutions.map((item) => {
          return {
            name: item,
            link: ''
          }
        })
      } else {
        this.persona.solutions = []
      }

      if (persona.otherResources) {
        if (persona.otherResources.links) {
          this.persona.otherResources.links = arrayClone(persona.otherResources.links);
        }

        if (persona.otherResources.files) {
          this.persona.otherResources.files = arrayClone(persona.otherResources.files);
        }
      }

      this.originalPersona = obj_clone(this.persona);

    },
    handleUpdate() {
      if (this.persona.name === '') {
        this.validationError.name = true;
        return;
      }

      const persona = {};
      persona.id = this.persona.id;
      persona.name = this.persona.name;
      persona.ratio = this.persona.ratio;
      persona.note = this.persona.note;
      persona.keyInsights = this.persona.keyInsights;
      persona.occupation = this.persona.occupation;
      persona.demographics = this.persona.demographics;

      if (this.persona.age && this.persona.age.value) {
        const values = this.persona.age.value.split("_");
        persona.age = {
          min: parseInt(values[0]),
          max: parseInt(values[1]),
        }
      }

      if (this.persona.education && this.persona.education.value) {
        persona.education = this.persona.education.value;
      }

      if (this.persona.gender && this.persona.gender.value) {
        persona.gender = this.persona.gender.value;
      }

      if (
        this.persona.otherResources
        && this.persona.otherResources.links
        && this.persona.otherResources.links.length > 0
      ) {
        persona.otherResources = this.persona.otherResources.links.map((item) => {
          return {
            link: item.link,
            name: item.name
          }
        });
      }

      persona.productIds = this.productIds;
      persona.channelIds = this.channelIds;

      if (this.persona.concerns && this.persona.concerns.length) {
        persona.concerns = this.persona.concerns.map((item) => item.name);
      }

      if (this.persona.contentSpecifics && this.persona.contentSpecifics.length) {
        persona.contentSpecifics = this.persona.contentSpecifics.map((item) => item.name);
      }

      if (this.persona.desires && this.persona.desires.length) {
        persona.desires = this.persona.desires.map((item) => item.name);
      }

      if (this.persona.needs && this.persona.needs.length) {
        persona.needs = this.persona.needs.map((item) => item.name);
      }

      if (this.persona.problems && this.persona.problems.length) {
        persona.problems = this.persona.problems.map((item) => item.name);
      }

      if (this.persona.solutions && this.persona.solutions.length) {
        persona.solutions = this.persona.solutions.map((item) => item.name);
      }

      if (this.persona.solutions && this.persona.solutions.length) {
        persona.solutions = this.persona.solutions.map((item) => item.name);
      }

      this.isUpdating = true;

      try {
        this.$apollo.mutate({
          mutation: PERSONA_UPDATE,
          variables: {
            persona
          },
          update: (store, data) => {
            this.originalPersona = arrayClone(this.persona);
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_6_2_1_toaster_success_title_saved'),
            text: this.$t('15_6_2_1_toaster_success_description_saved'),
            type: 'success',
            duration: 5000,
          });

          this.isUpdating = false;
        });
      } catch(e) {
        this.isUpdating = false;

        this.$notify({
          title: this.$t('15_6_2_1_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });
      }

    },
    deletePersona() {
      this.isUpdating = true;

      try {
        this.$apollo.mutate({
          mutation: PERSONA_DELETE,
          variables: {
            id: this.companyID,
            personaId: this.$route.params.personaId
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_6_2_1_toaster_success_title_deleted'),
            text: this.$t('15_6_2_1_toaster_success_description_deleted'),
            type: 'success',
            duration: 5000,
          });

          this.$router.push({ path: `/company/${this.companyID}/personas-and-audiences` });
          this.showModalDelete = false;
          this.isUpdating = false;
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_6_2_1_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });

        this.showModalDelete = false;
        this.isUpdating = false;
      }
    },
    confirmLeave(to, from, next) {
      if (this.dataHasChanged) {
        this.showLeaveModal = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    },
    handleModalLeaveClose() {
      this.showLeaveModal = false;
    },
    handleModalLeaveConfirm() {
      this.originalPersona = obj_clone(this.persona);

      this.showLeaveModal = false;
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.confirmLeave(to, from, next);
  }
}
</script>

<style scoped>
.persona-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.persona-detail-header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.persona-detail-header-back {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.persona-detail-header-title {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 800;
  line-height: 33.6px;
  text-align: left;
}

.persona-detail-header-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.col-full {
  width: 100%;
}
</style>
